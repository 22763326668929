import { TicketViewModel } from "../../../../../viewModels/dataTypes/building/TicketViewModel";
import {
  AlignmentEnum,
  HeadCellsType,
} from "../../../../../viewModels/dataTypes/DataTableType";
import TicketStateComponent from "../../../../../tools/table/ticketStateComponent/TicketStateComponent";

export const headCells: HeadCellsType<TicketViewModel>[] = [
  {
    id: "number",
    label: "SHARED.NUMBER",
    align: AlignmentEnum.Left,
    sortable: true,
    specialComponent: null,
    cellSize: {
      lg: 56,
      md: 56,
      sm: 56,
    },
    isLink: true,
  },
  {
    id: "ticketNumber",
    label: "APP_SUPPORT.TICKET_NUMBER",
    align: AlignmentEnum.Left,
    sortable: true,
    specialComponent: null,
    cellSize: {
      lg: 200,
      md: 130,
      sm: 130,
    },
    isLink: true,
  },
  {
    id: "ticketType",
    label: "SHARED.CATEGORY",
    align: AlignmentEnum.Left,
    sortable: true,
    specialComponent: null,
    cellSize: {
      lg: 200,
      md: 130,
      sm: 130,
    },
    isLink: true,
  },
  {
    id: "createdDateTime",
    label: "APP_SUPPORT.CREATION_DATE",
    align: AlignmentEnum.Left,
    sortable: true,
    specialComponent: null,
    cellSize: {
      lg: 200,
      md: 130,
      sm: 130,
    },
    isLink: true,
  },
  {
    id: "lastUpdateDateTime",
    label: "REQUESTS.LAST_UPDATE",
    align: AlignmentEnum.Left,
    sortable: true,
    specialComponent: null,
    cellSize: {
      lg: 200,
      md: 130,
      sm: 130,
    },
    isLink: true,
  },
  {
    id: "status",
    label: "APP_SUPPORT.STATE",
    align: AlignmentEnum.Left,
    sortable: false,
    specialComponent: TicketStateComponent,
    specialComponentProps: ["status", "id"],
    cellSize: {
      lg: 120,
      md: 120,
      sm: 120,
    },
    isLink: false,
  },
];
