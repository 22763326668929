import { CheckUserPassword } from "../../../services/api/UserAuth";
import { IServiceResult, ApiResponse } from "../../../viewModels/api/Api";
import { TwoFactorAuthViewModel } from "../../../viewModels/api/Models";
import { UserTokenModel } from "../../../viewModels/dataTypes/registration/TokenViewModel";

export const CheckUserPasswordService = async (
  userInfo: TwoFactorAuthViewModel
) => {
  var resultModel: IServiceResult<UserTokenModel> = {
    message: "",
  };
  if (userInfo.password != null && userInfo.password.length > 0) {
    var userData: ApiResponse<UserTokenModel> = await CheckUserPassword(
      userInfo
    );
    if (userData.data != null) {
      resultModel.data = userData.data;
    } else {
      resultModel.message = "ERRORS.INVALID_PASSWORD";
    }
  } else {
    resultModel.message = "ERRORS.EMPTY_PASSWORD";
  }
  return resultModel;
};
