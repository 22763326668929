import { styled } from "@mui/system";

const StyledAutocompletePaper = styled("div")({
  backgroundColor: "white",
  boxShadow:
    "rgba(0, 0, 0, 0.2) 0px 2px 1px -1px, rgba(0, 0, 0, 0.14) 0px 1px 1px 0px, rgba(0, 0, 0, 0.12) 0px 1px 3px 0px;",
  "& .MuiAutocomplete-listbox": {
    "& .MuiAutocomplete-option": {
      color: "var(--text-gray-high)",
      minHeight: "32px",
      fontFamily: "var(--regular)",
      fontSize: "14px",
      "&:hover": {
        backgroundColor: "var(--bg-gray-low)",
      },
      "&[aria-selected='true']": {
        backgroundColor: "var(--bg-gray-low)",
      },
    },
  },
});
export default StyledAutocompletePaper;
