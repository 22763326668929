import React, { useState, useEffect } from "react";
import AddNewPopups from "../../../../../tools/popup/addNewPopups/AddNewPopups";
import DropDownLabel from "../../../../../tools/dropDown/dropDownLabel/DropDownLabel";
import TextArea from "../../../../../tools/textArea/TextArea";
import Style from "../AnnouncementPopup.module.scss";
import { SizeType } from "../../../../../viewModels/dataTypes/tools/ListComponentType";
import {
  ButtonState,
  ButtonTheme,
} from "../../../../../viewModels/enums/ButtonEnum";
import Button from "../../../../../tools/button/Button";
import { useTranslation } from "react-i18next";
import { AddAnnouncementService } from "./newAnnouncement.service";
import TextField from "../../../../../tools/fields/textField/TextField";
import Grid from "@mui/material/Grid";
import { IDropDown } from "../../../../../viewModels/dataTypes/tools/DropDownItems";
import CampaignOutlinedIcon from "@mui/icons-material/CampaignOutlined";
import { CreateToast } from "../../../../../tools/toast/ToastService";
import { ToastType } from "../../../../../viewModels/enums/ToastType";
import { AttachmentsViewModel } from "../../../../../viewModels/dataTypes/AttachmentsViewModels";
import { AnnouncementViewModel } from "../../../../../viewModels/dataTypes/announcement/AnnouncementViewModel";
import { FloorinsDateTimeFormat } from "../../../../../services/commonServices/CommonServices";
import { useDispatch } from "react-redux";
import { AppThunkDispatch } from "../../../../../redux/Store";
import { GetAnnouncementAction } from "../../../../../redux/slices/AnnouncementSlice";
import SliderNewAnouncement from "./SliderNewAnouncement";

interface Props {
  onCancel: () => void;
  buildingId: string | undefined;
  labels: IDropDown[];
  onAddNewAnnouncement: (announcement: AnnouncementViewModel) => void;
}

const NewAnnouncement: React.FC<Props> = ({
  onCancel,
  onAddNewAnnouncement,
  buildingId,
  labels,
}) => {
  const [title, setTitle] = useState<string>("");
  const [description, setDescription] = useState<string>("");
  const [inProgressJobs, setInProgressJobs] = useState<boolean>(false);
  const [label, setLabel] = useState<IDropDown | null>(null);
  const [announcementAttachment, setAnnouncementAttachment] = useState<
    AttachmentsViewModel[]
  >([]);
  const [buttonDisabled, setButtonDisabled] = useState<boolean>(true);
  const { t: translate } = useTranslation();
  const [createdDateTime] = useState<Date>(new Date());

  const dispatch = useDispatch<AppThunkDispatch>();
  const handleTitleChange = (value: string) => {
    setTitle(value);
  };

  const handleDescriptionChange = (value: string) => {
    setDescription(value);
  };

  const handleLabelChange = (selectedLabel: IDropDown | null) => {
    setLabel(selectedLabel);
  };
const handleSend = async () => {
  if (!label || inProgressJobs) {
    return;
  }
  setInProgressJobs(true);

  try {
    const result = await AddAnnouncementService(
      createdDateTime,
      Number(buildingId),
      title,
      description,
      announcementAttachment,
      label.value
    );

    if (result.data) {
      const newAnnouncement: AnnouncementViewModel = {
        id: result.data.id,
        createdDateTime: FloorinsDateTimeFormat(new Date().toISOString()),
        buildingId: Number(buildingId),
        title,
        description,
        label: label.value,
        isDelete: false,
        announcementAttachment,
      };
      onAddNewAnnouncement(newAnnouncement);
      CreateToast(
        ToastType.Success,
        translate("ANNOUNCEMENTS.ALERT.SEND", {
          value: translate("ANNOUNCEMENTS.ANNOUNCEMENT"),
        })
      );
      onCancel();
      dispatch(GetAnnouncementAction(buildingId!));
    } else {
      CreateToast(ToastType.Error, translate("ANNOUNCEMENTS.ALERT.NOT_SEND"));
    }
  } catch (error) {
    CreateToast(ToastType.Error, translate("ANNOUNCEMENTS.ALERT.NOT_SEND"));
  } finally {
    setInProgressJobs(false); 
  }
};



  useEffect(() => {
    setButtonDisabled(!(title.trim() && label));
  }, [title, label]);

  return (
    <>
      <AddNewPopups
        title={translate("ANNOUNCEMENTS.NEW")}
        icon={<CampaignOutlinedIcon />}
        buttons={
          <div className={Style.buttons_container}>
            <Button
              className={Style.send_button}
              text={translate("BUTTON.CANCEL")}
              size={SizeType.small}
              theme={ButtonTheme.primaryReverse}
              clickMethod={onCancel}
            />
            <Button
              className={Style.cancel_button}
              text={translate("BUTTON.SEND")}
              size={SizeType.small}
              theme={ButtonTheme.primary}
              state={buttonDisabled ? ButtonState.disable : ButtonState.default}
              clickMethod={handleSend}
            />
          </div>
        }
      >
        <Grid container spacing={1.5}>
          <Grid
            item
            xs={12}
            md={6}
            className={Style.label_container}
          >
            <TextField
              value={title}
              onChange={(value) => {
                if (value.length <= 50) {
                  handleTitleChange(value);
                }
              }}
              label={translate("ANNOUNCEMENTS.SUBJECT")}
              maxLength={50}
            />
          </Grid>
          <Grid item xs={12} md={6} className={Style.label_container}>
            <DropDownLabel
              value={label}
              placeholder={translate("ANNOUNCEMENTS.SELECT_LABEL")}
              data={labels}
              onChange={handleLabelChange}
              label={translate("ANNOUNCEMENTS.LABEL")}
            />
          </Grid>
          <Grid
            item
            xs={12}
            className={`${Style.label_container} ${Style.textarea_container}`}
          >
            <TextArea
              label={translate("ANNOUNCEMENTS.DESCRIPTION")}
              value={description}
              readonly={false}
              onChange={handleDescriptionChange}
              placeholder={translate("ANNOUNCEMENTS.WRITE_DESCRIPTION")}
              maxLength={500}
              counter={true}
            />
          </Grid>
        </Grid>
        <SliderNewAnouncement
          announcementAttachment={announcementAttachment}
          setAnnouncementAttachment={setAnnouncementAttachment}
        />
      </AddNewPopups>
    </>
  );
};

export default NewAnnouncement;
