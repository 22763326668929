import { Eye, EyeSlash } from "iconsax-react";
import React, { ChangeEvent, ReactElement, useEffect, useState } from "react";
import Style from "./PasswordField.module.scss";
import { FieldState } from "../../../viewModels/enums/PublicEnum";
import { useTranslation } from "react-i18next";
import { MessageHandler } from "../../messagesHandler/MessagesHandler";
import { MessageType } from "../../../viewModels/enums/MessageType";
import { PasswordFieldType } from "../../../viewModels/enums/PasswordFieldType";

interface Props {
  icon?: ReactElement;
  label?: string;
  condotion?: (value: string) => boolean;
  onChange: (value: string) => void;
  checkPass?: boolean;
  fieldState?: FieldState;
  placeholder?: string;
  onBlur?: (value?: any) => void;
  errorMessage?: string | null;
}

const PasswordField: React.FC<Props> = ({
  icon,
  label,
  onChange,
  checkPass = false,
  fieldState = FieldState.Default,
  placeholder = "PLACEHOLDER.PASSWORD_HELPER",
  onBlur,
  errorMessage,
}) => {
  useEffect(() => {
    setPasswordFieldState(fieldState);
  }, [fieldState]);

  const [passwordType, setPasswordType] = useState<PasswordFieldType>(
    PasswordFieldType.Password
  );

  const [passwrodFieldState, setPasswordFieldState] = useState<FieldState>(
    FieldState.Default
  );

  const [changeLabelColor, setChangeLabelColor] = useState(false);

  const changePassword = (value: string) => {
    if (onChange !== undefined) {
      if (value.length > 0) {
        setChangeLabelColor(true);
      } else {
        setChangeLabelColor(false);
      }
      onChange(value);
    }
  };

  const changeFildType = () => {
    if (passwordType === PasswordFieldType.Password) {
      setPasswordType(PasswordFieldType.Text);
    } else {
      setPasswordType(PasswordFieldType.Password);
    }
  };

  const onBlurCondition = () => {
    setPasswordFieldState(
      fieldState === FieldState.Error ? FieldState.Error : FieldState.Default
    );
    if (onBlur !== undefined) {
      onBlur();
    }
  };

  const { t: translate } = useTranslation();

  return (
    <div className={Style.floor_pass_field}>
      {errorMessage?.length === 0 || errorMessage == null ? (
        <label className={`${changeLabelColor ? Style.fill_field : ""}`}>
          {icon}
          <span>{translate(label !== undefined ? label : "")}</span>
        </label>
      ) : (
        <MessageHandler
          message={translate(errorMessage)}
          type={MessageType.Error}
        />
      )}
      <div
        className={`${Style.field} ${
          passwrodFieldState === FieldState.Error && Style.error
        }`}
      >
        {checkPass === false ? (
          <input
            autoComplete="new-password"
            type={passwordType}
            placeholder={
              errorMessage?.length === 0 ? translate(placeholder) : ""
            }
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              changePassword(e.target.value)
            }
          />
        ) : (
          <input
            autoComplete="new-password"
            type={passwordType}
            placeholder={
              errorMessage?.length === 0 ? translate(placeholder) : ""
            }
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              changePassword(e.target.value)
            }
            onBlur={onBlurCondition}
          />
        )}

        <a onClick={changeFildType}>
          {passwordType === PasswordFieldType.Password ? (
            <Eye size="20" color="var(--icon-gray-medium)" />
          ) : (
            <EyeSlash size="20" color="var(--icon-gray-medium)" />
          )}
        </a>
      </div>
    </div>
  );
};

export default PasswordField;
