import React from "react";
import { IStepperItem } from "../../../../../../viewModels/dataTypes/tools/Stepper";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import CalendarViewMonthOutlinedIcon from "@mui/icons-material/CalendarViewMonthOutlined";
import AdUnitsOutlinedIcon from "@mui/icons-material/AdUnitsOutlined";
import LinerStepper from "../../../../../../tools/stepper/Stepper";
import Style from "./BuildingInfoStepper.module.scss";

interface Props {
  activeItem: number;
}
const BuildingInfoStepper: React.FC<Props> = ({ activeItem }) => {
  const steps: IStepperItem[] = [
    {
      titr: "BUILDING.GENERAL_INFO",
      icon: <HomeOutlinedIcon className={Style.stepper_icon} />,
    },
    {
      titr: "BUILDING.MAP_GENERATION",
      icon: <CalendarViewMonthOutlinedIcon className={Style.stepper_icon} />,
    },
    {
      titr: "BUILDING.UNIT_DETAIL",
      icon: <AdUnitsOutlinedIcon className={Style.stepper_icon} />,
    },
  ];

  return <LinerStepper steps={steps} activeStep={activeItem} />;
};

export default BuildingInfoStepper;
