import { Grid } from "@mui/material";
import React from "react";
import Style from "./AdministrationInfo.module.scss";
import useWindowSize from "../../../../../../../hook/WindowSize";
import {
  MediumDeviceTrigger,
  SmallDeviceTrigger,
} from "../../../../../../../Setting";
import CheckBox from "../../../../../../../tools/dropDown/checkBoxDropDown/CheckBox";
import { useTranslation } from "react-i18next";
import { IDropDown } from "../../../../../../../viewModels/dataTypes/tools/DropDownItems";
import DashboardFormTitle from "../../../../../../../tools/dashboardTitle/dashboardFormTitle/DashboardFormTitle";
import { Key } from "iconsax-react";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import DeleteAdmin from "./deleteAdmin/DeleteAdmin";
import { useParams } from "react-router-dom";

interface Props {
  userResponsibilities: IDropDown[] | null;
  userResponsibilitiesHandler: (value: IDropDown[] | null) => void;
  adminRoles: IDropDown[] | null;
  deleteHandler: () => void;
}

const AdministrationInfo: React.FC<Props> = ({
  userResponsibilities,
  userResponsibilitiesHandler,
  adminRoles,
  deleteHandler,
}) => {
  const windowSize = useWindowSize();
  const { t: translate } = useTranslation();
  const { buildingId, userId } = useParams();

  return (
    <div className={Style.admin_info}>
      <div className={Style.admin_info_head}>
        <DashboardFormTitle
          title={translate("USERS.ADMIN")}
          icon={<Key className={Style.admin_info_icon} />}
          className={Style.unit_title}
        />
        <DeleteAdmin
          buildingId={Number(buildingId)}
          userId={userId ?? ""}
          deleteHandler={deleteHandler}
        />
      </div>
      <Grid
        className={Style.admin_info_container}
        container
        columnSpacing={windowSize.width > MediumDeviceTrigger ? 8 : 6}
      >
        <Grid item md={6} sm={12} xs={12}>
          {adminRoles && (
            <CheckBox
              placeHolder={translate("PLACEHOLDER.RESPONSIBILITIES")}
              values={userResponsibilities}
              label={translate("LABELS.RESPONSIBILITIES")}
              onChange={userResponsibilitiesHandler}
              data={adminRoles}
            />
          )}
          {(windowSize.width > MediumDeviceTrigger ||
            windowSize.width < SmallDeviceTrigger) && (
            <div className={Style.admin_info_description}>
              <InfoOutlinedIcon className={Style.info_icon} />
              {translate("ADMIN.RESPONSIBILITY_HELPER")}
            </div>
          )}
        </Grid>
      </Grid>
    </div>
  );
};

export default AdministrationInfo;
