import React, { useEffect, useState } from "react";
import dayjs, { Dayjs } from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { ButtonDatePicker } from "./ButtonDatePicker";
import DateNavigation from "./DateNavigation";
import WeekPickerDay from "./WeekPickerDay";
import DefaultPickerDay from "./DefaultPickerDay";
import Style from "./FilterDropDownDate.module.scss";
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";
import { PickersDayProps } from "@mui/x-date-pickers";
import {
  FormatDateMonth,
  FormatDateMonthAndDay,
  FormatDateMonthWithDay,
  FormatDateYear,
  UltraSmallDeviceTrigger,
} from "../../../Setting";
import { CalenderEnum } from "../../../viewModels/enums/CalenderEnum";
import { DirectionNavigateEnum } from "../../../viewModels/enums/DirectionNavigateEnum";
import useWindowSize from "../../../hook/WindowSize";

dayjs.extend(isSameOrBefore);
dayjs.extend(isSameOrAfter);

interface DatePickerContainerProps {
  mode: string;
  onDateChange: (start: Dayjs, end: Dayjs) => void;
}

const DatePickerContainer: React.FC<DatePickerContainerProps> = ({
  mode,
  onDateChange,
}) => {
  const [selectedDate, setSelectedDate] = useState<Dayjs | null>(dayjs());
  const [highlightedWeek, setHighlightedWeek] = useState<{
    start: Dayjs;
    end: Dayjs;
  }>({
    start: dayjs().startOf(CalenderEnum.WEEK),
    end: dayjs().endOf(CalenderEnum.WEEK),
  });
  const [hoveredDate, setHoveredDate] = useState<Dayjs | null>(null);
  const windowSize = useWindowSize();
  const isMobile: boolean = windowSize.width < UltraSmallDeviceTrigger;
  const handleDateChange = (newValue: Dayjs | null) => {
    if (!newValue) return;

    if (mode === CalenderEnum.WEEK) {
      const newRange = {
        start: newValue.startOf(CalenderEnum.WEEK),
        end: newValue.endOf(CalenderEnum.WEEK),
      };
      setHighlightedWeek(newRange);
      setSelectedDate(newValue);
      onDateChange(newRange.start, newRange.end);
    } else {
      setSelectedDate(newValue);
      onDateChange(newValue, newValue);
    }
  };

  const handleTodayClick = () => {
    const today = dayjs();
    if (mode === CalenderEnum.WEEK) {
      const newRange = {
        start: today.startOf(CalenderEnum.WEEK),
        end: today.endOf(CalenderEnum.WEEK),
      };
      setHighlightedWeek(newRange);
      setSelectedDate(today.startOf(CalenderEnum.WEEK));
      onDateChange(newRange.start, newRange.end);
    } else {
      setSelectedDate(today);
      onDateChange(today, today);
    }
  };

  const handleNavigation = (
    direction: DirectionNavigateEnum.LEFT | DirectionNavigateEnum.RIGHT
  ) => {
    let newDate: Dayjs | null = null;

    if (mode === CalenderEnum.DAY) {
      newDate = selectedDate
        ? direction === DirectionNavigateEnum.LEFT
          ? selectedDate.subtract(1, CalenderEnum.DAY)
          : selectedDate.add(1, CalenderEnum.DAY)
        : null;
    } else if (mode === CalenderEnum.WEEK) {
      newDate =
        direction === DirectionNavigateEnum.LEFT
          ? highlightedWeek.start.subtract(1, CalenderEnum.WEEK)
          : highlightedWeek.start.add(1, CalenderEnum.WEEK);
    } else if (mode === CalenderEnum.MONTH) {
      newDate = selectedDate
        ? direction === DirectionNavigateEnum.LEFT
          ? selectedDate.subtract(1, CalenderEnum.MONTH)
          : selectedDate.add(1, CalenderEnum.MONTH)
        : null;
    } else if (mode === CalenderEnum.YEAR) {
      newDate = selectedDate
        ? direction === DirectionNavigateEnum.LEFT
          ? selectedDate.subtract(1, CalenderEnum.YEAR)
          : selectedDate.add(1, CalenderEnum.YEAR)
        : null;
    }

    if (mode === CalenderEnum.WEEK && newDate) {
      const newRange = {
        start: newDate.startOf(CalenderEnum.WEEK),
        end: newDate.endOf(CalenderEnum.WEEK),
      };
      setHighlightedWeek(newRange);
      setSelectedDate(newRange.start);
      onDateChange(newRange.start, newRange.end);
    } else if (newDate) {
      setSelectedDate(newDate);
      onDateChange(newDate, newDate);
    }
  };

  const handleDayHover = (date: Dayjs | null) => {
    setHoveredDate(date);
  };

  const displayDate =
    mode === CalenderEnum.WEEK
      ? isMobile
        ? `${highlightedWeek.start.format(FormatDateMonthWithDay)} `
        : `${highlightedWeek.start.format(
            FormatDateMonthAndDay
          )} - ${highlightedWeek.end.format(FormatDateMonthWithDay)}`
      : mode === CalenderEnum.MONTH
      ? selectedDate
        ? selectedDate.format(FormatDateMonth)
        : ""
      : mode === CalenderEnum.YEAR
      ? selectedDate
        ? selectedDate.format(FormatDateYear)
        : ""
      : selectedDate
      ? selectedDate.format(FormatDateMonthWithDay)
      : "";

  const getPickerViews = () => {
    switch (mode) {
      case CalenderEnum.DAY:
        return [CalenderEnum.DAY];
      case CalenderEnum.MONTH:
        return [CalenderEnum.MONTH, CalenderEnum.YEAR];
      case CalenderEnum.YEAR:
        return [CalenderEnum.YEAR];
      default:
        return [CalenderEnum.DAY];
    }
  };
  useEffect(() => {
    handleTodayClick();
  }, [mode]);

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <div className={Style.date_picker_container}>
        <ButtonDatePicker
          className={
            mode === CalenderEnum.WEEK
              ? Style.weekPicker
              : mode === CalenderEnum.MONTH
              ? Style.monthPicker
              : mode === CalenderEnum.YEAR
              ? Style.yearPicker
              : Style.dayPicker
          }
          label={displayDate}
          value={
            mode === CalenderEnum.WEEK ? highlightedWeek.start : selectedDate
          }
          onChange={handleDateChange}
          views={getPickerViews() as any}
          slots={{
            day: (props: PickersDayProps<dayjs.Dayjs>) =>
              mode === CalenderEnum.WEEK ? (
                <WeekPickerDay
                  {...props}
                  date={props.day}
                  isWithinWeek={
                    props.day.isSameOrAfter(
                      highlightedWeek.start,
                      CalenderEnum.DAY
                    ) &&
                    props.day.isSameOrBefore(
                      highlightedWeek.end,
                      CalenderEnum.DAY
                    )
                  }
                  isHoveredWeek={
                    hoveredDate &&
                    props.day.isSameOrAfter(
                      hoveredDate.startOf(CalenderEnum.WEEK),
                      CalenderEnum.DAY
                    ) &&
                    props.day.isSameOrBefore(
                      hoveredDate.endOf(CalenderEnum.WEEK),
                      CalenderEnum.DAY
                    )
                  }
                  handleDayHover={handleDayHover}
                />
              ) : (
                <DefaultPickerDay {...props} />
              ),
          }}
        />
        <DateNavigation
          onNavigate={handleNavigation}
          onTodayClick={handleTodayClick}
        />
      </div>
    </LocalizationProvider>
  );
};

export default DatePickerContainer;
