import React from "react";
import DashboardFormTitle from "../../../../../../../tools/dashboardTitle/dashboardFormTitle/DashboardFormTitle";
import Style from "./ResidentialInfo.module.scss";
import { useTranslation } from "react-i18next";
import CalendarViewMonthIcon from "@mui/icons-material/CalendarViewMonth";
import { Grid } from "@mui/material";

import {
  IResident,
  UpdateResidenViewModel,
} from "../../../../../../../viewModels/dataTypes/user/IResident";
import UnitAccordion from "../../../../../../../tools/accordion/secondaryAccordion/UnitAccordion";
import ContractDate from "./contractDate/ContractDate";
import { ResidentType } from "../../../../../../../viewModels/enums/ResidentType";
import CheckResident from "./checkResident/CheckResident";
import DeleteResident from "./deleteResident/DeleteResident";

interface Props {
  residentialInfo: IResident[];
  changeHandler: (userInfo: UpdateResidenViewModel) => void;
  deleteHandler: (residentId: number, residentType: string) => void;
}
const ResidentialInfo: React.FC<Props> = ({
  residentialInfo,
  changeHandler,
  deleteHandler,
}) => {
  const { t: translate } = useTranslation();

  return (
    <div className={Style.resident_info}>
      <DashboardFormTitle
        title={translate("UNIT.UNITS")}
        icon={<CalendarViewMonthIcon className={Style.units_icon} />}
        className={Style.unit_title}
      />
      <Grid className={Style.resident_info_container} container>
        {residentialInfo.map((item: IResident, index: number) => (
          <Grid item xs={12}>
            <UnitAccordion
              key={index}
              title={
                item.isPrimary
                  ? item.userRoleName
                  : translate("RESIDENT.CO_RESIDENT", {
                      value: item.userRoleName,
                    })
              }
              unitNumber={item.unitNumber}
              callToActionComponent={
                <DeleteResident
                  deleteHandler={deleteHandler}
                  isPrimary={item.isPrimary}
                  residentId={item.id}
                  userRoleName={item.userRoleName}
                />
              }
            >
              {item.userRoleName === ResidentType.Tenant ? (
                <ContractDate
                  changeHandler={changeHandler}
                  userInfo={{
                    endDate: item.contractEndDate,
                    isResident: item.isResident,
                    residentId: item.id,
                    startDate: item.contractStartDate,
                  }}
                />
              ) : (
                <CheckResident
                  changeHandler={changeHandler}
                  userInfo={{
                    startDate: item.contractStartDate,
                    endDate: item.contractEndDate,
                    isResident: item.isResident,
                    residentId: item.id,
                  }}
                />
              )}
            </UnitAccordion>
          </Grid>
        ))}
      </Grid>
    </div>
  );
};

export default ResidentialInfo;
