import { GetIconsBasedOnType } from "../../../../../../../services/api/Icon";
import {
  ApiResponse,
  IServiceResult,
} from "../../../../../../../viewModels/api/Api";
import { Icon, IconType } from "../../../../../../../viewModels/api/Models";

export const GetServiceCategoryIcons = async () => {
  const resultModel: IServiceResult<Icon[] | null> = {
    message: "",
    data: null,
  };
  var getServiceCategoryIconsesult: ApiResponse<Icon[]> =
    await GetIconsBasedOnType(IconType.All);
  resultModel.data = getServiceCategoryIconsesult.data;
  return resultModel;
};
