import { LabelEnum } from "../../../../viewModels/enums/LabelEnum";
import Style from "../anouncement/announcementList/announcementItem/AnnouncementItems.module.scss";
import StyleIcon from "../anouncement/announcementList/anouncementLabel/AnouncementLabels.module.scss";

export const getLabelClass = (label?: string) => {
  switch (label) {
    case LabelEnum.Notice:
    case LabelEnum.Notices:
      return {
        wrapperClass: Style.notice, 
        iconClass: StyleIcon.notice, 
      };
    case LabelEnum.Warning:
      return {
        wrapperClass: Style.warning, 
        iconClass: StyleIcon.warning, 
      };
    case LabelEnum.Advertisement:
      return {
        wrapperClass: Style.advertisement, 
        iconClass: StyleIcon.advertisement, 
      };
    default:
      return {
        wrapperClass: "",
        iconClass: "",
      };
  }
};
