import { useEffect } from "react";

const useCloseOnResize = (handleClose: () => void) => {
  useEffect(() => {
    const handleEvent = () => {
      handleClose();
    };

    // Listen for resize events
    window.addEventListener("resize", handleEvent);

    // Enhanced zoom detection on mobile
    let lastTouchEnd = 0;
    const handleTouchEnd = (event: Event) => {
      const now = new Date().getTime();
      if (now - lastTouchEnd <= 300) {
        event.preventDefault();
        handleClose();
      }
      lastTouchEnd = now;
    };

    // Detect zoom using visualViewport scale
    const handleVisualViewportResize = () => {
      if (window.visualViewport && window.visualViewport.scale !== 1) {
        handleClose();
      }
    };

    if (window.visualViewport) {
      window.visualViewport.addEventListener(
        "resize",
        handleVisualViewportResize
      );
    }

    window.addEventListener("gesturestart", handleEvent);
    window.addEventListener("gesturechange", handleEvent);
    window.addEventListener("gestureend", handleEvent);
    window.addEventListener("touchend", handleTouchEnd);

    // Clean up event listeners on component unmount
    return () => {
      window.removeEventListener("resize", handleEvent);
      window.removeEventListener("gesturestart", handleEvent);
      window.removeEventListener("gesturechange", handleEvent);
      window.removeEventListener("gestureend", handleEvent);
      window.removeEventListener("touchend", handleTouchEnd);

      if (window.visualViewport) {
        window.visualViewport.removeEventListener(
          "resize",
          handleVisualViewportResize
        );
      }
    };
  }, []);
};

export default useCloseOnResize;
