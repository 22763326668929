import React, { useEffect, useState } from "react";
import Button from "../../../../../tools/button/Button";
import Style from "./ConfirmUserContact.module.scss";
import {
  ContactVerificationState,
  FieldState,
} from "../../../../../viewModels/enums/PublicEnum";
import { useTranslation } from "react-i18next";
import { BlockTime } from "../../../../../services/commonServices/CommonServices";
import CheckCode from "../../../../../tools/fields/checkCode/CheckCode";
import Timer from "../../../../../tools/timer/Timer";
import Loading from "../../../../../tools/loading/Loading";
import { IServiceResult } from "../../../../../viewModels/api/Api";
import { CheckEmailOrPhoneNumberResponse } from "../../../../../viewModels/dataTypes/registration/SingUp";
import { ReSendOtpService } from "../../../checkOtp/ResendOtp.Services";
import { ResponseCode } from "../../../../../viewModels/enums/ResponseCode";
import { ButtonState } from "../../../../../viewModels/enums/ButtonEnum";
import { ReceiverType } from "../../../../../viewModels/api/Models";
import { EditUserContactLimit } from "../../../../../Setting";

interface Props {
  contactVertificationStep: ContactVerificationState;
  setContactVerificationStep: (value: ContactVerificationState) => void;
  checkUserContactMethod: () => void;
  userContact: string;
  deliveryMethod: ReceiverType;
  buttonState: ButtonState;
  submitOtp: (code: string) => void;
  blockTimerMethod: () => void;
  errorMessage: string;
  fieldState: FieldState;
  resetFromAction: boolean;
  otpLength: number;
  otpDisabled?: boolean;
  setOtpDisabled: (value: boolean) => void; // Add this prop
}

const ConfirmUserContactSteps: React.FC<Props> = ({
  contactVertificationStep,
  setContactVerificationStep,
  checkUserContactMethod,
  userContact,
  deliveryMethod,
  buttonState,
  submitOtp,
  blockTimerMethod,
  errorMessage,
  fieldState = FieldState.Default,
  resetFromAction = false,
  otpLength,
  otpDisabled = false,
  setOtpDisabled,
}) => {
  const [otp, setOtp] = useState<string[]>(Array(otpLength).fill(""));
  const [loading, setLoading] = useState<boolean>(false);
  const [resetForm, setResetForm] = useState<boolean>(false);
  const [otpErrorMessage, setOtpErrorMessage] = useState<string>("");
  const [otpFieldState, setOtpFieldState] = useState(FieldState.Default);
  const [editUserContacCount, setEditUserContacCount] =
    useState(EditUserContactLimit);

  const [, setFinalCode] = useState<string>("");

  const setOtpCode = (nextNumber: string, index: number) => {
    setResetForm(false);
    setOtpErrorMessage("");
    setOtpFieldState(FieldState.Default);
    otp[index] = nextNumber;
    setOtp(otp);

    if (otp.every((item: string) => item.length > 0)) {
      let code: string = "";
      for (var i: number = 0; i < otp.length; i++) {
        code = code + otp[i];
      }
      setFinalCode(code);
      submitOtp(code);
    }
  };

  const resend = async () => {
    setLoading(true);
    localStorage.setItem("otpDisabled", JSON.stringify(false));
    setOtpDisabled(false);
    let result: IServiceResult<CheckEmailOrPhoneNumberResponse> =
      await ReSendOtpService(userContact, deliveryMethod);
    setResetForm(true);
    setOtp(Array(otpLength).fill(""));
    if (result.data?.status === ResponseCode.Accepted) {
      setOtpErrorMessage("");
      setLoading(false);
      setOtpFieldState(FieldState.Default);
    } else {
      setOtpErrorMessage("ERRORS.OPERATION_FAILD");
      setOtpFieldState(FieldState.Error);
      setLoading(false);
    }
  };

  useEffect(() => {
    setOtpErrorMessage(errorMessage);
  }, [errorMessage]);

  useEffect(() => {
    setOtpFieldState(fieldState);
  }, [fieldState]);

  useEffect(() => {
    setResetForm(resetFromAction);
    if (resetFromAction) {
      setOtp(Array(otpLength).fill(""));
    }
  }, [resetFromAction, otpLength]);
  const { t: translate } = useTranslation();

  return (
    <>
      <div className={Style.verify_stepone}>
        {contactVertificationStep === ContactVerificationState.Checking ? (
          <Button
            text={translate("BUTTON.EDIT")}
            clickMethod={() => {
              setEditUserContacCount(editUserContacCount - 1);
              if (editUserContacCount < 1) {
                BlockTime();
                blockTimerMethod();
              } else {
                setContactVerificationStep(ContactVerificationState.Empty);
              }
            }}
            state={buttonState}
          />
        ) : contactVertificationStep === ContactVerificationState.Verified ? (
          <Button text={translate("ALERT.VERIFIED")} state={buttonState} />
        ) : contactVertificationStep === ContactVerificationState.Fill ? (
          <></>
        ) : (
          <Button
            text={translate("BUTTON.SEND_CODE")}
            clickMethod={checkUserContactMethod}
            state={buttonState}
          />
        )}
      </div>
      {contactVertificationStep === ContactVerificationState.Checking && (
        <>
          <div className={Style.verify_email}>
            <div>
              <div>
                <CheckCode
                  label={translate("AUTHENTICATION.GET_OTP")}
                  resetMode={resetForm}
                  setMethod={setOtpCode}
                  errorMessage={otpErrorMessage}
                  fieldState={otpFieldState}
                  otpDisabled={otpDisabled}
                />
              </div>
              <div>
                <Timer action={resend} />
              </div>
            </div>
          </div>
        </>
      )}
      {loading === true && <Loading />}
    </>
  );
};

export default ConfirmUserContactSteps;
