import {
  GetBuildingInfo,
  UpdateBuildingInfo,
} from "../../../../../services/api/Building";
import { ApiResponse, IServiceResult } from "../../../../../viewModels/api/Api";
import { BuildingInfoViewModel } from "../../../../../viewModels/api/Models";
import { IBuilding } from "../../../../../viewModels/dataTypes/building/IBuilding";
import { BuildingUsageEnum } from "../../../../../viewModels/dataTypes/building/IBuildingUsages";
import { IDropDown } from "../../../../../viewModels/dataTypes/tools/DropDownItems";
import { ResponseCode } from "../../../../../viewModels/enums/ResponseCode";

export const BuildingUsagesDropDownItems: IDropDown[] = [
  {
    value: BuildingUsageEnum.Commercial,
    text: BuildingUsageEnum.Commercial,
  },
  {
    value: BuildingUsageEnum.Residential,
    text: BuildingUsageEnum.Residential,
  },
  {
    value: BuildingUsageEnum.Administrative,
    text: BuildingUsageEnum.Administrative,
  },
];

export const GetBuildingInfoService = async (buildingId: string) => {
  var resultModel: IServiceResult<IBuilding> = {
    message: "",
  };

  var result: ApiResponse<IBuilding> = await GetBuildingInfo(
    Number(buildingId)
  );
  if (result.responseStatus == ResponseCode.Success) {
    resultModel.data = result.data;
  } else {
    resultModel.data = null;
    resultModel.message = "ERRORS.OPERATION_FAILD";
  }
  return resultModel;
};

export const UpdateBuildingInfoServices = async (
  building: BuildingInfoViewModel
) => {
  var resultModel: IServiceResult<boolean> = {
    message: "",
  };
  var result: ApiResponse<boolean> = await UpdateBuildingInfo(building);
  if (result.responseStatus == ResponseCode.Success) {
    resultModel.data = result.data;
  } else {
    resultModel.data = null;
    resultModel.message = "ERRORS.OPERATION_FAILD";
  }
  return resultModel;
};

export const GenerateBuildingUsagesList = (
  stringList: string
): IDropDown[] | null => {
  if (!stringList) return null;
  const values = stringList.split(",").map((value) => {
    const buildingUsage = value.trim();
    return {
      value: buildingUsage,
      text: buildingUsage,
    };
  });
  return values;
};
