import { ChangePasswordAction } from "../../../../services/api/UserAuth";
import {
  Localstorage_ChangePassToken,
  Localstorage_UserValidation,
} from "../../../../text/LocalStorageValues";
import { ApiResponse, IServiceResult } from "../../../../viewModels/api/Api";
import { ChangePasswordRequestViewModel } from "../../../../viewModels/api/Models";
import { UserContactValidationModel } from "../../../../viewModels/dataTypes/Cookie";
import { ResponseCode } from "../../../../viewModels/enums/ResponseCode";

//Change Password

export const CheckValidationToChangePassword = (
  theUserValidationInfo: UserContactValidationModel
) => {
  return (
    theUserValidationInfo != null &&
    theUserValidationInfo.id != null &&
    theUserValidationInfo.id.length > 0
  );
};

export const ChangePasswordService = async (
  password: string,
  userId: string
) => {
  var resultModel: IServiceResult<boolean> = {
    message: "",
    data: false,
  };
  if (userId != null && userId.length > 0) {
    let userInfo: ChangePasswordRequestViewModel = {
      userId: userId,
      password: password,
    };
    var result: ApiResponse<boolean> = await ChangePasswordAction(userInfo);
    if (result.responseStatus === ResponseCode.Success) {
      if (result.data == true) {
        localStorage.removeItem(Localstorage_ChangePassToken);
        localStorage.removeItem(Localstorage_UserValidation);
        resultModel.data = true;
      } else {
        resultModel.message = "ERRORS.REPETITIOUS_PASSWORD";
      }
    } else {
      resultModel.message = "ERRORS.OPERATION_FAILD";
    }
  } else {
    resultModel.message = "ERRORS.OPERATION_FAILD";
  }
  return resultModel;
};
