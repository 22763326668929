import {
  Autocomplete,
  AutocompleteRenderInputParams,
  Box,
  CircularProgress,
  TextField,
  createFilterOptions,
} from "@mui/material";
import React, { SyntheticEvent, useEffect, useState } from "react";
import Style from "./AutoComplete.module.scss";
import { SizeType } from "../../../viewModels/dataTypes/tools/ListComponentType";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import { useTranslation } from "react-i18next";
import StyledAutocompletePaper from "../StyledAutocompletePaper";

interface Props<T> {
  label?: string;
  placeHolder?: string;
  items: T[];
  getOptionLabel: (option: T) => string;
  getRenderOption: (option: T) => string;
  onChange: (value: T | null) => void;
  value: T | null;
  size?: SizeType;
  requiredField?: boolean;
  noOptionsText?: string;
}

const AutoComplete = <T,>({
  items,
  label,
  placeHolder,
  getOptionLabel,
  getRenderOption,
  onChange,
  value,
  size = SizeType.large,
  requiredField = false,
  noOptionsText = "ERRORS.NO_OPTIONS",
}: Props<T>) => {
  const onChangeAutoComplete = (selectedItem: T | null) => {
    onChange(selectedItem);
  };
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (items.length > 0) {
      setLoading(false);
    }
  }, [items]);

  const { t: translate } = useTranslation();
  return (
    <div className={Style.auto_complete}>
      <label
        className={`${Style.label} ${value != null ? Style.fill_field : ""}`}
      >
        <span>
          {label}
          {requiredField && "*"}
        </span>
      </label>
      <div className={`${Style.field}`}>
        <Autocomplete
          noOptionsText={translate(noOptionsText)}
          popupIcon={<KeyboardArrowDownIcon className={Style.popup_icon} />}
          clearIcon={<CloseOutlinedIcon className={Style.close_icon} />}
          value={value}
          onChange={(e: SyntheticEvent<Element, Event>, newValue: T | null) => {
            onChangeAutoComplete(newValue);
          }}
          className={`${Style.auto_complete_options} ${
            size === SizeType.small ? Style.small_field : ""
          }`}
          options={items ?? []}
          filterOptions={createFilterOptions({
            matchFrom: "start",
            stringify: getOptionLabel,
          })}
          onOpen={() =>
            items.length === 0 ? setLoading(true) : setLoading(false)
          }
          onClose={() => setLoading(false)}
          getOptionLabel={getOptionLabel}
          renderOption={(
            props: React.HTMLAttributes<HTMLLIElement>,
            option: T
          ) => (
            <Box
              component="li"
              sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
              {...props}
            >
              <span> {getRenderOption(option)}</span>
            </Box>
          )}
          renderInput={(params: AutocompleteRenderInputParams) => (
            <TextField
              className={Style.dropdown}
              {...params}
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <React.Fragment>
                    {loading ? <CircularProgress size={20} /> : null}
                    {params.InputProps.endAdornment}
                  </React.Fragment>
                ),
              }}
              placeholder={placeHolder}
            />
          )}
          PaperComponent={StyledAutocompletePaper}
        />
      </div>
    </div>
  );
};

export default AutoComplete;
