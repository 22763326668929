import {
  BaseSingleInputFieldProps,
  DateValidationError,
  FieldSection,
  UseDateFieldProps,
} from "@mui/x-date-pickers";
import Style from "./FilterDropDownDate.module.scss";
import CustomExpandMoreIcon from "../../icons/CustomeExpandMoreIcon";
import { Dayjs } from "dayjs";
interface ButtonFieldProps
  extends UseDateFieldProps<Dayjs, false>,
    BaseSingleInputFieldProps<
      Dayjs | null,
      Dayjs,
      FieldSection,
      false,
      DateValidationError
    > {
  setOpen?: React.Dispatch<React.SetStateAction<boolean>>;
  open: boolean;
  className?: string;
}
export const LabelButtonField: React.FC<ButtonFieldProps> = (
  props: ButtonFieldProps
) => {
  const {
    setOpen,
    label,
    InputProps: { ref } = {},
    inputProps: { "aria-label": ariaLabel } = {},
    className, 
  } = props;

  const { open } = props;

  return (
    <div
      className={Style.mobile_dropdown_label}
      ref={ref}
      aria-label={ariaLabel}
      onClick={() => setOpen?.((prev) => !prev)}
    >
      <span className={`${Style.label_date} ${className}` }>{label}</span>
      <CustomExpandMoreIcon
        open={open}
        className={`${Style.customExpandIcon}`}
      />
    </div>
  );
};
