import React, { ReactNode, useState } from "react";
import Style from "./UnitAccordion.module.scss";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";

interface Props {
  children: ReactNode;
  title: string;
  unitNumber: string;
  callToActionComponent: ReactNode;
}
const UnitAccordion: React.FC<Props> = ({
  children,
  title,
  unitNumber,
  callToActionComponent,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const accordionStateHandler = () => {
    setIsOpen(!isOpen);
  };
  return (
    <div className={Style.accordion}>
      <div className={Style.accordion_head}>
        <div className={Style.accordion_unit_info}>
          <div className={Style.accordion_unit_number}>
            <div onClick={accordionStateHandler}>
              {isOpen ? (
                <KeyboardArrowDownIcon
                  className={Style.accordion_head_arrow_down}
                />
              ) : (
                <KeyboardArrowRightIcon
                  className={Style.accordion_head_arrow_down}
                />
              )}
            </div>
            <span>{unitNumber}</span>
          </div>
          <h4>{title}</h4>
        </div>
        {callToActionComponent}
      </div>
      <div
        className={`${Style.accordion_content} ${
          isOpen ? Style.accordion_content_open : ""
        }`}
      >
        {children}
      </div>
    </div>
  );
};

export default UnitAccordion;
