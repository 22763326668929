import { Popper, PopperProps } from "@mui/material";

export const CustomPopper: React.FC<PopperProps> = (props: PopperProps) => {
  return (
    <Popper
      {...props}
      placement="top-end"
    />
  );
};
