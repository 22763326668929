import React, { ChangeEvent, ReactElement, useEffect, useState } from "react";
import Style from "./SmartField.module.scss";
import { CheckIsNumber } from "../../../services/Regex";
import { useDispatch, useSelector } from "react-redux";
import { AppThunkDispatch } from "../../../redux/Store";
import { GetCountriesDataAction } from "../../../redux/slices/CountrySlice";
import { IReducer } from "../../../viewModels/reduxInterfaces/ReduxInterfaces";
import { useTranslation } from "react-i18next";
import { CircularProgress } from "@mui/material";
import CountrySelect from "../../dropDown/countryDropDown/CountrySelect";
import {
  DefaultCountryRegen,
  EmailAddressLength,
  PhoneNumberLength,
} from "../../../Setting";
import { MessageHandler } from "../../messagesHandler/MessagesHandler";
import { MessageType } from "../../../viewModels/enums/MessageType";
import { FieldState } from "../../../viewModels/enums/PublicEnum";
import { CountryType } from "../../../viewModels/dataTypes/country/Country";

interface Props {
  onChange: (value: string, country?: CountryType) => void;
  errorMessage?: string | ReactElement | null;
  fieldState: FieldState;
  initialValue?: string; // Add initialValue prop here
}
interface Country {
  symbol: string;
}

const SmartField: React.FC<Props> = ({
  onChange,
  errorMessage,
  fieldState = FieldState.Default,
  initialValue = "", // Default to empty string if undefined
}) => {
  const [editable, setEditable] = useState<boolean>(false);
  const [countries, setCountries] = useState<CountryType[]>([]);
  const [country, setCountry] = useState<CountryType>();
  const [isPhoneNumber, setIsPhoneNumber] = useState<boolean>(false);
  const [inputValue, setInputValue] = useState<string>(initialValue);
  const [smartFieldState, setSmartFieldState] = useState(fieldState);
  const [changeLabelColor, setChangeLabelColor] = useState<boolean>(false);
  const [hasFetchedCountries, setHasFetchedCountries] =
    useState<boolean>(false);

  const countryList = useSelector((state: IReducer) => state.country);
  const dispatch = useDispatch<AppThunkDispatch>();

  useEffect(() => {
    setInputValue(initialValue); // Update inputValue when initialValue changes
  }, [initialValue]);

  useEffect(() => {
    setSmartFieldState(fieldState);
  }, [fieldState]);

  useEffect(() => {
    if (!hasFetchedCountries && countryList.countries.length === 0) {
      dispatch(GetCountriesDataAction());
      setHasFetchedCountries(true);
    } else {
      setCountries(countryList.countries);
      const theUs = countryList.countries.find(
        (a: Country) => a.symbol === DefaultCountryRegen
      );
      setCountry(theUs);
    }
  }, [countryList, dispatch, hasFetchedCountries]);

  const handleValueChange = (value: string) => {
    if (value != null && value.length > 0) {
      setChangeLabelColor(true);
    } else {
      setChangeLabelColor(false);
    }

    let checkNumber = CheckIsNumber(value);

    if (!checkNumber || value.length === 0) {
      setIsPhoneNumber(false);

      setInputValue(value);
      onChange(value, undefined);
    } else {
      setIsPhoneNumber(true);

      setInputValue(value);
      onChange(value, country);
    }
  };

  const changeCountry = (country: CountryType) => {
    setCountry(country);
    onChange(inputValue, country);
  };

  const { t: translate } = useTranslation();

  return (
    <div className={Style.floor_field}>
      {errorMessage?.toString().length === 0 ? (
        <label className={`${changeLabelColor ? Style.fill_field : ""}`}>
          <span>{translate("LABELS.PHONE_OR_EMAIL")}</span>
        </label>
      ) : (
        <MessageHandler type={MessageType.Error} message={errorMessage ?? ""} />
      )}
      <div
        className={`${Style.custome_field} ${
          smartFieldState === FieldState.Error ? Style.error_border : ""
        } ${editable === true ? Style.custome_field_gray : ""}`}
      >
        {isPhoneNumber ? (
          countries.length > 0 && country != null ? (
            <>
              <CountrySelect
                countries={countries}
                setEditCountry={setEditable}
                changeCountry={changeCountry}
                defaultCountry={country}
              />
            </>
          ) : (
            <div className={Style.country_loader}>
              <CircularProgress size={30} />
            </div>
          )
        ) : null}

        {editable === false && (
          <input
            autoCorrect="off"
            autoCapitalize="off"
            className={isPhoneNumber && Style.phone_number}
            value={inputValue}
            maxLength={isPhoneNumber ? PhoneNumberLength : EmailAddressLength}
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              handleValueChange(e.target.value)
            }
            placeholder={
              errorMessage?.toString().length === 0
                ? translate("PLACEHOLDER.PHONE_OR_EMAIL")
                : ""
            }
          />
        )}
      </div>
    </div>
  );
};

export default SmartField;
