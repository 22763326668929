import React, { ChangeEvent, useEffect, useState } from "react";
import CountrySelect from "../../dropDown/countryDropDown/CountrySelect";
import Style from "./PhoneNumberField.module.scss";
import { useDispatch, useSelector } from "react-redux";
import { IReducer } from "../../../viewModels/reduxInterfaces/ReduxInterfaces";
import { AppThunkDispatch } from "../../../redux/Store";
import { GetCountriesDataAction } from "../../../redux/slices/CountrySlice";
import { CircularProgress } from "@mui/material";
import { CheckPhoenValidation } from "../../../services/Regex";
import { useTranslation } from "react-i18next";
import { PhoneNumberLength } from "../../../Setting";
import { FieldState } from "../../../viewModels/enums/PublicEnum";
import { MessageHandler } from "../../messagesHandler/MessagesHandler";
import { MessageType } from "../../../viewModels/enums/MessageType";
import { CountryType } from "../../../viewModels/dataTypes/country/Country";

interface Props {
  defaultCountry?: string;
  onChange: (phoneNumber: string, country?: CountryType) => void;
  onBlur?: () => void;
  _checkValidation?: (value: boolean) => void;
  label?: string;
  value?: string;
  readOnly?: boolean;
  errorMessage?: string | null;
  fieldState: FieldState;
  requiredField?: boolean;
  placeHolder?: string;
}
interface Country {
  symbol: string;
}

const PhoneNumberValidation: React.FC<Props> = ({
  onChange,
  onBlur,
  label = "LABELS.PHONENUMBER",
  value = "",
  readOnly = false,
  errorMessage,
  fieldState = FieldState.Default,
  defaultCountry = "US",
  requiredField = false,
  placeHolder = "PLACEHOLDER.PHONENUMBER",
}) => {
  const [countries, setCountries] = useState<CountryType[]>([]);
  const [, setPreload] = useState<boolean>(true);
  const [editable, setEditable] = useState<boolean>(false);
  const [, setCheckValidation] = useState<boolean>(true);
  const [country, setCountry] = useState<CountryType>();
  const [phoneNumber, setPhoneNumber] = useState<string>("");
  const [hasFetchedCountries, setHasFetchedCountries] =
    useState<boolean>(false);
  const [changeLabelColor, setChangeLabelColor] = useState<boolean>(
    value.length > 0
  );

  const countryList = useSelector((state: IReducer) => state.country);
  const dispatch = useDispatch<AppThunkDispatch>();

  useEffect(() => {
    if (!hasFetchedCountries) {
      dispatch(GetCountriesDataAction());
      setHasFetchedCountries(true);
    } else if (countryList.countries.length > 0) {
      setCountries(countryList.countries);
      const selectedCountry = countryList.countries.find(
        (a: Country) => a.symbol === defaultCountry
      );
      setCountry(selectedCountry);
      setPreload(false);
    }
  }, [countryList.countries, defaultCountry, dispatch, hasFetchedCountries]);

  useEffect(() => {
    setPhoneNumber(value ?? "");
    setChangeLabelColor(value.length > 0);
  }, [value]);

  const changePhonenNumber = (phoneNumber: string) => {
    if (value != null && value.length > 0) {
      setChangeLabelColor(true);
    } else {
      setChangeLabelColor(false);
    }
    setPhoneNumber(phoneNumber);
    onChange(phoneNumber, country);
    const _checkPhoneValidation: boolean = CheckPhoenValidation(phoneNumber);
    setCheckValidation(_checkPhoneValidation);
  };

  const changeCountry = (country: CountryType) => {
    setCountry(country);
    onChange(phoneNumber, country);
  };

  const phoneNumberOnBlur = () => {
    if (onBlur) {
      onBlur();
    }
  };
  const { t: translate } = useTranslation();

  return (
    <div
      className={`${Style.floor_field} ${
        fieldState === FieldState.Error && Style.error
      }`}
    >
      {errorMessage?.length === 0 || errorMessage == null ? (
        <label
          className={`${Style.phone_lable} ${
            changeLabelColor ? Style.fill_field : ""
          }`}
        >
          <span>
            {translate(label)} {requiredField && "*"}
          </span>
        </label>
      ) : (
        <MessageHandler type={MessageType.Error} message={errorMessage ?? ""} />
      )}

      <div
        className={`${editable === true && Style.custome_field_gray} ${
          Style.phonenumber_field
        } ${readOnly ? Style.readonly_field : ""}`}
      >
        {countries.length > 0 && country != null ? (
          <CountrySelect
            countries={countries}
            setEditCountry={setEditable}
            changeCountry={changeCountry}
            defaultCountry={country}
            readOnly={readOnly}
            onBlur={phoneNumberOnBlur}
          />
        ) : (
          <div className={Style.country_loader}>
            <CircularProgress size={24} />
          </div>
        )}

        {!editable ? (
          <input
            autoCorrect="off"
            autoCapitalize="off"
            value={value}
            maxLength={PhoneNumberLength}
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              changePhonenNumber(e.target.value)
            }
            placeholder={
              errorMessage?.length !== 0 ? "" : translate(placeHolder)
            }
            onBlur={phoneNumberOnBlur}
            readOnly={readOnly}
          />
        ) : (
          <></>
        )}
      </div>
    </div>
  );
};

export default PhoneNumberValidation;
