import RequestServicesComponent from "../../../../../tools/table/servicesComponent/requestServiceComponent/RequestServicesComponent";
import RequestStateComponent from "../../../../../tools/table/servicesComponent/stateComponent/RequestStateComponent";
import { BookServiceViewModel } from "../../../../../viewModels/dataTypes/building/BookServiceViewModel";
import {
  AlignmentEnum,
  HeadCellsType,
} from "../../../../../viewModels/dataTypes/DataTableType";

export const headCells: HeadCellsType<BookServiceViewModel>[] = [
  {
    id: "number",
    label: "REQUESTS.NUMBER",
    align: AlignmentEnum.Left,
    sortable: true,
    specialComponent: null,
    cellSize: {
      lg: 56,
      md: 56,
      sm: 56,
    },
    isLink: true,
  },
  {
    id: "id",
    label: "REQUESTS.REQUEST_NUMBER",
    align: AlignmentEnum.Left,
    sortable: true,
    specialComponent: null,
    cellSize: {
      lg: 120,
      md: 100,
      sm: 100,
    },
    isLink: true,
  },
  {
    id: "serviceTitle",
    label: "REQUESTS.SERVICES",
    align: AlignmentEnum.Left,
    sortable: true,
    specialComponent: RequestServicesComponent,
    specialComponentProps: ["serviceTitle", "serviceIcon", "serviceIconColor"],
    cellSize: {
      lg: 195,
      md: 150,
      sm: 150,
    },
    isLink: true,
  },
  {
    id: "unitNumber",
    label: "REQUESTS.UNIT_NUMBER",
    align: AlignmentEnum.Left,
    sortable: true,
    specialComponent: null,
    cellSize: {
      lg: 100,
      md: 100,
      sm: 100,
    },
    isLink: true,
  },
  {
    id: "createdDateTime",
    label: "REQUESTS.REQUEST_DATE",
    align: AlignmentEnum.Left,
    sortable: true,
    specialComponent: null,
    cellSize: {
      lg: 195,
      md: 150,
      sm: 120,
    },
    isLink: true,
  },
  {
    id: "lastUpdateDateTime",
    label: "REQUESTS.LAST_UPDATE",
    align: AlignmentEnum.Left,
    sortable: true,
    specialComponent: null,
    cellSize: {
      lg: 195,
      md: 150,
      sm: 120,
    },
    isLink: true,
  },
  {
    id: "status",
    label: "REQUESTS.STATE",
    align: AlignmentEnum.Left,
    sortable: false,
    specialComponent: RequestStateComponent,
    specialComponentProps: ["status", "id"],
    cellSize: {
      lg: 120,
      md: 120,
      sm: 120,
    },
    isLink: false,
  },
];
