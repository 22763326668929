import React, { useEffect, useState } from "react";
import Style from "./AddAdminForm.module.scss";
import { Grid } from "@mui/material";
import { useTranslation } from "react-i18next";
import useWindowSize from "../../../../../../hook/WindowSize";
import TextField from "../../../../../../tools/fields/textField/TextField";
import PhoneNumberValidation from "../../../../../../tools/fields/phoneNumberField/PhoneNumberField";
import { FieldState } from "../../../../../../viewModels/enums/PublicEnum";
import CheckBox from "../../../../../../tools/dropDown/checkBoxDropDown/CheckBox";
import {
  AddAdministratorService,
  CheckExistAdminService,
  GetAdminRolesService,
} from "./AddAdminForm.Service";
import { IDropDown } from "../../../../../../viewModels/dataTypes/tools/DropDownItems";
import DashboardFormTitle from "../../../../../../tools/dashboardTitle/dashboardFormTitle/DashboardFormTitle";
import { Key, Profile } from "iconsax-react";
import Button from "../../../../../../tools/button/Button";
import {
  ButtonState,
  ButtonTheme,
} from "../../../../../../viewModels/enums/ButtonEnum";
import {
  CheckPhoenValidation,
  EmailRegex,
} from "../../../../../../services/Regex";
import { GetUserInfoService } from "../../../../../beforeDashboard/login/confirmationUserInfo/ConfirmationUserInfo.Service";
import { IUser } from "../../../../../../viewModels/dataTypes/user/User";
import { IServiceResult } from "../../../../../../viewModels/api/Api";
import { CountryType } from "../../../../../../viewModels/dataTypes/country/Country";
import Loading from "../../../../../../tools/loading/Loading";
import { useNavigate, useParams } from "react-router-dom";
import { CancelAddAdmin, ExistAdminPopup } from "./AddAdminPopUps";
import { ConvertIDropDownToString } from "../../../../../../services/commonServices/CommonServices";
import {
  FieldStopTypingTime,
  MediumDeviceTrigger,
} from "../../../../../../Setting";
import FirstLogin from "../../firstLogin/FirstLogin";
import { ToastType } from "../../../../../../viewModels/enums/ToastType";
import { CreateToast } from "../../../../../../tools/toast/ToastService";
import {
  CheckEmailValidation,
  CheckPhoneNumberValidation,
} from "../addResidentForm/AddResident.Service";
import { AddAdministratorViewModel } from "../../../../../../viewModels/api/Models";
import { GetBuildingUsersAction } from "../../../../../../redux/slices/BuildingUsersSlice";
import { useDispatch } from "react-redux";
import { AppThunkDispatch } from "../../../../../../redux/Store";
import useBodyScrollLock from "../../../../../../hook/BodyScrollLock";
import { setHasUnsavedChanges } from "../../../../../../redux/slices/UnsavedChangesSlice";

const AddAdminForm: React.FC = () => {
  const { t: translate } = useTranslation();
  const windowSize = useWindowSize();
  const { buildingId } = useParams();
  const navigate = useNavigate();
  //States
  const [phoneNumber, setPhoneNumber] = useState<string>("");
  const [email, setEmail] = useState("");
  const [firstName, setFirstName] = useState<string>("");
  const [lastName, setLastName] = useState<string>("");
  const [userResponsibilities, setUserResponsibilities] = useState<
    IDropDown[] | null
  >(null);
  const [country, setCountry] = useState<CountryType | null>(null);
  const [adminRoles, setAdminRoles] = useState<IDropDown[]>([]);

  const [buttonState, setButtonState] = useState<ButtonState>(
    ButtonState.disable
  );
  const [fieldReedOnly, setFieldReedOnly] = useState(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [existAdminPopUp, setExistAdminPopUp] = useState(false);
  const [cancelAddAdminState, setcancelAddAdminState] = useState(false);
  const [userFirstLogin, setUserFirstLogin] = useState<boolean>(false);
  const [mandatoriesChange, setMandatoriesChange] = useState<boolean>(false);
  const [phoneNumberErrorMessage, setPhoneNumberErrorMessage] =
    useState<string>("");
  const [phoneNumberFieldState, setPhoneNumberFieldState] =
    useState<FieldState>(FieldState.Default);
  const [emailFieldErrorMessage, setEmailFieldErrorMessage] =
    useState<string>("");
  const [emailStateField, setEmailStateField] = useState<FieldState>(
    FieldState.Default
  );
  //Effects

  const dispatch = useDispatch<AppThunkDispatch>();

  useEffect(() => {
    const loadData = async () => {
      const adminRoles = await GetAdminRolesService();
      if (adminRoles.data) {
        setAdminRoles(adminRoles.data);
      }
    };
    loadData();
  }, []);

  useEffect(() => {
    const areMandatoriesFilled = Boolean(
      phoneNumber && email && userResponsibilities
    );
    const isAnyFieldFilled = Boolean(
      phoneNumber || email || userResponsibilities || firstName || lastName
    );

    setButtonState(
      areMandatoriesFilled ? ButtonState.default : ButtonState.disable
    );
    setMandatoriesChange(isAnyFieldFilled);
  }, [phoneNumber, email, userResponsibilities, firstName, lastName]);

  useEffect(() => {
    const timeout = setTimeout(checkPhoneNumber, FieldStopTypingTime); // Adjust the timeout value as needed

    return () => clearTimeout(timeout);
  }, [phoneNumber, country]);

  useEffect(() => {
    const timeout = setTimeout(checkEmailAddress, FieldStopTypingTime); // Adjust the timeout value as needed

    return () => clearTimeout(timeout);
  }, [email]);
  //Functions

  const checkEmailAddress = async () => {
    if (!fieldReedOnly && EmailRegex(email)) {
      setLoading(true);
      let userInfo: IServiceResult<IUser> = await GetUserInfoService(email);
      if (userInfo.data) {
        fillExistUserInfo(userInfo.data);
        setFieldReedOnly(true);
        const checkExistAdminResult = await CheckExistAdminService(
          userInfo.data.id,
          buildingId ?? ""
        );
        if (checkExistAdminResult.data != null) {
          setExistAdminPopUp(true);
        }
      }
      setLoading(false);
    }
  };

  const checkPhoneNumber = async () => {
    if (!fieldReedOnly && CheckPhoenValidation(phoneNumber)) {
      setLoading(true);
      let userInfo: IServiceResult<IUser> = await GetUserInfoService(
        country?.code + phoneNumber
      );
      if (userInfo.data) {
        fillExistUserInfo(userInfo.data);
        setFieldReedOnly(true);
        const checkExistAdminResult = await CheckExistAdminService(
          userInfo.data.id,
          buildingId ?? ""
        );

        if (checkExistAdminResult.data != null) {
          setExistAdminPopUp(true);
        }
      }
      setLoading(false);
    }
  };

  const fillExistUserInfo = (userInfo: IUser) => {
    setFirstName(userInfo.firstName);
    setLastName(userInfo.lastName);
    setPhoneNumber(userInfo.phoneNumber.replace(userInfo.countryCode, ""));
    var userCountry: CountryType = {
      code: userInfo.countryCode,
      name: userInfo.countryName,
      symbol: userInfo.countryAbbreviation,
      id: userInfo.countryId,
    };
    setCountry(userCountry);
    setUserFirstLogin(userInfo.isConfirmed);
    setEmail(userInfo.email);
  };

  const onChangePhoneNumber = (
    value: string,
    countryValue?: CountryType | null
  ) => {
    setPhoneNumberErrorMessage("");
    setPhoneNumberFieldState(FieldState.Default);
    setPhoneNumber(value);
    if (countryValue != null) {
      setCountry(countryValue);
    }
  };

  const onChangeEmail = (email: string) => {
    setEmail(email);
    setEmailFieldErrorMessage("");
    setEmailStateField(FieldState.Default);
  };

  useEffect(() => {
    if (mandatoriesChange === true) {
      dispatch(setHasUnsavedChanges(true));
    } else {
      dispatch(setHasUnsavedChanges(false));
    }
  }, [mandatoriesChange]);

  const cancelAddAdminForm = () => {
    if (mandatoriesChange) {
      setcancelAddAdminState(true);
    } else {
      setcancelAddAdminState(false);
      navigate(-1);
    }
  };

  const closeCancelAddAdminPopUp = () => {
    setcancelAddAdminState(false);
  };

  const submitAdminInfo = async () => {
    setLoading(true);

    //check phonenumber validation to submit
    const phoneNumberValidation: boolean = CheckPhoneNumberValidation(
      setPhoneNumberErrorMessage,
      setPhoneNumberFieldState,
      phoneNumber
    );
    //check email address validation to submit
    const emailValidation: boolean = CheckEmailValidation(
      setEmailFieldErrorMessage,
      setEmailStateField,
      email
    );

    if (!phoneNumberValidation || !emailValidation) {
      setLoading(false);
      return;
    }
    const adminInfo: AddAdministratorViewModel = {
      buildingId: Number(buildingId),
      countryId: country?.id ?? 0,
      email: email,
      firstName: firstName,
      lastName: lastName,
      phoneNumber: phoneNumber,
      userRoleIds: ConvertIDropDownToString(userResponsibilities),
    };
    const addAdminResult = await AddAdministratorService(adminInfo);
    if (addAdminResult.data) {
      restartFrom();
      dispatch(GetBuildingUsersAction(buildingId ?? ""));
      setMandatoriesChange(false);
      dispatch(setHasUnsavedChanges(false));
      CreateToast(ToastType.Success, translate("ALERT.ADD_ADMIN_SUCCESSFULLY"));
      setLoading(false);
    } else {
      CreateToast(ToastType.Error, translate("ERRORS.ADD_ADMIN_FAILED"));
    }
    dispatch(setHasUnsavedChanges(false));
  };

  const updateUserResponsibilities = async (value: IDropDown[] | null) => {
    if (value?.length !== 0) {
      setUserResponsibilities(value);
    } else {
      setUserResponsibilities(null);
    }
  };

  const restartFrom = () => {
    setEmail("");
    setPhoneNumber("");
    setFirstName("");
    setLastName("");
    setMandatoriesChange(false);
    dispatch(setHasUnsavedChanges(false));
    setUserResponsibilities(null);
    setCountry(null);
    setFieldReedOnly(false);
    setUserFirstLogin(false);
  };

  // Use custom hook to lock body scroll
  useBodyScrollLock(existAdminPopUp);
  return (
    <>
      <div className={Style.new_admin}>
        <div className={Style.new_admin_form}>
          <div className={Style.new_admin_info}>
            <DashboardFormTitle
              title={translate("ADMIN.ADMIN_INFO")}
              icon={<Profile />}
              className={Style.new_admin_title}
              children={<FirstLogin isFirstLogin={userFirstLogin} />}
            />
            <Grid
              className={Style.new_admin_box}
              container
              columnSpacing={windowSize.width > MediumDeviceTrigger ? 8 : 6}
            >
              <Grid item md={6} sm={12} xs={12}>
                <TextField
                  errorMessage={translate(emailFieldErrorMessage)}
                  value={email}
                  onChange={onChangeEmail}
                  label={translate("LABELS.EMAIL")}
                  placeHolder={translate("PLACEHOLDER.USERTYPE_EMAIL", {
                    userType: translate("SHARED.ADMINISTRATOR").toLowerCase(),
                  })}
                  requiredField
                  readOnly={fieldReedOnly}
                  fieldState={emailStateField}
                />
              </Grid>
              <Grid item md={6} sm={12} xs={12}>
                <PhoneNumberValidation
                  errorMessage={translate(phoneNumberErrorMessage)}
                  value={phoneNumber}
                  onChange={onChangePhoneNumber}
                  label={translate("LABELS.PHONENUMBER")}
                  placeHolder={translate("PLACEHOLDER.USERTYPE_PHONE_NUMBER", {
                    userType: translate("SHARED.ADMINISTRATOR").toLowerCase(),
                  })}
                  fieldState={phoneNumberFieldState}
                  defaultCountry={country?.symbol}
                  readOnly={fieldReedOnly}
                  requiredField
                />
              </Grid>
              <Grid item md={6} sm={12} xs={12}>
                <TextField
                  value={firstName}
                  onChange={setFirstName}
                  label={translate("LABELS.FIRSTNAME")}
                  placeHolder={translate("PLACEHOLDER.USERTYPE_FIRSTNAME", {
                    userType: translate("SHARED.ADMINISTRATOR").toLowerCase(),
                  })}
                  readOnly={fieldReedOnly}
                />
              </Grid>

              <Grid item md={6} sm={12} xs={12}>
                <TextField
                  value={lastName}
                  onChange={setLastName}
                  label={translate("LABELS.LASTNAME")}
                  placeHolder={translate("PLACEHOLDER.USERTYPE_LASTNAME", {
                    userType: translate("SHARED.ADMINISTRATOR").toLowerCase(),
                  })}
                  readOnly={fieldReedOnly}
                />
              </Grid>
            </Grid>
          </div>
          <div className={Style.new_admin_permission}>
            <DashboardFormTitle
              title={translate("ADMIN.ADMIN_PERMISSION_RESPONSIBILITY")}
              icon={<Key />}
              className={Style.new_admin_title}
            />
            <Grid
              className={Style.new_admin_box}
              container
              columnSpacing={windowSize.width > MediumDeviceTrigger ? 8 : 6}
            >
              <Grid item md={6} sm={12} xs={12}>
                <CheckBox
                  placeHolder={translate("PLACEHOLDER.RESPONSIBILITIES")}
                  values={userResponsibilities}
                  label={translate("LABELS.RESPONSIBILITIES")}
                  onChange={updateUserResponsibilities}
                  data={adminRoles}
                  requiredField
                />
              </Grid>
            </Grid>
          </div>
        </div>
        <div className={Style.new_admin_buttons}>
          <Button
            theme={ButtonTheme.primaryReverse}
            text={translate("BUTTON.BACK")}
            clickMethod={cancelAddAdminForm}
          />
          <Button
            text={translate("BUTTON.ADD")}
            clickMethod={submitAdminInfo}
            state={buttonState}
            theme={ButtonTheme.primary}
          />
          {loading && <Loading />}
        </div>
      </div>
      {existAdminPopUp && <ExistAdminPopup />}
      {cancelAddAdminState && (
        <CancelAddAdmin cancelButton={closeCancelAddAdminPopUp} />
      )}
    </>
  );
};

export default AddAdminForm;
