import { CheckPhoenValidation, EmailRegex } from "../../../../services/Regex";
import {
  CheckEmailToSignUp,
  CheckPhoneNumberToSignUp,
} from "../../../../services/api/User";
import { Localstorage_UserValidation } from "../../../../text/LocalStorageValues";
import { ResponseCode } from "../../../../viewModels/enums/ResponseCode";
import {
  IServiceResult,
  ApiResponse,
} from "../../../../viewModels/api/Api";
import { UserContactValidationModel } from "../../../../viewModels/dataTypes/Cookie";
import { CheckEmailOrPhoneNumberResponse } from "../../../../viewModels/dataTypes/registration/SingUp";
import { CountryType } from "../../../../viewModels/dataTypes/country/Country";
import { ReceiverType } from "../../../../viewModels/api/Models";

export const SuperAdminLoginServiceViaPhoneNumber = async (
  phoneNumber: string,
  country: CountryType | undefined
) => {
  // define a parameter to handle Api response
  var result: IServiceResult<CheckEmailOrPhoneNumberResponse> & {
    route: string;
  } = {
    message: "",
    route: "",
  };
  // check phone number validation
  let isValidated = CheckPhoenValidation(phoneNumber);
  if (isValidated) {
    // call check user phone number Api
    const setPhoneNumber: ApiResponse<CheckEmailOrPhoneNumberResponse> =
      await CheckPhoneNumberToSignUp(country?.code + phoneNumber);
    if (setPhoneNumber.responseStatus == ResponseCode.Success) {
      //when the response status of request is 200
      if (setPhoneNumber.data?.status == ResponseCode.Accepted) {
        // when there aren't any problem to sign-up with the phone number
        var newUserValidation: UserContactValidationModel = {
          userContact: phoneNumber,
          userCountry: country ?? null,
          otpDeliveryMethod: ReceiverType.PhoneNumber,
        };
        localStorage.setItem(
          Localstorage_UserValidation,
          JSON.stringify(newUserValidation)
        );
        result.route = "/signup/checkSmsOtp";
        result.data = setPhoneNumber.data;
      } else if (setPhoneNumber.data?.status == ResponseCode.Created) {
        // when the phone number is resgistred
        result.message = "ERRORS.SIGNUP_ENTER_DEFERENT_PHONENUMBER";
        result.data = setPhoneNumber.data;
      } else {
        result.message = "ERRORS.TRY_OTP_LATER";
      }
    } else {
      result.message = "ERRORS.OPERATION_FAILD";
    }
  } else {
    result.message = "ERRORS.INVALID_PHONENUMBER";
  }
  return result;
};

export const SuperAdminLoginServiceViaEmail = async (email: string) => {
  // define a parameter to handle Api response
  var result: IServiceResult<CheckEmailOrPhoneNumberResponse> & {
    route: string;
  } = {
    message: "",
    route: "",
  };
  // check email validation
  let isValdate = EmailRegex(email);
  if (isValdate) {
    // call check user email address Api
    const setEmail: ApiResponse<CheckEmailOrPhoneNumberResponse> =
      await CheckEmailToSignUp(email);
    if (setEmail.responseStatus == ResponseCode.Success) {
      //when the response status of request is 200
      if (setEmail.data?.status == ResponseCode.Accepted) {
        // when there aren't any problem to sign-up with the email address
        var newUserValidation: UserContactValidationModel = {
          userContact: email,
          userCountry: null,
          otpDeliveryMethod: ReceiverType.Email,
        };
        localStorage.setItem(
          Localstorage_UserValidation,
          JSON.stringify(newUserValidation)
        );
        result.route = "/signup/checkEmailOtp";
        result.data = setEmail.data;
      } else if (setEmail.data?.status == ResponseCode.Created) {
        // when the email address is resgistred
        result.message = "ERRORS.SIGNUP_ENTER_DEFETENT_EMAIL";
        result.data = setEmail.data;
      } else {
        result.message = "ERRORS.TRY_OTP_LATER";
      }
    } else {
      result.message = "ERRORS.OPERATION_FAILD";
    }
  } else {
    result.message = "ERRORS.INVALID_EMAIL";
  }
  return result;
};
