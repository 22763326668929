import { ActionReducerMapBuilder, PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { GetAdminRolesService } from "../../components/dashboard/dashboardContents/users/addUser/addAdminForm/AddAdminForm.Service";
import { IServiceResult } from "../../viewModels/api/Api";
import { IDropDown } from "../../viewModels/dataTypes/tools/DropDownItems";
import { IAdminResponsibilitiesReducer } from "../../viewModels/reduxInterfaces/ReduxInterfaces";

const initialState = {
  responsibilities: [],
  loading: true,
  fill: false,
  err: "",
} as IAdminResponsibilitiesReducer;

const AdminResponsibilitiesSlice = createSlice({
  name: "adminResponsibilities",
  initialState,
  reducers: {},
  extraReducers: (builder : ActionReducerMapBuilder<IAdminResponsibilitiesReducer>) => {
    builder.addCase(GetAdminRolesAction.pending, (State : IAdminResponsibilitiesReducer) => {
      State.loading = true;
    });
    builder.addCase(
      GetAdminRolesAction.fulfilled,
      (State, action: PayloadAction<IDropDown[]>) => {
        State.responsibilities = action.payload;
        State.loading = false;
        State.fill = true;
      }
    );
    builder.addCase(GetAdminRolesAction.rejected, (State) => {
      State.loading = false;
      State.err = "Loading Faild";
    });
  },
});

export const GetAdminRolesAction = createAsyncThunk(
  "GetAdminRolesService",
  async () => {
    try {
      const adminResponsibilities: IServiceResult<IDropDown[]> =
        await GetAdminRolesService();
      if (adminResponsibilities.data) {
        return adminResponsibilities.data;
      } else {
        return [];
      }
    } catch (err) {
      return [];
    }
  }
);

export default AdminResponsibilitiesSlice.reducer;
