import React, { useRef, useState } from "react";
import { Swiper, SwiperClass, SwiperSlide } from "swiper/react";
import Style from "./ImageSlider.module.scss";
// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation"; // Import Swiper navigation styles
import ImageItem from "./imageItem/ImageItem";
import { Navigation } from "swiper/modules"; // Import Navigation module
import {
  GetFileFormat,
  GetFileName,
} from "../../../services/commonServices/filesCommonServices/FilesCommonServices";
import DownloadButton from "../../button/DownloadButton";
import ArrowBackIosOutlinedIcon from "@mui/icons-material/ArrowBackIosOutlined";
import ArrowForwardIosOutlinedIcon from "@mui/icons-material/ArrowForwardIosOutlined";
import { Attachments } from "../../../viewModels/api/Models";
import useWindowSize from "../../../hook/WindowSize";

interface Props {
  urls: Attachments[];
  onClick: (value: string) => void;
}
const ImageSlider: React.FC<Props> = ({ urls, onClick }) => {
  // #region confif
  const size = useWindowSize();
  // #endregion
  const [swiper, setSwiper] = useState<SwiperClass | null>(null);
  const handlePrevClick = () => {
    if (swiper) {
      swiper.slidePrev();
    }
  };

  const handleNextClick = () => {
    if (swiper) {
      swiper.slideNext();
    }
  };

  return (
    <div className={Style.slider_container}>
      <Swiper
        effect="coverflow"
        spaceBetween={8}
        slidesPerView={2}
        navigation={true}
        className={Style.slider}
        modules={[Navigation]}
        onSwiper={setSwiper}
        breakpoints={{
          768: {
            slidesPerView: 4,
            spaceBetween: 20, // Adjust space between slides for larger screens
          },
        }}
      >
        {urls.map((item: Attachments, index: number) => (
          <SwiperSlide key={index}>
            <ImageItem
              onClick={() => onClick(item.filePath ?? "")}
              src={item.filePath ?? ""}
              fileName={GetFileName(item.filePath ?? "")}
              callToAction={
                <DownloadButton
                  fileName={GetFileName(item.filePath ?? "")}
                  fileUrl={item.filePath ?? ""}
                />
              }
              format={GetFileFormat(item.filePath ?? "")}
            />
          </SwiperSlide>
        ))}
      </Swiper>
      {(size.width > 768 && urls.length > 4) ||
        (size.width < 768 && urls.length > 2 && (
          <>
            <div
              className={`${Style.slider_icon} ${Style.prev} swiper-button-prev`}
              onClick={handlePrevClick}
            >
              <ArrowBackIosOutlinedIcon />
            </div>
            <div
              className={`${Style.slider_icon} ${Style.next} swiper-button-next`}
              onClick={handleNextClick}
            >
              <ArrowForwardIosOutlinedIcon />
            </div>
          </>
        ))}
    </div>
  );
};

export default ImageSlider;
