export enum ButtonTheme {
  default = "button",
  primary = "primary_button",
  primaryReverse = "reverse_button",
  accent = "accent_button",
  delete = "delete_button",
  deleteReverse = "reverse_delete_button",
}
export enum ButtonState {
  default = "default",
  disable = "disable_button",
  success = "success_button",
}
