import React, { ReactElement } from "react";
import Style from "./MessageHandler.module.scss";
import { MessageType } from "../../viewModels/enums/MessageType";
import { useTranslation } from "react-i18next";
interface Props {
  message?: ReactElement | string | null;
  type?: MessageType | null;
  alignCenter?: boolean;
}

export const MessageHandler: React.FC<Props> = ({
  message = "",
  type = null,
  alignCenter = false,
}) => {
  return (
    <div
      className={`${Style.error_message} ${
        type === MessageType.Success && Style.success_msg
      } ${alignCenter && Style.center}`}
    >
      <span>{message}</span>
    </div>
  );
};
