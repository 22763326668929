import { UpdateComplaint } from "../../../../../../services/api/Complaint";
import { EnumToList } from "../../../../../../services/commonServices/CommonServices";
import {
  ApiResponse,
  IServiceResult,
} from "../../../../../../viewModels/api/Api";
import {
  Complaint,
  ComplaintState,
} from "../../../../../../viewModels/api/Models";
import {
  IDropDown,
  IStateDropDown,
} from "../../../../../../viewModels/dataTypes/tools/DropDownItems";
import { ResponseCode } from "../../../../../../viewModels/enums/ResponseCode";

export const UpdateComplaintService = async (info: Complaint) => {
  const resultModel: IServiceResult<boolean> = {
    message: "",
    data: null,
  };
  const updateComplaintResult: ApiResponse<boolean> = await UpdateComplaint(
    info
  );
  if (updateComplaintResult.responseStatus == ResponseCode.Success) {
    resultModel.data = updateComplaintResult.data;
  }

  return resultModel;
};

export const ComplaintStateColors = [
  "var(--color-new)",
  "var(--color-resolved)",
  "var(--color-inprogress)",
  "var(--color-unresolved)",
];

export const GetComplaintStates = () => {
  const statesInfo = EnumToList(ComplaintState);
  const _states: IStateDropDown[] = [];
  statesInfo.map((state: IDropDown, index: number) =>
    _states.push({
      color: ComplaintStateColors[index],
      text: state.text,
      value: state.value,
    })
  );
  return _states;
};

export const GetDropDownItem = (
  value: ComplaintState,
  items: IStateDropDown[]
) => {
  return (
    items.find(
      (selectedItem: IStateDropDown) => selectedItem.value === value.toString()
    ) ?? items[0]
  );
};
