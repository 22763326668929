import React from "react";
import HeroSrctions from "./heroSection/HeroSections";
import ServicesSection from "./servicesSection/ServicesSection";
import PrimaryFooter from "../footer/primaryFooter/PrimaryFooter";

const Home: React.FC = () => {
  return (
    <div>
      <HeroSrctions />
      <ServicesSection />
      <PrimaryFooter />
    </div>
  );
};

export default Home;
