import {DatePickerProps, DesktopDatePicker } from "@mui/x-date-pickers";
import { Dayjs } from "dayjs";
import React, { useState } from "react";
import { LabelButtonField } from "./LabelButtonField";
import { CustomPopper } from "./CustomProper";

export const ButtonDatePicker = (
  props: Omit<DatePickerProps<Dayjs>, "open" | "onOpen" | "onClose"> & {
    className?: string;
  }
) => {
  const [open, setOpen] = useState<boolean>(false);

  return (
    <DesktopDatePicker
      {...props}
      open={open}
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      slots={{ ...props.slots, field: LabelButtonField, popper: CustomPopper}}
      slotProps={{
        ...props.slotProps,
        field: { setOpen, open, className: props.className } as any,
      }}
    />
  );
};
