import { ChangeEvent } from "react";

const useSyncInputValue: (
  propValue: string | undefined,
  onChange?: (value: string) => void
) => readonly [(e: ChangeEvent<HTMLInputElement>) => void] = (
  propValue: string | undefined,
  onChange?: (value: string) => void
) => {
  const handleChange: (e: ChangeEvent<HTMLInputElement>) => void = (
    e: ChangeEvent<HTMLInputElement>
  ) => {
    const { value } = e.target;
    if (onChange) {
      onChange(value);
    }
  };

  return [handleChange] as const;
};

export default useSyncInputValue;
