import styled from "styled-components";

export const ContentContainer = styled.div`
  padding: 1rem 5rem 0;
  overflow-y: auto;
  scrollbar-width: none;
  display: flex;
  flex-direction: column;
  max-width: 100%;
  > div {
    &:first-child {
      flex: 1 1;
    }
  }
  @media (min-width: 1400px) {
    padding: 1rem 8rem 0;
  }
  @media screen and (max-width: 1200px) and (max-height: 900px) {
    padding: 1.25rem 1.65rem;
  }

  @media (max-width: 900px) {
    padding: 0 0.5rem;
  }
`;
