import React from "react";
import StateComponent from "./StateComponent";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { GetBuildingComplaintsAction } from "../../../redux/slices/ComplaintsSlice";
import { TicketState } from "../../../viewModels/api/Models";
import { AppThunkDispatch } from "../../../redux/Store";
import MockTickets from "../../../mockData/MockTickets.json"; // Import mock data

interface Props {
  id: number;
  isHovered?: boolean | undefined;
  status: number;
}

const TicketStateComponent: React.FC<Props> = ({ status, isHovered, id }) => {
  const { buildingId } = useParams();
  const dispatch = useDispatch<AppThunkDispatch>();
  const handleSelectState = async (index: number) => {
    await updateState(id, index);
    dispatch(GetBuildingComplaintsAction(buildingId!));
  };

  const updateState = async (ticketId: number, newStatus: number) => {
    // Find the ticket by its ID and update its status in the mock data
    const ticketIndex = MockTickets.findIndex(
      (ticket) => ticket.id === ticketId
    );
    if (ticketIndex !== -1) {
      MockTickets[ticketIndex].status = newStatus;
    }

    // const newStateInfo = {
    //   id: ticketId,
    //   status: newStatus,
    // };
    // await UpdateTicketState(newStateInfo);
  };

  const statusLabels = Object.keys(TicketState).filter((key: string) =>
    isNaN(Number(key))
  );
  const stateColors = [
    "var(--color-new)",
    "var(--color-resolved)",
    "var(--color-inprogress)",
    "var(--color-unresolved)",
  ];

  return (
    <div>
      <StateComponent
        status={status}
        stateItems={statusLabels}
        stateColors={stateColors}
        hovered={isHovered}
        onSelect={handleSelectState}
        hideUnResolved={status !== TicketState.Resolved}
      />
    </div>
  );
};

export default TicketStateComponent;
