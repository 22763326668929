import { TableCell, TableRow } from "@mui/material";
import { styled } from "@mui/material/styles";
import { ITabelCellSize } from "../../viewModels/dataTypes/tools/TabelCellSize";

interface Props {
  cellWidth?: ITabelCellSize;
}
export const StyledTableCell = styled(TableCell)<Props>`
  min-width: ${(a) => (a.cellWidth != null ? `${a.cellWidth.lg}px` : "auto")};
  @media screen and (max-width: 1200px), screen and (max-height: 900px) {
    min-width: ${(a) => (a.cellWidth != null ? `${a.cellWidth.md}px` : "auto")};
  }
  @media (max-width: 900px) {
    min-width: ${(a) => (a.cellWidth != null ? `${a.cellWidth.sm}px` : "auto")};
  }
`;

export const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&.hovered": {
    backgroundColor: theme.palette.action.hover, // Apply hover style if needed
  },
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));
