import React, { useEffect, useState } from "react";
import Style from "./NewComment.module.scss";
import TextField from "../../fields/textField/TextField";
import {
  GetUserTokenInfo,
} from "../../../services/commonServices/CommonServices";
import { useDispatch, useSelector } from "react-redux";
import { AppThunkDispatch } from "../../../redux/Store";
import { IReducer } from "../../../viewModels/reduxInterfaces/ReduxInterfaces";
import { UserCredentialInfoViewModel } from "../../../viewModels/api/Models";
import { GetUserDataAction } from "../../../redux/slices/UserSlice";
import { useTranslation } from "react-i18next";
import LargeAvatar from "../../defaultAvatar/LargeAvatar";

interface Props {
  value: string;
  onChange: (value: string) => void;
}
const NewComment: React.FC<Props> = ({ value, onChange }) => {
  const dispatch = useDispatch<AppThunkDispatch>();
  const { t: translate } = useTranslation();
  const userCredential = useSelector((state: IReducer) => state.user);
  const [userCredentialInf, setUserCredentialInfo] =
    useState<UserCredentialInfoViewModel | null>(null);

  useEffect(() => {
    if (userCredential.userLogin) {
      setUserCredentialInfo(userCredential.user);
    } else {
      const userToken = GetUserTokenInfo();
      dispatch(GetUserDataAction(userToken?.userId ?? ""));
    }
  }, [userCredential]);

  return (
    <div className={Style.comment_box}>
      <div className={Style.avatar}>
        {userCredentialInf?.userAvatar === null ? (
          <LargeAvatar />
        ) : (
          <div className={Style.logo}>
            <img
              src={userCredentialInf?.userAvatar}
              alt={`${`${userCredentialInf?.firstName} ${userCredentialInf?.lastName}`} avatar`}
            />
          </div>
        )}
      </div>
      <div className={Style.comment_field}>
        <TextField
          value={value}
          onChange={onChange}
          placeHolder={translate("PLACEHOLDER.ADD_COMMENT")}
        />
      </div>
    </div>
  );
};

export default NewComment;
