import React from "react";
import Style from "./ClearCheckedDropDown.module.scss";
import CloseIcon from "@mui/icons-material/Close";

interface Props {
  text?: string;
  active?: boolean;
  onClear?: () => void; 
}

const ClearCheckedDropDown: React.FC<Props> = ({ text, active, onClear }) => {
  return (
    <div
      className={`${Style.container_clear} ${active ? Style.active : ""}`}
      onClick={active ? onClear : undefined} 
    >
      <CloseIcon className={Style.close_icon} />
      <span className={Style.checked_clear}>{text}</span>
    </div>
  );
};

export default ClearCheckedDropDown;
