import React, { useEffect, useState } from "react";
import { IDropDown } from "../../../../../viewModels/dataTypes/tools/DropDownItems";
import AddNewPopups from "../../../../../tools/popup/addNewPopups/AddNewPopups";
import { useTranslation } from "react-i18next";
import CampaignOutlinedIcon from "@mui/icons-material/CampaignOutlined";
import { AnnouncementInfoService } from "./AnnouncementInfo.service";
import { Announcement } from "../../../../../viewModels/api/Models";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import { Grid } from "@mui/material";
import TextArea from "../../../../../tools/textArea/TextArea";
import TextField from "../../../../../tools/fields/textField/TextField";
import Style from "../AnnouncementPopup.module.scss";
import Styles from "./AnnouncementInfo.module.scss";
import LoaderScreen from "../../../../../tools/loaderScreen/LoaderScreen";
import PushPinIcon from "@mui/icons-material/PushPin";
import { LabelEnum } from "../../../../../viewModels/enums/LabelEnum";
import ImagePdfBoxSlider from "../../../../../tools/slider/imageBoxSlider/ImagePdfBoxSlider";
import SwiperComponent from "../../../../../tools/slider/swiperComponent/SwiperComponent";
import BrokenImageOutlinedIcon from '@mui/icons-material/BrokenImageOutlined';
interface Props {
  onCancel: () => void;
  buildingId: string | undefined;
  labels: IDropDown[];
  announcementId: string;
}

const AnnouncementInfo: React.FC<Props> = ({ onCancel, announcementId }) => {
  const [announcementInfo, setAnnouncementInfo] = useState<Announcement | null>(
    null
  );
  const [openBoxSlider, setOpenBoxSlider] = useState<Boolean>(false);
  const [currentSliderImage, setCurrentSliderImage] = useState<string>("");
  const { t: translate } = useTranslation();

  useEffect(() => {
    const fetchAnnouncementInfo = async () => {
      const result = await AnnouncementInfoService(Number(announcementId));

      if (result.data) {
        setAnnouncementInfo(result.data);
      }
    };

    if (announcementId) {
      fetchAnnouncementInfo();
    }
  }, [announcementId]);

  const openAttachmentFile = (value: string) => {
    setCurrentSliderImage(value);
    setOpenBoxSlider(true);
  };

  const closeAttachmentFile = () => {
    setOpenBoxSlider(false);
  };

  const getIconColor = (label: string) => {
    switch (label) {
      case LabelEnum.Notice:
        return "var(--icon-primary)";
      case LabelEnum.Warning:
        return "var(--icon-red)";
      case LabelEnum.Advertisement:
        return "var(--icon-green-high)";
      default:
        return "var(--icon-gray-low)";
    }
  };

  return (
    <AddNewPopups
      title={translate("ANNOUNCEMENTS.INFO")}
      createDateTime={announcementInfo?.createdDateTime}
      id={announcementInfo?.id}
      icon={<CampaignOutlinedIcon />}
      cancelIcon={<CloseOutlinedIcon onClick={onCancel} />}
    >
      <Grid className={`${Styles.info_container}`} container spacing={1.5}>
        {announcementInfo ? (
          <>
            <Grid item xs={12} md={6} className={Style.label_container}>
              <TextField
                value={announcementInfo.title || ""}
                label={translate("ANNOUNCEMENTS.SUBJECT")}
                maxLength={50}
                readOnly
              />
            </Grid>
            <Grid
              item
              xs={12}
              md={6}
              className={`${Style.label_container} ${Styles.label_container}`}
            >
              <PushPinIcon
                sx={{ color: getIconColor(announcementInfo.label || "") }}
              />
              <TextField
                value={announcementInfo.label || ""}
                placeHolder={translate("ANNOUNCEMENTS.SELECT_LABEL")}
                label={translate("ANNOUNCEMENTS.LABEL")}
                maxLength={50}
                readOnly
              />
            </Grid>
            <Grid
              item
              xs={12}
              className={`${Style.label_container} ${Style.textarea_container} ${Styles.textarea_container}`}
            >
              <TextArea
                label={translate("ANNOUNCEMENTS.DESCRIPTION")}
                value={announcementInfo.description || ""}
                readonly={true}
                placeholder={translate("ANNOUNCEMENTS.WRITE_DESCRIPTION")}
              />
            </Grid>

            {announcementInfo.announcementAttachment?.length! > 0 && (
              <Grid item xs={12} className={Style.slider_container}>
                <SwiperComponent
                  attachments={announcementInfo.announcementAttachment!}
                  openAttachmentFile={openAttachmentFile}
                />
              </Grid>
            )}
            {announcementInfo.announcementAttachment?.length! <= 0 && (
              <Grid item xs={12} className={Style.slider_container}>
                <div
                  className={`${Style.swiper_slide_custom} ${Styles.no_image}`}
                >
                  <BrokenImageOutlinedIcon />
                  <p>{translate("ANNOUNCEMENTS.IMAGE_PLACEHOLDER")}</p>
                </div>
              </Grid>
            )}
            {openBoxSlider && (
              <ImagePdfBoxSlider
                onClose={closeAttachmentFile}
                imagesList={announcementInfo.announcementAttachment}
                currentImage={currentSliderImage}
              />
            )}
          </>
        ) : (
          <LoaderScreen customStyle={Styles.fullHeight} />
        )}
      </Grid>
    </AddNewPopups>
  );
};

export default AnnouncementInfo;
