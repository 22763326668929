import React, { ReactElement } from "react";
import Style from "./AddButton.module.scss";
import { DefaultTFuncReturn } from "i18next";
import { SizeType } from "../../../viewModels/dataTypes/tools/ListComponentType";
import Button from "../../button/Button";
import { Add } from "iconsax-react";

interface Props {
  link?: string;
  text: DefaultTFuncReturn | ReactElement;
}

const AddButton: React.FC<Props> = ({ link, text }) => {
  return (
    <div className={Style.add_button}>
      <Button
        link={link}
        text={text}
        size={SizeType.small}
        icon={<Add className={Style.add_button_icon} />}
      />
    </div>
  );
};

export default AddButton;
