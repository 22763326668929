import React from "react";
import ProfileComponent from "./ProfileComponent"; // Update the import path as necessary

interface UserProfileComponentProps {
  contact: string;
  avatar: string;
}

const UserProfileComponent: React.FC<UserProfileComponentProps> = ({
  contact,
  avatar,
}) => {
  return <ProfileComponent value={contact} avatar={avatar} />;
};

export default UserProfileComponent;
