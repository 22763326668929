import React, { useEffect, useState } from "react";
import CustomizedTables from "../../../../../tools/table/Table";
import { DashboardBox } from "../../../../../tools/box/DashboardBox";
import { useTranslation } from "react-i18next";
import { Building } from "iconsax-react";
import SearchBox from "../../../../../tools/searchBox/SearchBox";
import { IUserBuilding } from "../../../../../viewModels/dataTypes/building/UserBuildingViewModel";
import DashboardTitle from "../../../../../tools/dashboardTitle/DashboardTitle";
import { headCells } from "./BuildingList.Service";
import AddButton from "../../../../../tools/table/addButton/AddButton";

interface Props {
  items: IUserBuilding[];
}
const BuildingList: React.FC<Props> = ({ items }) => {
  const [buildingList, setBuildingList] = useState<IUserBuilding[]>(items);

  useEffect(() => {
    setBuildingList(items);
  }, [items]);

  const { t: translate } = useTranslation();

  const onSearchBuilding = (searchTerm: string) => {
    const searchResults: IUserBuilding[] = [];

    for (const item of items) {
      if (item.buildingName.toLowerCase().includes(searchTerm.toLowerCase())) {
        searchResults.push(item);
      }
    }
    setBuildingList(searchResults);
  };

  return (
    <DashboardBox>
      <DashboardTitle
        icon={<Building size="22" color="var(--icon-gray-high)" />}
        label={translate("BUILDING.BUILDING_LIST")}
        button={<AddButton link={"/"} text={translate("DASHBOARD.BUILDING")} />}
      />
      <SearchBox placeholder="Search by name" onSearch={onSearchBuilding} />
      <CustomizedTables
        path="/dashboard/building/info/"
        tableData={buildingList}
        tableHeaderItems={headCells}
        notFoundMessage={translate("ALERT.NOT_FOUND_BUILDING")}
      />
    </DashboardBox>
  );
};

export default BuildingList;
