import React, { useEffect, useState } from "react";
import Style from "./ChangeState.module.scss";
import {
  IDropDown,
  IStateDropDown,
} from "../../../viewModels/dataTypes/tools/DropDownItems";
import { SizeType } from "../../../viewModels/dataTypes/tools/ListComponentType";
import {
  FormControl,
  MenuItem,
  Select,
  SelectChangeEvent,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import useDropdownPosition from "../../../hook/DropdownPosition";
import { StyledMenu } from "./CustomSelect";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";

interface Props {
  value: IStateDropDown | null;
  data: IStateDropDown[];
  onChange: (value: IStateDropDown | null) => void;
  label?: string;
  size?: SizeType;
}
const ChangeState: React.FC<Props> = ({
  data,
  onChange,
  label,
  size = SizeType.large,
  value,
}) => {
  //configuration
  const { t: translate } = useTranslation();
  const { menuPosition } = useDropdownPosition();

  //states
  const [selectedValue, setSelectedValue] = useState<IStateDropDown | null>(
    value
  );

  const handleChange = (event: SelectChangeEvent<string>) => {
    const {
      target: { value },
    } = event;

    // Find the selected item in the dropdown data
    const selectedItems: IStateDropDown | undefined = data.find(
      (item: IDropDown) => item.value === value
    );

    // Update the selected value state
    setSelectedValue(selectedItems ?? null);

    // Call the external onChange handler
    onChange(selectedItems ?? null);
  };

  useEffect(() => {
    setSelectedValue(value);
  }, [value]);

  return (
    <div className={Style.dropdown_box}>
      {label && (
        <label className={`${Style.label}`}>
          <span>{label}</span>
        </label>
      )}

      <StyledMenu
        className={`${Style.dropdown_items} ${
          size === SizeType.small ? Style.small_field : ""
        } `}
        borderColor={selectedValue?.color ?? ""}
        backgroundColor={selectedValue?.color ?? ""}
      >
        <FormControl className={Style.dropdown}>
          <Select
            IconComponent={ExpandMoreIcon}
            onChange={handleChange}
            value={selectedValue?.value ?? ""}
            MenuProps={{
              classes: { paper: Style.dropdown_menu },
              anchorOrigin: {
                vertical: menuPosition === "top" ? "top" : "bottom",
                horizontal: "left",
              },
              transformOrigin: {
                vertical: menuPosition === "top" ? "bottom" : "top",
                horizontal: "left",
              },
            }}
            renderValue={() => (
              <div className={Style.selected_value}>
                <FiberManualRecordIcon sx={{ color: selectedValue?.color }} />
                <span>{selectedValue?.text}</span>
              </div>
            )}
          >
            {data.map((item: IStateDropDown) => (
              <MenuItem className={Style.dropdown_item} value={item.value}>
                {translate(item.text)}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </StyledMenu>
    </div>
  );
};

export default ChangeState;
