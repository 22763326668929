import React, { ReactElement, useEffect, useState } from "react";
import Style from "./DurationRepeat.module.scss";
import {
  FormControl,
  MenuItem,
  Select,
  SelectChangeEvent,
} from "@mui/material";
import { IDropDown } from "../../viewModels/dataTypes/tools/DropDownItems";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useTranslation } from "react-i18next";
import useDropdownPosition from "../../hook/DropdownPosition";
import { IDurationRepeat } from "../../viewModels/dataTypes/tools/IDurationRepeat";

interface Props {
  icon?: ReactElement;
  label?: string;
  placeHolder?: string;
  value: IDurationRepeat;
  onChange: (param: IDurationRepeat) => void;
  requiredField?: boolean;
  dropDownDate?: IDropDown[];
}

const DurationRepeat: React.FC<Props> = ({
  icon,
  label,
  placeHolder,
  value,
  onChange,
  requiredField = false,
  dropDownDate = [],
}) => {
  const [changeLabelColor, setChangeLabelColor] = useState<boolean>(false);
  const [inputValue, setInputValue] = useState<number>(Number(value?.value));
  const [selectedValue, setSelectedValue] = useState<IDropDown | null>(
    dropDownDate[0]
  );

  const { t: translate } = useTranslation();
  const { selectRef, menuPosition, calculatePosition } = useDropdownPosition();

  const handleChange = (event: SelectChangeEvent<string>) => {
    const {
      target: { value },
    } = event;

    const selectedItems = dropDownDate.find((item: IDropDown) =>
      value.includes(item.value.toString())
    );

    setSelectedValue(selectedItems ?? null);
  };

  useEffect(() => {
    const selectedItem = dropDownDate.find(
      (item: IDropDown) => Number(item.value) == value?.durationType
    );
    setSelectedValue(selectedItem ?? dropDownDate[0]);
  }, []);

  useEffect(() => {
    const durationInfo: IDurationRepeat = {
      durationType: Number(selectedValue?.value),
      value: inputValue,
    };
    if (selectedValue && inputValue > 0) {
      setChangeLabelColor(true);
    } else {
      setChangeLabelColor(false);
    }
    onChange(durationInfo);
  }, [inputValue, selectedValue]);
  const changeInputValueHandler = (value: number) => {
    if (value >= 0) {
      setInputValue(value);
    }
  };
  return (
    <div className={Style.duration_repeat_field}>
      <label className={`${changeLabelColor ? Style.fill_field : ""}`}>
        {icon}
        <span className={Style.label}>
          {label}
          {requiredField && " *"}
        </span>
      </label>
      <div className={Style.field_flex}>
        <div className={`${Style.field}`}>
          <input
            autoCorrect="off"
            autoCapitalize="off"
            value={inputValue.toString()}
            placeholder={placeHolder}
            type="number"
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              changeInputValueHandler(Number(e.target.value))
            }
          />
        </div>
        <div ref={selectRef} className={`${Style.dropdown_items}  `}>
          <FormControl className={Style.dropdown}>
            <Select
              IconComponent={ExpandMoreIcon}
              onChange={handleChange}
              onOpen={calculatePosition}
              value={selectedValue?.value}
              MenuProps={{
                classes: { paper: Style.dropdown_menu },
                anchorOrigin: {
                  vertical: menuPosition === "top" ? "top" : "bottom",
                  horizontal: "left",
                },
                transformOrigin: {
                  vertical: menuPosition === "top" ? "bottom" : "top",
                  horizontal: "left",
                },
              }}
            >
              {dropDownDate.map((item: IDropDown) => (
                <MenuItem className={Style.dropdown_item} value={item.value}>
                  {translate(item.text)}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
      </div>
    </div>
  );
};

export default DurationRepeat;
