import { ActionReducerMapBuilder, PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { IBuildingInfoReducer } from "../../viewModels/reduxInterfaces/ReduxInterfaces";
import { IBuilding } from "../../viewModels/dataTypes/building/IBuilding";
import { GetBuildingInfoService } from "../../components/dashboard/dashboardContents/building/buildingInfo/BuildingGeneralInfo.Service";
import { IServiceResult } from "../../viewModels/api/Api";

const initialState = {
  buildingInfo: null,
  loading: true,
  fill: false,
  error: "",
} as IBuildingInfoReducer;

const BuildingInfoSlice = createSlice({
  name: "buildingInfo",
  initialState,
  reducers: {},
  extraReducers: (builder : ActionReducerMapBuilder<IBuildingInfoReducer>) => {
    builder.addCase(GetBuildingInfoDataAction.pending, (State) => {
      State.loading = true;
    });
    builder.addCase(
      GetBuildingInfoDataAction.fulfilled,
      (State, action: PayloadAction<IBuilding | null>) => {
        State.buildingInfo = action.payload;
        State.loading = false;
        State.fill = true;
      }
    );
    builder.addCase(GetBuildingInfoDataAction.rejected, (State) => {
      State.loading = false;
      State.err = "Loading Faild";
    });
  },
});
export const GetBuildingInfoDataAction = createAsyncThunk(
  "GetBuildingInfoDataAction",
  async (id: string) => {
    try {
      const buildingInfo: IServiceResult<IBuilding> =
        await GetBuildingInfoService(id);
      if (buildingInfo.data) {
        return buildingInfo.data;
      } else {
        return null;
      }
    } catch (err) {
      return null;
    }
  }
);

export default BuildingInfoSlice.reducer;
