import React from "react";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";

interface DownloadButtonProps {
  fileUrl: string;
  fileName: string;
}

const DownloadButton: React.FC<DownloadButtonProps> = ({
  fileUrl,
  fileName,
}) => {
  function handleAnchorClick(event: React.MouseEvent<HTMLAnchorElement>) {
    event.stopPropagation();
  }
  return (
    <a
      onClick={handleAnchorClick}
      href={fileUrl || ""}
      download={fileName}
      target="_blank"
      rel="noreferrer"
    >
      <FileDownloadOutlinedIcon />
    </a>
  );
};

export default DownloadButton;
