import {
  ActionReducerMapBuilder,
  createAsyncThunk,
  createSlice,
  PayloadAction,
} from "@reduxjs/toolkit";
import { IServiceResult } from "../../viewModels/api/Api";
import { IBuildingServiceRequestsReducer } from "../../viewModels/reduxInterfaces/ReduxInterfaces";
import { BookServiceViewModel } from "../../viewModels/dataTypes/building/BookServiceViewModel";
import { GetServiceRequestItems } from "../../components/dashboard/dashboardContents/services/requests/ServiceRequest.Service";

const initialState: IBuildingServiceRequestsReducer = {
  serviceRequests: [],
  loading: true,
  fill: false,
  err: "",
} as IBuildingServiceRequestsReducer;

const BuildingServiceRequestsSlice = createSlice({
  name: "buildingServiceRequests",

  initialState,
  reducers: {},
  extraReducers: (
    builder: ActionReducerMapBuilder<IBuildingServiceRequestsReducer>
  ) => {
    builder.addCase(
      GetBuildingServiceRequests.fulfilled,
      (state, action: PayloadAction<BookServiceViewModel[]>) => {
        if (action.payload.length > 0) {
          state.serviceRequests = action.payload;
          state.fill = true;
        }
        state.loading = false;
      }
    );
    builder.addCase(GetBuildingServiceRequests.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(GetBuildingServiceRequests.rejected, (state) => {
      state.loading = false;
    });
  },
});

export const GetBuildingServiceRequests = createAsyncThunk(
  "GetBuildingServiceRequests",
  async ({
    buildingId,
    categoryId,
  }: {
    buildingId: number;
    categoryId: number;
  }): Promise<BookServiceViewModel[]> => {
    const response: IServiceResult<BookServiceViewModel[]> =
      await GetServiceRequestItems(buildingId, categoryId);
    return response.data || [];
  }
);

export default BuildingServiceRequestsSlice.reducer;
