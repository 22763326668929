import React, { useEffect, useState } from "react";
import Loading from "../../../../../tools/loading/Loading";
import CustomizedTables from "../../../../../tools/table/Table";
import FilterDropDownDate from "../../../../../tools/dropDown/filterDropDownDate/FilterDropDownDate";
import FilterDropDownSelect from "../../../../../tools/dropDown/filterDropDownSelect/FilterDropDownSelect";
import FilterDropDown from "../../../../../tools/dropDown/filterDropDown/FilterDropDown";
import Filter from "../../../../../tools/table/filter/Filter";
import { IDropDown } from "../../../../../viewModels/dataTypes/tools/DropDownItems";
import useWindowSize from "../../../../../hook/WindowSize";
import { DateRangeType } from "../../../../../viewModels/dataTypes/DateRangeType";
import { TicketState } from "../../../../../viewModels/api/Models";
import { useTranslation } from "react-i18next";
import { DataTableItemType } from "../../../../../viewModels/dataTypes/DataTableType";
import { FilterConditionsEnum } from "../../../../../viewModels/enums/FilterConditionsEnum";
import { Dayjs } from "dayjs";
import {
  FormatDateDefault,
  MediumDeviceTrigger,
  UltraSmallDeviceTrigger,
} from "../../../../../Setting";
import Style from "./TicketsList.module.scss";
import { headCells } from "./TicketTableData";
import { DateItems } from "../../../../../tools/dropDown/filterDropDownDate/DateItems";
import { CalenderEnum } from "../../../../../viewModels/enums/CalenderEnum";
import { DashboardBox } from "../../../../../tools/box/DashboardBox";
import DashboardTitle from "../../../../../tools/dashboardTitle/DashboardTitle";
import { Headphone } from "iconsax-react";
import { TicketViewModel } from "../../../../../viewModels/dataTypes/building/TicketViewModel";
import { FilterBuildingTickets } from "../Tickets.Service";
import {
  appSupportItem,
  SuperAdminMenuItem,
} from "../../../menu/MenuItemsValue";
import { extractTitrItems } from "../../../../../services/commonServices/CommonServices";
import AddButton from "../../../../../tools/table/addButton/AddButton";

import MockTickets from "../../../../../mockData/MockTickets.json";
interface Props {
  ticketsList: TicketViewModel[];
}

const TicketsList: React.FC<Props> = ({ ticketsList }) => {
  // configurations
  const windowsSize = useWindowSize();
  const { t } = useTranslation();

  // states
  const [preload, setPreload] = useState<boolean>(true);
  const [isActiveCloseIcon, setIsActiveCloseIcon] = useState<boolean>(false);
  const [ticketCategories, setTicketCategories] = useState<string | null>(null);
  const [searchText, setSearchText] = useState<string>("");
  const [checkedItemsStates, setCheckedItemsStates] = useState<string[]>([]);
  const [states, setStates] = useState<string | null>(null);
  const [categoriesDropDown, setCategoresDropDown] = useState<IDropDown[]>([]);
  const [stateDropDown, setStateDropDown] = useState<IDropDown[]>([]);
  const [selectedMode, setSelectedMode] = useState<string>("");
  const [checkedItemsTicketCategories, setCheckedItemsTicketCategories] =
    useState<string[]>([]);
  const [ticketFilterList, setTickettFilterList] = useState<
    (TicketViewModel & DataTableItemType)[]
  >([]);
  const [stateFilterLogic, setStateFilterLogic] = useState<string>(
    FilterConditionsEnum.OR
  );
  const [ticketCategoriesFilterLogic, setTicketCategoriesFilterLogic] =
    useState<string>(FilterConditionsEnum.OR);
  const [, setRequestTimeFilterLogic] = useState<string>(
    FilterConditionsEnum.OR
  );
  const [selectedDateRange, setSelectedDateRange] =
    useState<DateRangeType | null>(null);

  const isMediumToUltraSmallDevice: boolean =
    windowsSize.width <= MediumDeviceTrigger &&
    windowsSize.width > UltraSmallDeviceTrigger;

  // functions
  const getTicketList = (): string[] => {
    return Object.keys(TicketState)
      .filter((key) => isNaN(Number(key)))
      .map((key: string) => key);
  };

  const resetFilters = () => {
    setSearchText("");
    setTicketCategories(null);
    setStates(null);
    setCheckedItemsTicketCategories([]);
    setCheckedItemsStates([]);
    setSelectedMode("");
    if (checkedItemsTicketCategories.length > 0 || searchText.length > 0) {
      setTicketCategoriesFilterLogic(FilterConditionsEnum.OR);
      setRequestTimeFilterLogic(FilterConditionsEnum.OR);
      setStateFilterLogic(FilterConditionsEnum.OR);
    }
  };

  const handleDateChange = (startDate: Dayjs, endDate: Dayjs) => {
    setSelectedDateRange({
      start: startDate.format(FormatDateDefault),
      end: endDate.format(FormatDateDefault),
    });
  };

  // useEffect
  useEffect(() => {
    const translatedItems = [
      ...extractTitrItems(SuperAdminMenuItem()).map((item) => ({
        ...item,
        text: t(item.text),
      })),
      ...extractTitrItems(appSupportItem).map((item) => ({
        ...item,
        text: t(item.text),
      })),
      {
        value: "Plans",
        text: t("Plans"),
      },
      {
        value: "Others",
        text: t("Others"),
      },
    ];

    setCategoresDropDown(translatedItems);
  }, []);

  useEffect(() => {
    setTickettFilterList(MockTickets);
  }, []);

  useEffect(() => {
    if (MockTickets) {
      setTickettFilterList(MockTickets);
    } else {
      setTickettFilterList([]); // Or provide some other default if needed
    }

    const stateDropDownList: IDropDown[] = getTicketList().map(
      (state: string, index: number) => ({
        value: index.toString() ?? "",
        text: state ?? "",
      })
    );
    setStateDropDown(stateDropDownList);
    setPreload(false);
  }, [ticketsList]);

  useEffect(() => {
    setIsActiveCloseIcon(
      checkedItemsTicketCategories.length > 0 ||
        checkedItemsStates.length > 0 ||
        searchText.length > 0 ||
        selectedMode !== ""
    );
  }, [
    checkedItemsTicketCategories.length,
    checkedItemsStates.length,
    searchText.length,
    selectedMode,
  ]);
  useEffect(() => {
    const dataFilter = FilterBuildingTickets(
      MockTickets || [], // Use an empty array if testTicket is undefined
      searchText,
      ticketCategories ?? "",
      ticketCategoriesFilterLogic,
      states ?? "",
      selectedDateRange,
      selectedMode
    );
    setTickettFilterList(dataFilter);
  }, [
    ticketsList,
    searchText,
    ticketCategories,
    ticketCategoriesFilterLogic,
    states,
    selectedDateRange,
    selectedMode,
  ]);

  return (
    <DashboardBox>
      <DashboardTitle
        icon={<Headphone className={Style.user_icon} />}
        label={`${t("APP_SUPPORT.FLOORINS_APP_SUPPORT")}`}
        button={
          <AddButton
            text={t("APP_SUPPORT.NEW_TICKET")}
            link="/dashboard/tickets/addTicket"
          />
        }
      />
      <Filter
        onSearch={setSearchText}
        onClose={resetFilters}
        valueSearch={searchText}
        isActiveCloseIcon={isActiveCloseIcon}
        filterItemsDate={
          <>
            {windowsSize.width >= UltraSmallDeviceTrigger && (
              <div className={Style.container_date_desktop_filter}>
                {isMediumToUltraSmallDevice ? (
                  selectedMode && (
                    <FilterDropDownSelect
                      items={DateItems}
                      label={t("APP_SUPPORT.CREATION_TIME")}
                      onModeChange={setSelectedMode}
                      value={selectedMode}
                    />
                  )
                ) : (
                  <FilterDropDownSelect
                    items={DateItems}
                    label={t("APP_SUPPORT.CREATION_TIME")}
                    onModeChange={setSelectedMode}
                    value={selectedMode}
                  />
                )}
                {selectedMode && (
                  <FilterDropDownDate
                    className={Style.tickets_list_dropdown}
                    mode={selectedMode}
                    onDateChange={(start: Dayjs, end: Dayjs) =>
                      handleDateChange(start, end)
                    }
                  />
                )}
              </div>
            )}
          </>
        }
        filterItems={
          <>
            {windowsSize.width < MediumDeviceTrigger && (
              <>
                <p
                  className={`${Style.tickets_mobile_report} ${
                    selectedMode !== "" ? Style.active : ""
                  }`}
                  onClick={() =>
                    setSelectedMode(selectedMode !== "" ? "" : CalenderEnum.DAY)
                  }
                >
                  {t("APP_SUPPORT.CREATION_TIME")}
                </p>
              </>
            )}
            <FilterDropDown
              className={Style.tickets_list_dropdown}
              onChange={setTicketCategories}
              onChangeConditionsLogic={setTicketCategoriesFilterLogic}
              valueConditionsLogic={ticketCategoriesFilterLogic}
              items={categoriesDropDown}
              label={t("SHARED.CATEGORY")}
              checkedItems={checkedItemsTicketCategories}
              setCheckedItems={setCheckedItemsTicketCategories}
              showRadioButton={true}
            />

            <FilterDropDown
              className={Style.tickets_list_dropdown}
              onChange={setStates}
              onChangeConditionsLogic={setStateFilterLogic}
              valueConditionsLogic={stateFilterLogic}
              items={stateDropDown}
              label={t("REQUESTS.STATE")}
              checkedItems={checkedItemsStates}
              setCheckedItems={setCheckedItemsStates}
              showRadioButton={false}
            />
          </>
        }
      />
      {selectedMode && windowsSize.width < UltraSmallDeviceTrigger && (
        <div className={`${Style.container_filter_Date}`}>
          <FilterDropDownSelect
            items={DateItems}
            label={t("APP_SUPPORT.CREATION_TIME")}
            onModeChange={setSelectedMode}
            value={selectedMode}
          />
          {selectedMode && (
            <FilterDropDownDate
              className={Style.tickets_list_dropdown}
              mode={selectedMode}
              onDateChange={(start: Dayjs, end: Dayjs) =>
                handleDateChange(start, end)
              }
            />
          )}
        </div>
      )}
      {preload ? (
        <Loading />
      ) : (
        <CustomizedTables
          className={Style.tickets_list}
          path={`/dashboard/ticket/`}
          tableData={ticketFilterList}
          tableHeaderItems={headCells}
          notFoundMessage={t("ALERT.NOT_FOUND_TICKETS")}
        />
      )}
    </DashboardBox>
  );
};

export default TicketsList;
