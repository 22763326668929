import { Trash } from "iconsax-react";
import React, { useEffect, useState } from "react";
import Style from "./DeleteUserFromList.module.scss";
import MessagePopUp from "../../../../../../tools/popup/MessagePopUp";
import { useTranslation } from "react-i18next";
import Button from "../../../../../../tools/button/Button";
import {
  ButtonState,
  ButtonTheme,
} from "../../../../../../viewModels/enums/ButtonEnum";
import { SizeType } from "../../../../../../viewModels/dataTypes/tools/ListComponentType";
import { IServiceResult } from "../../../../../../viewModels/api/Api";
import { DeleteUserFromListService } from "./DeleteUserFromList.Service";
import { DeleteBuildingUserViewModel } from "../../../../../../viewModels/api/Models";
import { useParams } from "react-router-dom";
import { CreateToast } from "../../../../../../tools/toast/ToastService";
import { ToastType } from "../../../../../../viewModels/enums/ToastType";
import Loading from "../../../../../../tools/loading/Loading";
import { useDispatch } from "react-redux";
import { AppThunkDispatch } from "../../../../../../redux/Store";
import { GetBuildingUsersAction } from "../../../../../../redux/slices/BuildingUsersSlice";
import useBodyScrollLock from "../../../../../../hook/BodyScrollLock";

interface Props {
  id: string;
  contact: string;
}

const DeleteUserFromList: React.FC<Props> = ({ id, contact }) => {
  const [buttonState, setButtonState] = useState<ButtonState>(
    ButtonState.disable
  );
  const [openDeletePopUp, setOpenDeletePopUp] = useState(false);

  const [loading, setLoading] = useState<boolean>(false);

  const { buildingId } = useParams();

  const dispatch = useDispatch<AppThunkDispatch>();
  const popUpHandler = () => {
    setOpenDeletePopUp(!openDeletePopUp);
  };

  const deleteUserAction = async () => {
    setLoading(true);
    var userInfo: DeleteBuildingUserViewModel = {
      userId: id,
      buildingId: Number(buildingId),
    };

    const deleteUserResult: IServiceResult<boolean> =
      await DeleteUserFromListService(userInfo);
    if (deleteUserResult.data) {
      dispatch(GetBuildingUsersAction(buildingId ?? ""));
      CreateToast(
        ToastType.Success,
        translate(deleteUserResult.message, { value: translate("USERS.USER") })
      );
      setLoading(false);
      setOpenDeletePopUp(false);
    } else {
      CreateToast(
        ToastType.Error,
        translate(deleteUserResult.message, {
          value: translate("USERS.USER"),
        })
      );
      setLoading(false);
    }
  };
  useEffect(() => {
    const changeStatePolice: boolean = loading;
    setButtonState(
      changeStatePolice ? ButtonState.disable : ButtonState.default
    );
  }, [loading]);
  const { t: translate } = useTranslation();

  // Use custom hook to lock body scroll
  useBodyScrollLock(openDeletePopUp);
  return (
    <>
      <div className={Style.actions} onClick={popUpHandler}>
        <Trash className={Style.list_delete_icon} />
      </div>
      {openDeletePopUp && (
        <MessagePopUp
          icon={<Trash className={Style.delete_icon} />}
          title={translate("RESIDENT.DELETE_RESIDENT_TITR")}
          description={
            <p>
              {translate("ALERT.CONFIRM_DELETE_USER_ALERT")}
              <span className={Style.contact}>{contact}</span>?
            </p>
          }
          className={Style.delete_user_popup}
          buttons={
            <>
              <Button
                className={Style.delete_button}
                text={translate("BUTTON.CANCEL")}
                theme={ButtonTheme.deleteReverse}
                size={SizeType.small}
                clickMethod={() => setOpenDeletePopUp(false)}
              />
              <Button
                className={Style.delete_button}
                text={translate("BUTTON.DELETE")}
                theme={ButtonTheme.delete}
                size={SizeType.small}
                clickMethod={deleteUserAction}
                state={buttonState}
              />
            </>
          }
        />
      )}
      {loading && <Loading />}
    </>
  );
};

export default DeleteUserFromList;
