import { BodyOfGetRequest } from "../../Setting";
import {
  AddServiceRequest,
  BaseUrl,
  DeleteServiceRequest,
  GetServiceCategoriesRequest,
  GetServiceRequest,
  ServiceCategoryRequest,
} from "../../text/Api";
import { ApiResponse } from "../../viewModels/api/Api";
import { BuildingServices, ServiceCategory } from "../../viewModels/api/Models";
import { ApiMethod } from "../../viewModels/enums/ApiMethod";
import { CallApi } from "../CallApi";
import { DefaultApiHeader } from "./ApiHeaders";

export const GetServiceCategories = async (buildingId: number) => {
  const Url: string = `${BaseUrl + GetServiceCategoriesRequest + buildingId}`;
  let Result: ApiResponse<ServiceCategory[]> = await CallApi(
    Url,
    BodyOfGetRequest,
    DefaultApiHeader,
    ApiMethod.Get
  );

  return Result;
};

export const AddServiceCategory = async (categoryInfo: ServiceCategory) => {
  const Url: string = `${BaseUrl + ServiceCategoryRequest}`;
  const raw = JSON.stringify(categoryInfo);
  let Result: ApiResponse<ServiceCategory> = await CallApi(
    Url,
    raw,
    DefaultApiHeader,
    ApiMethod.Post
  );

  return Result;
};

export const UpdateServiceCategory = async (categoryInfo: ServiceCategory) => {
  const Url: string = `${BaseUrl + ServiceCategoryRequest}`;
  const raw = JSON.stringify(categoryInfo);
  let Result: ApiResponse<boolean> = await CallApi(
    Url,
    raw,
    DefaultApiHeader,
    ApiMethod.Put
  );

  return Result;
};

export const DeleteServiceCategory = async (categoryId: number) => {
  const Url: string = `${BaseUrl + ServiceCategoryRequest}/${categoryId}`;

  let Result: ApiResponse<boolean> = await CallApi(
    Url,
    BodyOfGetRequest,
    DefaultApiHeader,
    ApiMethod.Delete
  );

  return Result;
};

export const GetServices = async (buildingId: number, categoryId: number) => {
  const Url: string = `${BaseUrl}${GetServiceRequest}${buildingId}/${categoryId}`;

  let Result: ApiResponse<ServiceCategory[]> = await CallApi(
    Url,
    BodyOfGetRequest,
    DefaultApiHeader,
    ApiMethod.Get
  );

  return Result;
};

export const DeleteServices = async (serviceId: number) => {
  const Url: string = `${BaseUrl}${DeleteServiceRequest}${serviceId}`;

  let Result: ApiResponse<boolean> = await CallApi(
    Url,
    BodyOfGetRequest,
    DefaultApiHeader,
    ApiMethod.Delete
  );

  return Result;
};

export const AddService = async (serviceInfo: BuildingServices) => {
  const Url: string = `${BaseUrl}${AddServiceRequest}`;

  let Result: ApiResponse<BuildingServices> = await CallApi(
    Url,
    JSON.stringify(serviceInfo),
    DefaultApiHeader,
    ApiMethod.Post
  );

  return Result;
};
