import { ResponseCode } from "../viewModels/enums/ResponseCode";
import { ApiResponse } from "../viewModels/api/Api";

export async function CallApi<T>(
  BaseUrl: string,
  Body: BodyInit,
  Header: any,
  Method: string
) {
  var requestOptions: RequestInit;

  if (Body === "{}") {
    requestOptions = {
      method: Method,
      headers: Header,
      credentials: "include",
      redirect: "follow",
    };
  } else {
    requestOptions = {
      method: Method,
      headers: Header,
      body: Body,
      credentials: "include",
      redirect: "follow",
    };
  }

  try {
    const response = await fetch(BaseUrl, requestOptions);
    const data: T = await response.json();
    const status: ResponseCode = response.status;
    var requestResult: ApiResponse<T>;
    requestResult = {
      data: data,
      responseStatus: status,
    };
    return requestResult;
  } catch (error) {
    return {
      data: null,
      responseStatus: ResponseCode.BadRequest,
    };
  }
}
