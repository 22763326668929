import React from "react";
import { BuildingCommunicationViewModel } from "../../../viewModels/api/Models";
import Style from "./CommentCard.module.scss";
import { GetDayWithMonth } from "../../../services/commonServices/dateCommonServices/DateCommonService";
import { useTranslation } from "react-i18next";
import LargeAvatar from "../../defaultAvatar/LargeAvatar";

interface Props {
  commentInfo: BuildingCommunicationViewModel;
}
const CommentCard: React.FC<Props> = ({ commentInfo }) => {
  const { t: translate } = useTranslation();

  return (
    <div
      className={`${Style.comment_box} ${
        commentInfo.isAdmin ? Style.is_admin : ""
      }`}
    >
      <div className={Style.avatar}>
        {commentInfo.sederAvatar == null ||
        commentInfo.sederAvatar.length == 0 ? (
          <LargeAvatar />
        ) : (
          <div className={Style.logo}>
            <img
              src={commentInfo.sederAvatar}
              alt={`${commentInfo.senderName} avatar`}
            />
          </div>
        )}
      </div>
      <div className={Style.comment_content}>
        <div className={Style.comment_content_title}>
          <h3>{commentInfo.senderName}</h3>
          <p>{`${translate("SHARED.COMMENTED")} ${GetDayWithMonth(
            commentInfo.sendDateTime?.toString() ?? ""
          )}`}</p>
        </div>
        <div className={Style.comment_content_message}>
          <p>{commentInfo.senderMessage}</p>
        </div>
      </div>
    </div>
  );
};

export default CommentCard;
