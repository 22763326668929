import React, { useEffect, useState } from "react";
import TextField from "../../../tools/fields/textField/TextField";
import Button from "../../../tools/button/Button";
import { IServiceResult } from "../../../viewModels/api/Api";
import PasswordField from "../../../tools/fields/passwordField/PasswordField";
import { useNavigate } from "react-router-dom";
import { UserContactValidationModel } from "../../../viewModels/dataTypes/Cookie";
import CheckRules from "../../../tools/checkBox/checkRules/CheckRules";
import Loading from "../../../tools/loading/Loading";
import { MessageType } from "../../../viewModels/enums/MessageType";
import { FieldState } from "../../../viewModels/enums/PublicEnum";
import { Localstorage_UserValidation } from "../../../text/LocalStorageValues";
import { ButtonState } from "../../../viewModels/enums/ButtonEnum";
import { useTranslation } from "react-i18next";
import { RegisterUser } from "./services/InsertInfo";
import BeforeDashboardForm from "../BeforeDashboardForm";
import ConfirmUserEmail from "./confirmUserContact/ConfirmUserEmail";
import ConfirmUserPhoneNumber from "./confirmUserContact/ConfirmUserPhoneNumber";
import { MessageHandler } from "../../../tools/messagesHandler/MessagesHandler";
import {
  CheckConfirmPasswordService,
  CheckIsCompanyService,
  CheckPasswordService,
  CheckRuleService,
} from "./services/CheckInfo";
import { CountryType } from "../../../viewModels/dataTypes/country/Country";
import {
  ReceiverType,
  UserInfoRegistrationViewModel,
} from "../../../viewModels/api/Models";
import { UserTokenModel } from "../../../viewModels/dataTypes/registration/TokenViewModel";

const UserRegistration: React.FC = () => {
  const navigate = useNavigate();

  const [formValidate, setFormValidate] = useState(false);
  //first name states
  const [firstName, setFirstName] = useState<string>("");
  const [firstNameErrorMessage, setFirstNameErrorMessage] =
    useState<string>("");
  const [firstNameFieldState, setFirstNameFieldState] = useState<FieldState>(
    FieldState.Default
  );
  //company name states
  const [companyName, setCompanyName] = useState<string>("");
  const [lastName, setLastName] = useState<string>("");
  const [lastNameErrorMessage, setLastNameErrorMessage] = useState<string>("");
  const [lastNameFieldState, setLastNameFieldState] = useState<FieldState>(
    FieldState.Default
  );
  const [email, setEmail] = useState<string>("");
  const [phoneNumber, setPhoneNumber] = useState<string>("");
  const [theUserValidation, setTheUserValidation] =
    useState<UserContactValidationModel>();
  //password states
  const [password, setPassword] = useState<string>("");
  const [passwordFieldState, setPasswordFieldState] = useState<FieldState>(
    FieldState.Default
  );
  const [passwordErrorMessage, setPasswordErrorMessage] = useState("");
  //confirm password states
  const [confirmPassword, setConfirmPassword] = useState<string>("");
  const [confirmPasswordFieldState, setConfirmPasswordFieldState] =
    useState<FieldState>(FieldState.Default);
  const [confirmPasswordErrorMessage, setConfirmPasswordErrorMessage] =
    useState("");
  //rule check
  const [ruleCheck, setRuleCheck] = useState<boolean>(false);
  const [ruleState, setRuleState] = useState<FieldState>(FieldState.Default);
  // public
  const [loading, setLoading] = useState<boolean>(false);
  const [country, setCountry] = useState<CountryType | null>(null);
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    if (!localStorage.getItem(Localstorage_UserValidation)) {
      navigate("/login");
    } else {
      let ueserDataJson = localStorage.getItem(Localstorage_UserValidation);
      if (ueserDataJson != null) {
        let userValidationData: UserContactValidationModel =
          JSON.parse(ueserDataJson);
        if (userValidationData.otpDeliveryMethod === ReceiverType.Email) {
          setEmail(userValidationData.userContact);
        } else {
          setPhoneNumber(userValidationData.userContact);
          setCountry(userValidationData.userCountry);
        }
        setTheUserValidation(userValidationData);
      } else {
        navigate("/login");
      }
    }
  }, [navigate]);

  const submitInfo = async () => {
    setLoading(true);
    if (theUserValidation !== undefined) {
      setLoading(true);
      let userData: UserInfoRegistrationViewModel = {
        password: password,
        email: email,
        firstName: firstName.length !== 0 ? firstName : null,
        lastName: lastName.length !== 0 ? lastName : null,
        companyName: companyName.length > 0 ? companyName : null,
        country: country?.id ?? 0,
        phoneNumber: country?.code + phoneNumber,
      };
      let checkIsCompany = CheckIsCompanyService(
        userData,
        setFirstNameErrorMessage,
        setFirstNameFieldState,
        setLastNameErrorMessage,
        setLastNameFieldState
      );

      let checkPassword = CheckPasswordService(
        password,
        setPasswordErrorMessage,
        setPasswordFieldState
      );
      let checkConfirmPassword = CheckConfirmPasswordService(
        password,
        confirmPassword,
        setConfirmPasswordErrorMessage,
        setConfirmPasswordFieldState
      );

      let checkRule = CheckRuleService(ruleCheck, setRuleState);
      if (
        checkPassword &&
        checkConfirmPassword &&
        checkRule &&
        checkIsCompany
      ) {
        var addPersonalInfoResult: IServiceResult<UserTokenModel> =
          await RegisterUser(userData);
        if (addPersonalInfoResult.data != null) {
          navigate("/pricing");
        } else {
          setErrorMessage(addPersonalInfoResult.message);
          setLoading(false);
        }
      } else {
        setLoading(false);
      }
    } else {
      setErrorMessage("ERRORS.OPERATION_FAILD");
    }
  };

  const changeFirstName = (value: string) => {
    setFirstName(value);
    setLastNameErrorMessage("");
    setLastNameFieldState(FieldState.Default);
    setFirstNameErrorMessage("");
    setFirstNameFieldState(FieldState.Default);
  };

  const changeLastName = (value: string) => {
    setLastName(value);
    setLastNameErrorMessage("");
    setLastNameFieldState(FieldState.Default);
    setFirstNameErrorMessage("");
    setFirstNameFieldState(FieldState.Default);
  };

  const onChangePhoneNumber = (value: string, country?: CountryType | null) => {
    setPhoneNumber(value);
    setCountry(country ?? null);
  };

  const onChangePassword = (value: string) => {
    setPassword(value);
    setPasswordErrorMessage("");
    setPasswordFieldState(FieldState.Default);
    if (value === confirmPassword) {
      setConfirmPasswordErrorMessage("");
      setConfirmPasswordFieldState(FieldState.Default);
    }
  };

  const onChangeConfirmPassword = (value: string) => {
    setConfirmPassword(value);
    setConfirmPasswordErrorMessage("");
    setConfirmPasswordFieldState(FieldState.Default);
  };

  const { t: translate } = useTranslation();

  return (
    <BeforeDashboardForm
      title={translate("AUTHENTICATION.SIGNUP")}
      description={translate("AUTHENTICATION.COMPLETE_INFO")}
      body={
        <div>
          <MessageHandler
            message={translate(errorMessage)}
            type={MessageType.Error}
          />
          <TextField
            value={firstName}
            placeHolder={`${translate("PLACEHOLDER.FIRSTNAME")} 
                `}
            errorMessage={translate(firstNameErrorMessage)}
            label={translate("LABELS.FIRSTNAME")}
            onChange={changeFirstName}
            fieldState={firstNameFieldState}
          />
          <TextField
            value={lastName}
            placeHolder={`${translate("PLACEHOLDER.LASTNAME")} 
                    `}
            errorMessage={translate(lastNameErrorMessage)}
            label={translate("LABELS.LASTNAME")}
            onChange={changeLastName}
            fieldState={lastNameFieldState}
          />
          <TextField
            value={companyName}
            placeHolder={`${translate("PLACEHOLDER.COMPANY_NAME")} 
                    `}
            label={translate("LABELS.COMPANY_NAME")}
            onChange={setCompanyName}
          />
          {theUserValidation?.otpDeliveryMethod === ReceiverType.Email ? (
            <ConfirmUserPhoneNumber
              country={country}
              phoneNumber={phoneNumber}
              setPhoneNumber={onChangePhoneNumber}
              setComplete={setFormValidate}
            />
          ) : (
            <ConfirmUserEmail
              setComplete={setFormValidate}
              email={email}
              setEmail={setEmail}
            />
          )}
          <PasswordField
            errorMessage={passwordErrorMessage}
            label={translate("AUTHENTICATION.PASSWORD")}
            onChange={onChangePassword}
            checkPass={true}
            fieldState={passwordFieldState}
            placeholder={translate("PLACEHOLDER.PASSWORD")}
          />
          <PasswordField
            errorMessage={confirmPasswordErrorMessage}
            label={translate("AUTHENTICATION.CONFIRM_PASSWORD")}
            onChange={onChangeConfirmPassword}
            placeholder={translate("PLACEHOLDER.CONFIRM_PASSWORD")}
            checkPass={true}
            fieldState={confirmPasswordFieldState}
          />
          <CheckRules
            ruleState={ruleState}
            isChecked={ruleCheck}
            onClick={() => setRuleCheck(!ruleCheck)}
          />
          <div className={`mt-1 mb-3`}>
            {formValidate === true ? (
              <Button
                text={translate("BUTTON.NEXT")}
                clickMethod={submitInfo}
              />
            ) : (
              <Button
                text={translate("BUTTON.NEXT")}
                state={ButtonState.disable}
              />
            )}
          </div>
          {loading === true && <Loading />}
        </div>
      }
    />
  );
};

export default UserRegistration;
