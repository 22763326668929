import React, { useEffect, useState } from "react";
import BeforeDashboardForm from "../../BeforeDashboardForm";
import { useTranslation } from "react-i18next";
import { MessageHandler } from "../../../../tools/messagesHandler/MessagesHandler";
import Button from "../../../../tools/button/Button";
import Loading from "../../../../tools/loading/Loading";
import PasswordField from "../../../../tools/fields/passwordField/PasswordField";
import ConfirmUserEmail from "../../registration/confirmUserContact/ConfirmUserEmail";
import ConfirmUserPhoneNumber from "../../registration/confirmUserContact/ConfirmUserPhoneNumber";
import { MessageType } from "../../../../viewModels/enums/MessageType";
import { CheckLengthtRegex, PasswordRegex } from "../../../../services/Regex";
import {
  ContactVerificationState,
  FieldState,
} from "../../../../viewModels/enums/PublicEnum";
import {
  ConfirmUserInfoService,
  GetUserInfoService,
} from "./ConfirmationUserInfo.Service";
import { Localstorage_UserValidation } from "../../../../text/LocalStorageValues";
import { UserContactValidationModel } from "../../../../viewModels/dataTypes/Cookie";
import { IServiceResult } from "../../../../viewModels/api/Api";
import { useNavigate } from "react-router-dom";
import TextField from "../../../../tools/fields/textField/TextField";
import { IUser } from "../../../../viewModels/dataTypes/user/User";
import { ButtonState } from "../../../../viewModels/enums/ButtonEnum";
import {
  CheckConfirmPasswordService,
  CheckIsCompanyService,
  CheckPasswordService,
} from "../../registration/services/CheckInfo";

import { UserTokenModel } from "../../../../viewModels/dataTypes/registration/TokenViewModel";
import { useDispatch } from "react-redux";
import { AppThunkDispatch } from "../../../../redux/Store";
import { GetUserBuildingDataAction } from "../../../../redux/slices/UserBuildingSlice";
import { CountryType } from "../../../../viewModels/dataTypes/country/Country";
import { UserInfoRegistrationViewModel } from "../../../../viewModels/api/Models";

const ConfirmationUserInfo = () => {
  const [loading, setLoading] = useState<Boolean>(false);
  const [preload, setPreload] = useState<Boolean>(true);
  const [message, setMessage] = useState("");
  const [userInfo, setUserInfo] = useState<IUser | null>(null);
  //phone number
  const [phoneNumber, setPhoneNumber] = useState<string>("");
  const [phoneNumberValidationState, setPhoneNumberValidationState] =
    useState<ContactVerificationState>(ContactVerificationState.Empty);
  //country
  const [country, setCountry] = useState<CountryType | null>(null);

  //email
  const [email, setEmail] = useState<string>("");
  const [emailValidationState, setEmailValidationState] =
    useState<ContactVerificationState>(ContactVerificationState.Empty);
  //first name
  const [firstName, setFirstName] = useState<string>("");
  const [firstNameErrorMessage, setFirstNameErrorMessage] =
    useState<string>("");
  const [firstNameFieldState, setFirstNameFieldState] = useState<FieldState>(
    FieldState.Default
  );
  //last name
  const [lastName, setLastName] = useState<string>("");
  const [lastNameErrorMessage, setLastNameErrorMessage] = useState<string>("");
  const [lastNameFieldState, setLastNameFieldState] = useState<FieldState>(
    FieldState.Default
  );
  //password
  const [password, setPassword] = useState<string>("");
  const [passwordErrorMessage, setPasswordErrorMessage] = useState<string>("");
  const [passwordFieldState, setPasswordFieldState] = useState<FieldState>(
    FieldState.Default
  );
  //confirm password
  const [confirmPassword, setConfirmPassword] = useState<string>("");
  const [phoneNumberValidate, setPhoneNumberValidate] = useState(false);
  const [emailValidate, setEmailValidate] = useState(false);
  const [confirmPasswordFieldState, setConfirmPasswordFieldState] =
    useState<FieldState>(FieldState.Default);
  const [confirmPasswordErrorMessage, setConfirmPasswordErrorMessage] =
    useState<string>("");
  const navigate = useNavigate();
  const dispatch = useDispatch<AppThunkDispatch>();

  useEffect(() => {
    const getUserInfo = async () => {
      if (!localStorage.getItem(Localstorage_UserValidation)) {
        navigate("/login");
      } else {
        var ueserDataJson = localStorage.getItem(Localstorage_UserValidation);
        if (ueserDataJson != null) {
          var userValidationData: UserContactValidationModel =
            JSON.parse(ueserDataJson);

          var info: IServiceResult<IUser> = await GetUserInfoService(
            userValidationData.userCountry != null
              ? userValidationData.userCountry.code +
                  userValidationData.userContact
              : userValidationData.userContact
          );
          if (info.data != null) {
            setPreload(false);
            setUserInfo(info.data);
            setFirstName(info.data.firstName);
            setLastName(info.data.lastName);
            setEmail(info.data.email);
            var userCountry: CountryType = {
              code: info.data.countryCode,
              name: info.data.countryName,
              symbol: info.data.countryAbbreviation,
              id: info.data.countryId,
            };
            setCountry(userCountry);
            setPhoneNumber(
              info.data.phoneNumber.replace(info.data.countryCode, "")
            );
            if (info.data.phoneNumberConfirmed) {
              setPhoneNumberValidationState(ContactVerificationState.Fill);
              setPhoneNumberValidate(true);
            }
            if (info.data.emailConfirmed) {
              setEmailValidationState(ContactVerificationState.Fill);
              setEmailValidate(true);
            }
          } else {
            navigate("/");
          }
        } else {
          navigate("/");
        }
      }
    };
    getUserInfo();
  }, []);

  const { t: translate } = useTranslation();
  const changeFirstName = (value: string) => {
    setFirstName(value);
    setLastNameErrorMessage("");
    setLastNameFieldState(FieldState.Default);
    setFirstNameErrorMessage("");
    setFirstNameFieldState(FieldState.Default);
  };

  const changeLastName = (value: string) => {
    setLastName(value);
    setLastNameErrorMessage("");
    setLastNameFieldState(FieldState.Default);
    setFirstNameErrorMessage("");
    setFirstNameFieldState(FieldState.Default);
  };
  const onChangePassword = (value: string) => {
    setPassword(value);
    setPasswordErrorMessage("");
    setPasswordFieldState(FieldState.Default);
    if (value == confirmPassword) {
      setConfirmPasswordErrorMessage("");
      setConfirmPasswordFieldState(FieldState.Default);
    }
  };
  const onChangeConfirmPassword = (value: string) => {
    setConfirmPassword(value);
    setConfirmPasswordErrorMessage("");
    setConfirmPasswordFieldState(FieldState.Default);
  };

  const onChangePhoneNumber = (
    value: string,
    countryValue?: CountryType | null
  ) => {
    setPhoneNumber(value);
    if (countryValue != null) {
      setCountry(countryValue);
    }
    if (countryValue?.code + value != userInfo?.phoneNumber) {
      setPhoneNumberValidationState(ContactVerificationState.Empty);
      setPhoneNumberValidate(false);
    } else {
      setPhoneNumberValidationState(ContactVerificationState.Fill);
      setPhoneNumberValidate(true);
    }
  };

  const onChangeEmail = (value: string) => {
    if (value != userInfo?.email) {
      setEmailValidationState(ContactVerificationState.Empty);
      setEmailValidate(false);
    } else {
      setEmailValidationState(ContactVerificationState.Fill);
      setEmailValidate(true);
    }
    setEmail(value);
  };

  const completeInfo = async () => {
    var userData: UserInfoRegistrationViewModel = {
      id: userInfo?.id,
      password: password,
      email: email,
      firstName: firstName?.length != 0 ? firstName : null,
      lastName: lastName?.length != 0 ? lastName : null,
      country: country?.id ?? 0,
      companyName: country?.name ?? "",
      phoneNumber: country?.code + phoneNumber,
    };
    let checkPassword = CheckPasswordService(
      password,
      setPasswordErrorMessage,
      setPasswordFieldState
    );
    let checkConfirmPassword = CheckConfirmPasswordService(
      password,
      confirmPassword,
      setConfirmPasswordErrorMessage,
      setConfirmPasswordFieldState
    );

    var checkUserNameAndFamily = CheckIsCompanyService(
      userData,
      setFirstNameErrorMessage,
      setFirstNameFieldState,
      setLastNameErrorMessage,
      setLastNameFieldState
    );
    if (checkPassword && checkConfirmPassword && checkUserNameAndFamily) {
      setLoading(true);
      var completeInfoResponse: IServiceResult<UserTokenModel> =
        await ConfirmUserInfoService(userData);
      if (completeInfoResponse.data != null) {
        dispatch(GetUserBuildingDataAction(completeInfoResponse.data.userId));
        navigate("/building");
      } else {
        setMessage(completeInfoResponse.message);
        setLoading(false);
      }
    } else {
      setLoading(false);
    }
  };

  return (
    <>
      {preload ? (
        <Loading />
      ) : (
        <BeforeDashboardForm
          title={translate("AUTHENTICATION.LOGIN")}
          description={translate("AUTHENTICATION.CHECK_INFO")}
          body={
            <div>
              {message.length > 0 ? (
                <MessageHandler
                  message={translate(message)}
                  type={MessageType.Error}
                />
              ) : (
                <></>
              )}
              <TextField
                value={firstName}
                placeHolder={`${translate("PLACEHOLDER.FIRSTNAME")} 
                `}
                errorMessage={translate(firstNameErrorMessage)}
                label={translate("LABELS.FIRSTNAME")}
                onChange={changeFirstName}
                fieldState={firstNameFieldState}
                condotion={CheckLengthtRegex}
              />
              <TextField
                value={lastName}
                placeHolder={`${translate("PLACEHOLDER.LASTNAME")} 
                    `}
                errorMessage={translate(lastNameErrorMessage)}
                label={translate("LABELS.LASTNAME")}
                onChange={changeLastName}
                fieldState={lastNameFieldState}
                condotion={CheckLengthtRegex}
              />
              <ConfirmUserPhoneNumber
                setComplete={setPhoneNumberValidate}
                country={country}
                phoneNumber={phoneNumber}
                setPhoneNumber={onChangePhoneNumber}
                defaultState={phoneNumberValidationState}
              />
              <ConfirmUserEmail
                setComplete={setEmailValidate}
                email={email}
                setEmail={onChangeEmail}
                defaultState={emailValidationState}
              />
              <PasswordField
                errorMessage={passwordErrorMessage}
                label={translate("AUTHENTICATION.PASSWORD")}
                onChange={onChangePassword}
                condotion={PasswordRegex}
                checkPass={true}
                fieldState={passwordFieldState}
                placeholder={translate("PLACEHOLDER.PASSWORD")}
              />
              <PasswordField
                errorMessage={confirmPasswordErrorMessage}
                label={translate("AUTHENTICATION.CONFIRM_PASSWORD")}
                onChange={onChangeConfirmPassword}
                placeholder={translate("PLACEHOLDER.CONFIRM_PASSWORD")}
                checkPass={true}
                fieldState={confirmPasswordFieldState}
              />
              <div className={`mt-5 mb-3`}>
                {phoneNumberValidate && emailValidate ? (
                  <Button
                    text={translate("BUTTON.NEXT")}
                    clickMethod={completeInfo}
                  />
                ) : (
                  <Button
                    text={translate("BUTTON.NEXT")}
                    state={ButtonState.disable}
                  />
                )}
              </div>
              {loading === true && <Loading />}
            </div>
          }
        />
      )}
    </>
  );
};

export default ConfirmationUserInfo;
