import React, { useEffect, useState } from "react";
import { DashboardBox } from "../../../../../tools/box/DashboardBox";
import DashboardTitle from "../../../../../tools/dashboardTitle/DashboardTitle";
import Style from "./BuildingServiceTab.module.scss";
import {
  IBuildingServiceCategoriesReducer,
  IReducer,
} from "../../../../../viewModels/reduxInterfaces/ReduxInterfaces";
import { useDispatch, useSelector } from "react-redux";
import { ServiceCategory } from "../../../../../viewModels/api/Models";
import { useParams } from "react-router-dom";
import IconWrapper from "../../../../../tools/icons/CustomIcon";
import { AppThunkDispatch } from "../../../../../redux/Store";
import { GetBuildingServiceCategories } from "../../../../../redux/slices/BuildingServiceCategories";
import { useTranslation } from "react-i18next";
import { ServiceTabItemEnum, TabItems } from "./BuildingServiceTab.data";
import BuildingServicesList from "./servicesComponent/buildingServicesList/BuildingServicesList";
import ToggleSwitch from "../../../../../tools/toggleSwitch/ToggleSwitch";
import ServiceRequests from "../requests/ServiceRequests";

const BuildingServiceTab = () => {
  // Configuration component
  const { categoryId, buildingId } = useParams();

  const buildingServiceCategoriesInfo: IBuildingServiceCategoriesReducer =
    useSelector((state: IReducer) => state.buildingServiceCategories);

  const dispatch = useDispatch<AppThunkDispatch>();

  const { t: translate } = useTranslation();
  // States
  const [categoryIcon, setCategoryIcon] = useState<string>("");
  const [categoryName, setCategoryName] = useState<string>("");
  const [activeTab, setActiveTab] = useState<string>(
    ServiceTabItemEnum.Service
  );
  // Effects
  useEffect(() => {
    const getCategoriesInfo = async () => {
      if (buildingServiceCategoriesInfo.fill) {
        const currentServiceCategory =
          buildingServiceCategoriesInfo.categories?.find(
            (category: ServiceCategory) => category.id === Number(categoryId)
          );

        setCategoryIcon(currentServiceCategory?.iconTitle ?? "");
        setCategoryName(currentServiceCategory?.title ?? "");
      } else {
        dispatch(GetBuildingServiceCategories(Number(buildingId)));
      }
    };
    getCategoriesInfo();
  }, [buildingServiceCategoriesInfo]);
  const changeTabHandler = (activeTab: string) => {
    setActiveTab(activeTab);
  };

  return (
    <DashboardBox>
      <DashboardTitle
        backButton
        icon={
          <IconWrapper name={categoryIcon} className={Style.category_icon} />
        }
        label={`${categoryName} ${translate("SERVICE.SERVICES")}`}
        className={Style.service_title}
      />
      <div className={Style.services_container}>
        <ToggleSwitch
          TabItems={TabItems}
          setActiveTab={changeTabHandler}
          activeTab={activeTab}
        />
      </div>
      {activeTab === ServiceTabItemEnum.Service ? (
        <div className={Style.services_container_service}>
          <BuildingServicesList />
        </div>
      ) : (
        <div className={Style.services_requests_container}>
          <ServiceRequests />
        </div>
      )}
    </DashboardBox>
  );
};

export default BuildingServiceTab;
