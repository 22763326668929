import {
  BaseFilesPath,
  BaseUrl,
  DeleteUserFromUserListRequest,
  GetBuildingInfoRequest,
  GetCitiesByProvinceRequest,
  GetProvincesByCountryRequest,
  GetUserBuildingRequest,
  UpdateBuildingInfoRequest,
} from "../../text/Api";
import { ApiResponse } from "../../viewModels/api/Api";
import { BuildingInfoViewModel, DeleteBuildingUserViewModel } from "../../viewModels/api/Models";
import { IBuilding } from "../../viewModels/dataTypes/building/IBuilding";
import { IUserBuilding } from "../../viewModels/dataTypes/building/UserBuildingViewModel";
import { ICity } from "../../viewModels/dataTypes/country/Country";
import { ApiMethod } from "../../viewModels/enums/ApiMethod";
import { CallApi } from "../CallApi";
import {
  FloorinsDateTimeFormat,
  GenerateRandomColor,
} from "../commonServices/CommonServices";
import { DefaultApiHeader } from "./ApiHeaders";



export const GetUserBuilding = async (id: string): Promise<IUserBuilding[]> => {
  const Url: string = BaseUrl + GetUserBuildingRequest + id;

  var Result: ApiResponse<IUserBuilding[]> = await CallApi(
    Url,
    "{}",
    DefaultApiHeader,
    ApiMethod.Get
  );
  var buildingList: IUserBuilding[] = [];
  if (Result.data != null) {
    Result.data.forEach((buildings: IUserBuilding) => {
      const bgColor = GenerateRandomColor();
      buildingList.push({
        fileName:
          buildings.fileName != null
            ? BaseFilesPath + buildings.fileName
            : null,
        lastModified: FloorinsDateTimeFormat(buildings.lastModified),
        lastView: FloorinsDateTimeFormat(buildings.lastView),
        bgAvatar: bgColor,
        buildingName: buildings.buildingName,
        id: buildings.id,
      });
      // Update the age field for each object

      // Increment age by 1, you can use any other logic here
    });
    return buildingList;
  } else {
    return [];
  }
};

export const GetProvincesByCountry = async (id: number) => {
  const Url: string = BaseUrl + GetProvincesByCountryRequest + id;

  var Result: ApiResponse<ICity[]> = await CallApi(
    Url,
    "{}",
    DefaultApiHeader,
    ApiMethod.Get
  );
  return Result;
};

export const GetCitiesByProvince = async (province: string) => {
  const Url: string = BaseUrl + GetCitiesByProvinceRequest + province;

  var Result: ApiResponse<ICity[]> = await CallApi(
    Url,
    "{}",
    DefaultApiHeader,
    ApiMethod.Get
  );
  return Result;
};

export const GetBuildingInfo = async (buildingId: number) => {
  const Url: string = BaseUrl + GetBuildingInfoRequest + buildingId;

  var Result: ApiResponse<IBuilding> = await CallApi(
    Url,
    "{}",
    DefaultApiHeader,
    ApiMethod.Get
  );
  return Result;
};

export const UpdateBuildingInfo = async (
  buildingInfo: BuildingInfoViewModel
) => {
  const Url: string = BaseUrl + UpdateBuildingInfoRequest;
  var Result: ApiResponse<boolean> = await CallApi(
    Url,
    JSON.stringify(buildingInfo),
    DefaultApiHeader,
    ApiMethod.Post
  );

  return Result;
};


export const DeleteUserFromList = async (
  buildingInfo: DeleteBuildingUserViewModel
) => {
  const Url: string = BaseUrl + DeleteUserFromUserListRequest;
  var Result: ApiResponse<boolean> = await CallApi(
    Url,
    JSON.stringify(buildingInfo),
    DefaultApiHeader,
    ApiMethod.Delete
  );

  return Result;
};