import { GetComplaintInfo } from "../../../../../services/api/Complaint";
import { ApiResponse, IServiceResult } from "../../../../../viewModels/api/Api";
import { Complaint } from "../../../../../viewModels/api/Models";
import { ResponseCode } from "../../../../../viewModels/enums/ResponseCode";

export const GetComplaintInfoService = async (complaintId: number) => {
  const resultModel: IServiceResult<Complaint | null> = {
    message: "",
    data: null,
  };
  const addServiceResult: ApiResponse<Complaint> = await GetComplaintInfo(
    complaintId
  );
  if (addServiceResult.responseStatus == ResponseCode.Success) {
    resultModel.data = addServiceResult.data;
  }

  return resultModel;
};
