import {
  DeleteServiceCategory,
  UpdateServiceCategory,
} from "../../../../../../../services/api/BuildingServices";
import {
  ApiResponse,
  IServiceResult,
} from "../../../../../../../viewModels/api/Api";
import { ServiceCategory } from "../../../../../../../viewModels/api/Models";

export const UpdateServiceCategoryService = async (
  buildingId: number,
  title: string,
  iconId: number,
  id: number
) => {
  const resultModel: IServiceResult<boolean> = {
    message: "",
    data: false,
  };
  const newServiceCategoryInfo: ServiceCategory = {
    id: id,
    buildingId: buildingId,
    isDelete: false,
    title: title,
    iconId: iconId,
  };
  const getServiceCategoriesResult: ApiResponse<boolean> =
    await UpdateServiceCategory(newServiceCategoryInfo);
  resultModel.data = getServiceCategoriesResult.data;
  return resultModel;
};

export const DeleteServiceCategoryService = async (id: number) => {
  const resultModel: IServiceResult<boolean> = {
    message: "",
    data: false,
  };

  const getServiceCategoriesResult: ApiResponse<boolean> =
    await DeleteServiceCategory(Number(id));
  resultModel.data = getServiceCategoriesResult.data;
  return resultModel;
};
