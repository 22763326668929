import { Stop, TickSquare } from "iconsax-react";
import React from "react";
import Style from "./CheckRules.module.scss";
import { useTranslation } from "react-i18next";
import { FieldState } from "../../../viewModels/enums/PublicEnum";

interface Props {
  isChecked: boolean;
  onClick: () => void;
  ruleState?: FieldState;
}
const CheckRules: React.FC<Props> = ({
  isChecked,
  onClick,
  ruleState = FieldState.Default,
}) => {
  const { t: translate } = useTranslation();
  return (
    <div
      className={`${Style.check_rules} ${
        ruleState == FieldState.Error && isChecked == false ? Style.error : ""
      }`}
      onClick={onClick}
    >
      {isChecked === false ? (
        <Stop
          color={`${
            ruleState == FieldState.Error ? "var(--icon-red)" : "var(--icon-gray-high)"
          }`}
          size={24}
        />
      ) : (
        <TickSquare color="var(--icon-primary)" variant="Bold" size={24} />
      )}
      <span>
        {translate("SHARED.AGREE_RULES")}
        {translate("SHARED.PRIVACY_POLICY")}
        {translate("SHARED.AND")}
        {translate("SHARED.TERMS_OF_SERVICE")}
      </span>
    </div>
  );
};

export default CheckRules;
