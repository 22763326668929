import {
  GetBuildingUserInfo,
  UpdateAdministrator,
  UpdateRangeOfResidents,
} from "../../../../../../services/api/User";
import {
  ApiResponse,
  IServiceResult,
} from "../../../../../../viewModels/api/Api";
import { AddAdministratorViewModel } from "../../../../../../viewModels/api/Models";
import { BuildingUserInfoViewModel } from "../../../../../../viewModels/dataTypes/building/IBuildingUserInfoViewModels";
import { IDropDown } from "../../../../../../viewModels/dataTypes/tools/DropDownItems";
import { IAdministrator } from "../../../../../../viewModels/dataTypes/user/IAdministrator";
import { IResident } from "../../../../../../viewModels/dataTypes/user/IResident";
import { ResponseCode } from "../../../../../../viewModels/enums/ResponseCode";

export const GetBuildingUserInfoService = async (
  buildingId: string,
  userId: string
) => {
  var resultModel: IServiceResult<BuildingUserInfoViewModel> = {
    message: "",
  };
  var result: ApiResponse<BuildingUserInfoViewModel> =
    await GetBuildingUserInfo(buildingId, userId);
  if (result.responseStatus == ResponseCode.Success) {
    resultModel.data = result.data;
  } else {
    resultModel.data = null;
    resultModel.message = "ERRORS.OPERATION_FAILD";
  }
  return resultModel;
};

export const GetAdminPermissionsService = (permissions: IAdministrator[]) => {
  if (!permissions) return [];

  const userPermissions: IDropDown[] = [];

  permissions?.map((item: IAdministrator) => {
    userPermissions.push({
      text: item.userRoleName ?? "",
      value: item.userRoleId?.toString() ?? "0",
    });
  });
  return userPermissions;
};

export const UpdateAdministrationInfo = async (
  adminInfo: AddAdministratorViewModel
) => {
  var resultModel: IServiceResult<IAdministrator[]> = {
    message: "",
  };
  var result: ApiResponse<IAdministrator[]> = await UpdateAdministrator(
    adminInfo
  );
  if (result.responseStatus == ResponseCode.Success) {
    resultModel.data = result.data;
  } else {
    resultModel.data = null;
  }
  return resultModel;
};

export const UpdateResidentialInfo = async (residents: IResident[]) => {
  var resultModel: IServiceResult<IResident[]> = {
    message: "",
  };
  var result: ApiResponse<IResident[]> = await UpdateRangeOfResidents(
    residents
  );
  if (result.responseStatus == ResponseCode.Success) {
    resultModel.data = result.data;
  } else {
    resultModel.data = null;
    resultModel.message = "ERRORS.EDITION_FAILD";
  }
  return resultModel;
};
