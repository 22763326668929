import { Grid } from "@mui/material";
import React, { Dispatch, useEffect, useState } from "react";
import ContainerStyle from "../ServiceInfoComponent.module.scss";
import useWindowSize from "../../../../../../../../hook/WindowSize";
import { MediumDeviceTrigger } from "../../../../../../../../Setting";
import FloorinsDatePicker from "../../../../../../../../tools/datePicker/DatePicker";
import { useTranslation } from "react-i18next";
import WeekDays from "../../../../../../../../tools/weekDays/WeekDays";
import { WeekDaysType } from "../../../../../../../../viewModels/dataTypes/tools/WeekDaysType";
import {
  ServiceDateAction,
  ServiceDateActionType,
  ServiceDateInfo,
} from "./ServiceDate.State";
import { ListOfRepeatDuration } from "./ServiceDate.Service";
import dayjs, { Dayjs } from "dayjs";
import { IDurationRepeat } from "../../../../../../../../viewModels/dataTypes/tools/IDurationRepeat";
import DurationRepeat from "../../../../../../../../tools/durationRepeat/DurationRepeat";

interface Props {
  weekDayInfo: WeekDaysType[];
  onChangeWeekDayInfo: (value: WeekDaysType[]) => void;
  changeDispatch: Dispatch<ServiceDateAction>;
  states: ServiceDateInfo;
}
const ServiceDate: React.FC<Props> = ({
  weekDayInfo,
  onChangeWeekDayInfo,
  changeDispatch,
  states,
}) => {
  //configs
  const windowSize = useWindowSize();
  const { t: translate } = useTranslation();

  //states
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);
  const [durationRepeatValue, setDurationRepeatValue] =
    useState<IDurationRepeat>(states.durationRepeat);

  const changeStartDateHandler = (value: Date | null) => {
    if ((value ?? new Date()) < (new Date())) {
      changeDispatch({
        type: ServiceDateActionType.StartDate,
        payload: new Date(),
      });
    } else {
      changeDispatch({ type: ServiceDateActionType.StartDate, payload: value });
    }

    if ((value ?? new Date()) > (startDate ?? new Date())) {
      changeDispatch({ type: ServiceDateActionType.EndDate, payload: value });
    }
  };

  const changeEndDateHandler = (value: Date | null) => {
    if ((value ?? new Date()) < (startDate ?? new Date())) {
      changeDispatch({
        type: ServiceDateActionType.EndDate,
        payload: new Date(startDate?.toDateString() ?? ""),
      });
    } else {
      changeDispatch({ type: ServiceDateActionType.EndDate, payload: value });
    }
  };

  const changeDurationRepeatValueHandler = (value: IDurationRepeat) => {
    changeDispatch({
      type: ServiceDateActionType.DurationRepeat,
      payload: value,
    });
  };

  const disableDatesBefore = (disableBeforeDate: Dayjs) => {
    return (day: Dayjs): boolean => {
      return day.isBefore(disableBeforeDate, "day");
    };
  };

  useEffect(() => {
    setStartDate(states.startDate);
    setEndDate(states.endDate);
    setDurationRepeatValue(states.durationRepeat);
  }, [states]);

  const shouldDisable: (day: Dayjs) => boolean = disableDatesBefore(
    dayjs(startDate)
  );

  return (
    <Grid
      className={ContainerStyle.service_accordion_box_container}
      container
      columnSpacing={windowSize.width > MediumDeviceTrigger ? 8 : 6}
    >
      <Grid item md={6} sm={12} xs={12}>
        <FloorinsDatePicker
          value={startDate}
          onChange={changeStartDateHandler}
          label={translate("LABELS.START_DATE")}
          disablePast={true}
        />
      </Grid>
      <Grid item md={6} sm={12} xs={12}>
        <FloorinsDatePicker
          value={endDate}
          onChange={changeEndDateHandler}
          label={translate("LABELS.END_DATE")}
          shouldDisable={shouldDisable}
          disablePast={true}
        />
      </Grid>
      <Grid item md={6} sm={6} xs={6}>
        <DurationRepeat
          value={durationRepeatValue}
          label={translate("LABELS.REPEAT_EVERY")}
          dropDownDate={ListOfRepeatDuration}
          onChange={changeDurationRepeatValueHandler}
        />
      </Grid>
      <Grid item md={6} sm={12} xs={12}>
        <WeekDays items={weekDayInfo} onChange={onChangeWeekDayInfo} />
      </Grid>
    </Grid>
  );
};

export default ServiceDate;
