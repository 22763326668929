import Style from "./DashboardHeader.module.scss";
import { Notification } from "iconsax-react";
import TuneIcon from "@mui/icons-material/Tune";
import ProfileInfo from "./profile/ProfileInfo";
import MobileHeader from "./mobileHeader/MobileHeader";
import useWindowSize from "../../../hook/WindowSize";
import CurrentPlan from "./currentPlan/CurrentPlan";
import { PricingTypeEnum } from "../../../viewModels/enums/PricingType";
import { SmallDeviceTrigger } from "../../../Setting";
import { UserCredentialInfoViewModel } from "../../../viewModels/api/Models";

interface Props {
  userInfo: UserCredentialInfoViewModel | null;
  scrolled?: boolean;
}

const DashboardHeader: React.FC<Props> = ({ userInfo, scrolled = false }) => {
  const { width: screenSize } = useWindowSize();

  return (
    <>
      {screenSize > SmallDeviceTrigger ? (
        <header className={Style.header}>
          <ProfileInfo userInfo={userInfo} />
          <div className={Style.short_access}>
            <TuneIcon className={Style.tune_icon} />
            <Notification className={Style.notification_icon} />
            <CurrentPlan
              planType={userInfo?.planTitle ?? PricingTypeEnum.Trial}
              planImage={userInfo?.planImageUrl ?? ""}
            />
          </div>
        </header>
      ) : (
        <>
          <MobileHeader scrolled={scrolled} />
        </>
      )}
    </>
  );
};

export default DashboardHeader;
