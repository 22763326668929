import { ApiResponse, IServiceResult } from "../../../../viewModels/api/Api";
import { ContainsSearchTerm } from "../../../../tools/table/filter/filterSearch/FIlterSearch.Service";
import {
  FloorinsDateTimeFormat,
  IsNullString,
} from "../../../../services/commonServices/CommonServices";
import dayjs from "dayjs";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";
import customParseFormat from "dayjs/plugin/customParseFormat";
import { TicketViewModel } from "../../../../viewModels/dataTypes/building/TicketViewModel";
import {
  GetTickets,
  UpdateTicketsState,
} from "../../../../services/api/Tickets";
import { FormatDateDefault } from "../../../../Setting";
import { CalenderEnum } from "../../../../viewModels/enums/CalenderEnum";
import { FilterConditionsEnum } from "../../../../viewModels/enums/FilterConditionsEnum";
import { Ticket, TicketState } from "../../../../viewModels/api/Models";

dayjs.extend(isSameOrAfter);
dayjs.extend(isSameOrBefore);
dayjs.extend(customParseFormat);

export const GetTicketItems = async (): Promise<
  IServiceResult<TicketViewModel[]>
> => {
  const resultModel: IServiceResult<TicketViewModel[]> = {
    message: "",
    data: null,
  };

  const getTicketsResult: ApiResponse<Ticket[]> = await GetTickets();

  const transformedData: TicketViewModel[] =
    getTicketsResult.data?.map((ticket: Ticket, index: number) => ({
      ...ticket,
      lastUpdateDateTime: FloorinsDateTimeFormat(
        ticket.lastUpdateDateTime?.toString() ?? ""
      ),
      createdDateTime: FloorinsDateTimeFormat(
        ticket.createdDateTime?.toString() ?? ""
      ),
      number: index + 1,
    })) || [];

  resultModel.data = transformedData;

  return resultModel;
};

export const UpdateTicketState = async (stateInfo: TicketViewModel) => {
  const resultModel: IServiceResult<boolean> = {
    message: "",
    data: false,
  };

  const getTicketStateResult: ApiResponse<TicketViewModel> =
    await UpdateTicketsState(stateInfo);
  resultModel.data = !!getTicketStateResult.data;
  return resultModel;
};

const SearchBuildingTickets = (data: TicketViewModel[], searchTerm: string) => {
  if (IsNullString(searchTerm)) {
    return data;
  }

  return data.filter((item: TicketViewModel) =>
    ContainsSearchTerm(item, searchTerm)
  );
};

const cleanTicketCategory = (category: string): string => {
  switch (category) {
    case "building/units/undefined":
      return "units";
    case "building/info/undefined":
      return "buildingInfo";
    case "building/announcements/undefined":
      return "announcements";
    case "building/accounting/undefined":
      return "accounting";
    case "building/complaints/undefined":
      return "complaints";
    case "building/services/undefined":
      return "services";
    case "tickets":
      return "appSupport";
    case "building/users/undefined":
      return "users";
    default:
      return category;
  }
};

export const FilterBuildingTickets = (
  data: TicketViewModel[],
  searchText: string,
  ticketCategories: string,
  ticketsLogic: string,
  states: string,
  dateRange: { start: string; end: string } | null,
  selectedMode: string
) => {
  const searchedData = SearchBuildingTickets(data, searchText);

  if (IsNullString(states) && IsNullString(ticketCategories) && !dateRange) {
    return searchedData;
  }

  const ticketsList: string[] = ticketCategories
    ? ticketCategories
        .replace(/\/dashboard\//g, "")
        .split(",")
        .map((u) => cleanTicketCategory(u.trim()))
    : [];

  const stateList: number[] = states
    ? states.split(",").map((s) => parseInt(s.trim(), 10))
    : [];

  const filteredData = searchedData.filter((item: TicketViewModel) => {
    const ticketCategoriesMatch =
      ticketsLogic === FilterConditionsEnum.OR
        ? ticketsList.length === 0 ||
          ticketsList.some((ticketCategory: string) => {
            return (
              item.ticketType
                ?.toLowerCase()
                .trim()
                .toLowerCase()
                .replace(/\s+/g, "") ===
              ticketCategory
                .toLowerCase()
                .trim()
                .toLowerCase()
                .replace(/\s+/g, "")
            );
          })
        : ticketsList.length === 0 ||
          ticketsList.every((ticketCategory: string) => {
            return (
              item.ticketType?.trim().toLowerCase().replace(/\s+/g, "") ===
              ticketCategory.trim().toLowerCase().replace(/\s+/g, "")
            );
          });

    const statesMatch =
      stateList.length === 0 ||
      stateList.some((state: TicketState) => item.status === state);

    const dateMatch =
      !dateRange ||
      (() => {
        const itemDate = dayjs(item.createdDateTime);
        const startDate = dayjs(dateRange.start, FormatDateDefault, true);
        const endDate = dayjs(dateRange.end, FormatDateDefault, true);

        if (!itemDate.isValid() || !startDate.isValid() || !endDate.isValid()) {
          return false;
        }

        switch (selectedMode) {
          case CalenderEnum.DAY:
            return (
              itemDate.isSameOrAfter(startDate, CalenderEnum.DAY) &&
              itemDate.isSameOrBefore(endDate, CalenderEnum.DAY)
            );
          case CalenderEnum.WEEK:
            return (
              itemDate.isSameOrAfter(startDate, CalenderEnum.DAY) &&
              itemDate.isSameOrBefore(endDate, CalenderEnum.DAY)
            );
          case CalenderEnum.MONTH:
            return (
              itemDate.isSameOrAfter(startDate, CalenderEnum.MONTH) &&
              itemDate.isSameOrBefore(endDate, CalenderEnum.MONTH)
            );
          case CalenderEnum.YEAR:
            return (
              itemDate.isSameOrAfter(startDate, CalenderEnum.YEAR) &&
              itemDate.isSameOrBefore(endDate, CalenderEnum.YEAR)
            );
          default:
            return true;
        }
      })();

    return ticketCategoriesMatch && statesMatch && dateMatch;
  });

  return filteredData;
};
