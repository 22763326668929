import React, { useEffect, useState } from "react";
import Style from "./MenuItem.module.scss";
import { MenuItemsViewModel } from "../../../../viewModels/dataTypes/tools/MenuItemsViewModel";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import SubMenu from "./SubMenu";
import { useDispatch, useSelector } from "react-redux";
import { AppThunkDispatch } from "../../../../redux/Store";
import MessagePopUp from "../../../../tools/popup/MessagePopUp";
import { InfoCircle } from "iconsax-react";
import Button from "../../../../tools/button/Button";
import { ButtonTheme } from "../../../../viewModels/enums/ButtonEnum";
import { SizeType } from "../../../../viewModels/dataTypes/tools/ListComponentType";
import {
  setHasUnsavedChanges,
  setShowPopup,
} from "../../../../redux/slices/UnsavedChangesSlice";

interface Props {
  item: MenuItemsViewModel;
  onItemClick: (path: string) => void;
  selectedItemPath: string;
}

const MenuItem: React.FC<Props> = ({ item, onItemClick, selectedItemPath }) => {
  const { t: translate } = useTranslation();
  const location = useLocation();
  const [hover, setHover] = useState(false);
  const navigate = useNavigate();
  const [activeSubMenu, setActiveSubMenu] = useState<boolean>(false);
  const dispatch = useDispatch<AppThunkDispatch>();
  const [selectedSubMenuPath, setSelectedSubMenuPath] = useState<string>("");
  const hasUnsavedChanges = useSelector(
    (state: any) => state.unsavedChanges.hasUnsavedChanges
  );
  const showPopup = useSelector((state: any) => state.unsavedChanges.showPopup);

  const onMouseEnterHandler = () => {
    setHover(true);
  };

  const onMouseLeaveHandler = () => {
    setHover(false);
  };

  const checkIfActive = (item: MenuItemsViewModel): boolean => {
    if (item.path !== location.pathname) {
      if (item.subMenu) {
        const activeSubMenu = item.subMenu.some((child) =>
          location.pathname.startsWith(child.path)
        );
        return activeSubMenu;
      } else {
        return false;
      }
    }
    return false;
  };

  const handleClick = () => {
    onItemClick(item.path);
  };

  const handleSubMenuClick = (path: string) => {
    setSelectedSubMenuPath(path);
  };

  const discardHandler = () => {
    dispatch(setShowPopup(false));
    dispatch(setHasUnsavedChanges(false));
    navigate(selectedSubMenuPath || selectedItemPath);
  };

  useEffect(() => {
    setActiveSubMenu(checkIfActive(item));
  }, [location, item]);

  return (
    <>
      <li
        className={Style.menu_item}
        key={item.path}
        onMouseEnter={onMouseEnterHandler}
        onMouseLeave={onMouseLeaveHandler}
        onClick={handleClick}
      >
        <NavLink
          className={({ isActive }) =>
            isActive || hover ? Style.item_menu_active : ""
          }
          to={item.path}
          onClick={(e) => {
            if (hasUnsavedChanges) {
              e.preventDefault();
              dispatch(setShowPopup(true));
            } else {
              navigate(item.path);
            }
          }}
        >
          {({ isActive }) =>
            isActive || hover ? (
              <>
                {item.boldIcon}
                {translate(item.titr)}
              </>
            ) : (
              <>
                {item.icon}
                {translate(item.titr)}
              </>
            )
          }
        </NavLink>
        {activeSubMenu && item.subMenu != null ? (
          <ul>
            {item.subMenu.map((subItem: MenuItemsViewModel) => (
              <SubMenu
                item={subItem}
                key={subItem.path}
                selectedItemPath={selectedSubMenuPath || selectedItemPath}
                onSubMenuClick={handleSubMenuClick}
              />
            ))}
          </ul>
        ) : null}
      </li>
      {showPopup && selectedItemPath === item.path ? (
        <MessagePopUp
          icon={<InfoCircle />}
          title={translate("SHARED.LEAVE_PAGE")}
          description={translate("ALERT.LEAVE_PAGE")}
          buttons={
            <>
              <Button
                className={Style.cancel_button}
                text={translate("BUTTON.CANCEL")}
                size={SizeType.small}
                theme={ButtonTheme.primaryReverse}
                clickMethod={() => dispatch(setShowPopup(false))}
              />
              <Button
                className={Style.cancel_button}
                text={translate("BUTTON.DISCARD")}
                size={SizeType.small}
                theme={ButtonTheme.primary}
                clickMethod={discardHandler}
              />
            </>
          }
        />
      ) : null}
    </>
  );
};

export default MenuItem;
