import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import DropDownStyle from "./IconDropDown.module.scss";
import { StyledMenu } from "./StyledMenu";
import { Icon } from "../../../viewModels/api/Models";
import IconSliderItem from "../../../components/dashboard/dashboardContents/services/serviceCategory/servicesCategoryPopUp/servicesIconSlider/IconSliderItem";
import {
  IReducer,
  IServicesIconReducer,
} from "../../../viewModels/reduxInterfaces/ReduxInterfaces";
import { useDispatch, useSelector } from "react-redux";
import { AppThunkDispatch } from "../../../redux/Store";
import { GetServicesIcons } from "../../../redux/slices/ServicesIcons";

interface Props {
  label?: string;
  iconId: number;
  onChange: (value: number) => void;
}
const IconDropDown: React.FC<Props> = ({ label, iconId, onChange }) => {
  const [icons, setIcons] = useState<Icon[] | null>(null);
  const [activeIcon, setActiveIcon] = useState<Icon | null>(null);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  //redux
  const servicesIconsInfo: IServicesIconReducer = useSelector(
    (state: IReducer) => state.servicesIcons
  );
  const dispatch = useDispatch<AppThunkDispatch>();
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    if (servicesIconsInfo.fill) {
      setIcons(servicesIconsInfo.icons);
      const currentIcon = findIconHandler(
        servicesIconsInfo.icons ?? [],
        iconId
      );
      setActiveIcon(currentIcon);
    } else {
      dispatch(GetServicesIcons());
    }
  }, [servicesIconsInfo, iconId]);

  const changeIconHandler = (iconId: number) => {
    setActiveIcon(findIconHandler(icons ?? [], iconId));
    onChange(iconId);
    setAnchorEl(null);
  };

  const findIconHandler = (iconsList: Icon[], iconId: number): Icon | null => {
    const currentIcon = iconsList?.find((item: Icon) => item.id === iconId);
    return currentIcon ?? null;
  };
  return (
    <div className={DropDownStyle.dropdown_box}>
      <label className={`${DropDownStyle.label}`}>
        <span>{label}</span>
      </label>
      <div className={DropDownStyle.dropdown_box_select}>
        <Button
          className={DropDownStyle.dropdown_button}
          onClick={handleClick}
          endIcon={<KeyboardArrowDownIcon />}
        >
          <IconSliderItem
            className={DropDownStyle.active_icon}
            color={activeIcon?.color ?? "var(--gray-600)"}
            icon={activeIcon?.title ?? ""}
          />
        </Button>
        <StyledMenu anchorEl={anchorEl} open={open} onClose={handleClose}>
          {icons?.map((item: Icon) => (
            <li onClick={() => changeIconHandler(item.id ?? 0)}>
              <IconSliderItem
                className={DropDownStyle.item_icon}
                color={item.color ?? ""}
                icon={item.title ?? ""}
              />
            </li>
          ))}
        </StyledMenu>
      </div>
    </div>
  );
};
export default IconDropDown;
