import React, { ReactNode, useState } from "react";
import DashboardFormTitle from "../dashboardTitle/dashboardFormTitle/DashboardFormTitle";
import Style from "./Accordion.module.scss";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { ClassNameViewModel } from "../../viewModels/dataTypes/CommonViewModels";

interface Props extends ClassNameViewModel {
  content: ReactNode;
  children?: ReactNode;
  icon: ReactNode;
  title: string;
  accordionIsOpen?: boolean;
  callToActionNode?: ReactNode;
}
const Accordion: React.FC<Props> = ({
  content,
  children,
  icon,
  title,
  accordionIsOpen = true,
  callToActionNode,
  className = "",
}) => {
  const [isOpen, setIsOpen] = useState<boolean>(accordionIsOpen);
  const changeAccordionState = () => {
    setIsOpen(!isOpen);
  };
  return (
    <div className={`${Style.accordion} ${className}`}>
      <div className={Style.accordion_head}>
        <div className={Style.accordion_head_items}>
          <div onClick={changeAccordionState}>
            {isOpen ? (
              <KeyboardArrowDownIcon
                className={Style.accordion_head_arrow_down}
              />
            ) : (
              <KeyboardArrowRightIcon
                className={Style.accordion_head_arrow_down}
              />
            )}
          </div>
          <DashboardFormTitle
            className={Style.accordion_title}
            title={title}
            icon={icon}
            children={children}
          />
        </div>
        <div className={Style.accordion_head_calltoaction}>
          {callToActionNode}
        </div>
      </div>
      {isOpen && (
        <div className={Style.content}>
          <div className={Style.border}></div>
          {content}
        </div>
      )}
    </div>
  );
};

export default Accordion;
