import {
  ChoosePlanToCreateBuilding,
  GetApplicationPlans,
} from "../../../services/api/Plan";
import {
  ApiResponse,
  IResponseStatus,
  IServiceResult,
} from "../../../viewModels/api/Api";
import { ChoosePlanToCreateBuildingViewModel } from "../../../viewModels/api/Models";
import { PricingItem } from "../../../viewModels/dataTypes/plan/Pricing";
import { ResponseCode } from "../../../viewModels/enums/ResponseCode";

export const GetPlans = async () => {
  const getProvinceResult: ApiResponse<PricingItem[]> =
    await GetApplicationPlans();
  if (getProvinceResult.responseStatus == ResponseCode.Success) {
    return getProvinceResult.data;
  } else {
    return null;
  }
};

export const CheckCreateBuildingInfo = (
  cityId: number,
  province: string,
  buildingName: string,
  planId: number
) => {
  return (
    cityId != 0 && planId != 0 && province.length != 0 && buildingName.length != 0
  );
};

export const ChoosePlanService = async (
  choosePlanItems: ChoosePlanToCreateBuildingViewModel
) => {
  var _result: IServiceResult<IResponseStatus> = {
    message: "",
  };
  var choosePlanResult: ApiResponse<IResponseStatus> =
    await ChoosePlanToCreateBuilding(choosePlanItems);
  if (choosePlanResult.responseStatus == ResponseCode.Success) {
    _result.data = choosePlanResult.data;
  } else {
    _result.message = "ERRORS.OPERATION_FAILD";
  }
  return _result;
};
