import { BodyOfGetRequest } from "../../Setting";
import { BaseUrl, GetBuildingTickets, UpdateTicketState } from "../../text/Api";

import { ApiResponse } from "../../viewModels/api/Api";
import { Ticket } from "../../viewModels/api/Models";
import { TicketViewModel } from "../../viewModels/dataTypes/building/TicketViewModel";
import { ApiMethod } from "../../viewModels/enums/ApiMethod";
import { CallApi } from "../CallApi";
import { DefaultApiHeader } from "./ApiHeaders";

export const GetTickets = async () => {
  const Url: string = `${BaseUrl}${GetBuildingTickets}`;

  let Result: ApiResponse<Ticket[]> = await CallApi(
    Url,
    BodyOfGetRequest,
    DefaultApiHeader,
    ApiMethod.Get
  );
  return Result;
};

export const UpdateTicketsState = async (stateInfo: TicketViewModel) => {
  const Url: string = `${BaseUrl}${UpdateTicketState}`;
  const raw = JSON.stringify(stateInfo);

  let Result: ApiResponse<TicketViewModel> = await CallApi(
    Url,
    raw,
    DefaultApiHeader,
    ApiMethod.Put
  );

  return Result;
};
