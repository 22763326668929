import {
  ActionReducerMapBuilder,
  PayloadAction,
  createAsyncThunk,
  createSlice,
} from "@reduxjs/toolkit";
import { IBuildingComplaintsReducer } from "../../viewModels/reduxInterfaces/ReduxInterfaces";

import { ComplaintsViewModel } from "../../viewModels/dataTypes/complaints/ComplaintsViewModel";
import { GetComplaintsItems } from "../../components/dashboard/dashboardContents/complaints/complaintsList/ComplaintsList.service";
import { IServiceResult } from "../../viewModels/api/Api";

const initialState = {
  complaints: [],
  loading: true,
  fill: false,
  error: "",
} as IBuildingComplaintsReducer;

const BuildingComplainsSlice = createSlice({
  name: "complaintsList",
  initialState,
  reducers: {},
  extraReducers: (
    builder: ActionReducerMapBuilder<IBuildingComplaintsReducer>
  ) => {
    builder.addCase(
      GetBuildingComplaintsAction.fulfilled,
      (state, action: PayloadAction<ComplaintsViewModel[]>) => {
        state.complaints = action.payload;
        state.loading = false;
        state.fill = true;
      }
    );
    builder.addCase(
      GetBuildingComplaintsAction.pending,
      (state: IBuildingComplaintsReducer) => {
        state.loading = true;
      }
    );
    builder.addCase(
      GetBuildingComplaintsAction.rejected,
      (state, action: PayloadAction<string | undefined>) => {
        state.loading = false;
        state.err = action.payload;
      }
    );
  },
});

export const GetBuildingComplaintsAction = createAsyncThunk<
  ComplaintsViewModel[], // Return type
  string, // Argument type
  { rejectValue: string } // Rejected action payload type
>(
  "GetBuildingComplainsAction",
  async (buildingId: string, { rejectWithValue }) => {
    try {
      const buildingComplains: IServiceResult<ComplaintsViewModel[]> =
        await GetComplaintsItems(buildingId);

      if (buildingComplains.data && buildingComplains.data.length > 0) {
        return buildingComplains.data; // Dispatch action if there are complaints
      } else {
        // If data is empty, reject the action
        return rejectWithValue("");
      }
    } catch (err) {
      return rejectWithValue("");
    }
  }
);

export default BuildingComplainsSlice.reducer;
