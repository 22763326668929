import BuildingProfileComponent from "../../../../../tools/table/profileComponent/BuildingProfileComponent";
import {
  AlignmentEnum,
  HeadCellsType,
} from "../../../../../viewModels/dataTypes/DataTableType";
import { IUserBuilding } from "../../../../../viewModels/dataTypes/building/UserBuildingViewModel";
import { ITabelCallToAction } from "../../../../../viewModels/dataTypes/tools/TabelCellSize";

export const headCells: HeadCellsType<IUserBuilding & ITabelCallToAction>[] = [
  {
    id: "buildingName",
    label: "BUILDING.BUILDING_NAME_TITR",
    align: AlignmentEnum.Left,
    sortable: true,
    specialComponent: BuildingProfileComponent,
    specialComponentProps: ["buildingName", "fileName", "bgAvatar"],
    isLink: true,
  },
  {
    id: "lastModified",
    label: "BUILDING.LAST_MODIFIED",
    align: AlignmentEnum.Center,
    sortable: true,
    specialComponent: null,
    isLink: true,
  },
  {
    id: "lastView",
    sortable: true,
    label: "BUILDING.LAST_VIEW",
    align: AlignmentEnum.Center,
    specialComponent: null,
    isLink: true,
  },
  {
    id: "action",
    sortable: false,
    label: "SHARED.ACTION",
    align: AlignmentEnum.Center,
    specialComponent: null,
  },
];
