import { useEffect, useRef } from "react";

const useNumericInputMode = () => {
  const inputRefs = useRef<(HTMLInputElement | null)[]>([]);

  useEffect(() => {
    inputRefs.current.forEach((input: HTMLInputElement | null) => {
      if (input) {
        input.setAttribute("inputmode", "numeric");
      }
    });
  }, []);

  return inputRefs;
};

export default useNumericInputMode;
