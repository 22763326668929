import {
  GetUserInfoByUserContact,
  InfoConfirmation,
} from "../../../../services/api/User";
import {
  Localstorage_UserToken,
  Localstorage_UserValidation,
} from "../../../../text/LocalStorageValues";
import { ApiResponse, IServiceResult } from "../../../../viewModels/api/Api";
import { UserInfoRegistrationViewModel } from "../../../../viewModels/api/Models";
import { UserTokenModel } from "../../../../viewModels/dataTypes/registration/TokenViewModel";
import { IUser } from "../../../../viewModels/dataTypes/user/User";
import { ResponseCode } from "../../../../viewModels/enums/ResponseCode";

export const GetUserInfoService = async (userContact: string) => {
  var _result: IServiceResult<IUser> = {
    message: "",
  };

  var userInfo: ApiResponse<IUser> = await GetUserInfoByUserContact(
    userContact
  );
  if (userInfo.responseStatus == ResponseCode.Success) {
    if (userInfo.data != null) {
      _result.data = userInfo.data;
    } else {
      _result.message = "ERRORS.OPERATION_FAILD";
    }
  } else {
    _result.message = "ERRORS.OPERATION_FAILD";
  }
  return _result;
};
export const ConfirmUserInfoService = async (
  userData: UserInfoRegistrationViewModel
) => {
  var _result: IServiceResult<UserTokenModel> = {
    message: "",
  };
  var personalRegistrationResult: ApiResponse<UserTokenModel> =
    await InfoConfirmation(userData);
  if (personalRegistrationResult.responseStatus == ResponseCode.Success) {
    localStorage.setItem(
      Localstorage_UserToken,
      JSON.stringify(personalRegistrationResult.data)
    );
    _result.data = personalRegistrationResult.data;
    localStorage.removeItem(Localstorage_UserValidation);
  } else {
    _result.message = "ERRORS.OPERATION_FAILD";
  }

  return _result;
};
