import React from "react";
import Style from "./ToggleSwitch.module.scss";
import { ToggleTabItem } from "../../viewModels/dataTypes/tools/ToggleTabItem";
import { useTranslation } from "react-i18next";

interface Props {
  activeTab: string;
  setActiveTab: (value: string) => void;
  TabItems: ToggleTabItem[];
}
const ToggleSwitch: React.FC<Props> = ({
  TabItems,
  setActiveTab,
  activeTab,
}) => {
  const changeTabHandler = (value: string) => {
    setActiveTab(value);
  };
  const { t: translate } = useTranslation();
  return (
    <div className={Style.services_tab}>
      {TabItems.map((item: ToggleTabItem) => (
        <div
          key={item.value}
          onClick={() => changeTabHandler(item.value)}
          className={activeTab === item.value ? Style.active_tab : ""}
        >
          {translate(item.label)}
        </div>
      ))}
    </div>
  );
};

export default ToggleSwitch;
