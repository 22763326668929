import { AddService } from "../../../../../../../../../services/api/BuildingServices";
import { GetNewId } from "../../../../../../../../../services/commonServices/CommonServices";
import {
  DefaultDuration,
  DefaultMaxTime,
  DefaultMinTime,
} from "../../../../../../../../../Setting";
import {
  ApiResponse,
  IServiceResult,
} from "../../../../../../../../../viewModels/api/Api";
import {
  BuildingServices,
  DaySchedule,
  DayOfWeek,
} from "../../../../../../../../../viewModels/api/Models";
import { WeekDaysType } from "../../../../../../../../../viewModels/dataTypes/tools/WeekDaysType";
import { ResponseCode } from "../../../../../../../../../viewModels/enums/ResponseCode";

export const UpdateDaySchedules = (
  days: WeekDaysType[],
  isSetToAll: boolean,
  daySchedules: DaySchedule[]
) => {
  if (isSetToAll) {
    let _days: DayOfWeek[] = [];
    days.map((day: WeekDaysType) => _days.push(day.value));
    daySchedules = [
      {
        days: _days,
        sessionInfo: daySchedules[0].sessionInfo,
      },
    ];
    return daySchedules;
  } else {
    const existingDays = new Set(
      daySchedules.flatMap((schedule: DaySchedule) => schedule.days)
    );

    // Filter out schedules that are no longer in the days list
    daySchedules = daySchedules.filter((schedule: DaySchedule) =>
      days.some((day: WeekDaysType) => schedule.days?.includes(day.value))
    );

    // Add missing schedules for new days
    days.forEach((day: WeekDaysType) => {
      if (!existingDays.has(day.value)) {
        daySchedules.push({
          days: [day.value],
          sessionInfo: [
            {
              capacity: 0,
              durationSession: DefaultDuration,
              endTime: DefaultMaxTime,
              startTime: DefaultMinTime,
              id: 0,
            },
          ],
        });
      }
    });

    return daySchedules;
  }
};

export const MakeDaySchedules = (days: WeekDaysType[], isSetToAll: boolean) => {
  let daySchedules: DaySchedule[] = [];
  if (isSetToAll) {
    let _days: DayOfWeek[] = [];
    days.map((day: WeekDaysType) => _days.push(day.value));
    daySchedules = [
      {
        days: _days,
        sessionInfo: [
          {
            capacity: 0,
            durationSession: DefaultDuration,
            endTime: DefaultMaxTime,
            startTime: DefaultMinTime,
            id: 0,
          },
        ],
      },
    ];
    return daySchedules;
  } else {
    days.map((day: WeekDaysType) =>
      daySchedules.push({
        days: [day.value],
        sessionInfo: [
          {
            capacity: 0,
            durationSession: DefaultDuration,
            endTime: DefaultMaxTime,
            startTime: DefaultMinTime,
            id: 0,
          },
        ],
      })
    );

    return daySchedules;
  }
};

export const AddSessoionToDaySchedule = (
  daySchedules: DaySchedule[],
  days: DayOfWeek[]
) => {
  return daySchedules.map((item: DaySchedule) =>
    item.days === days
      ? {
          ...item,
          sessionInfo: [
            ...(item.sessionInfo || []), // Spread existing sessionInfo or an empty array if undefined
            {
              capacity: 0,
              durationSession: DefaultDuration,
              endTime: DefaultMaxTime,
              startTime: DefaultMinTime,
              id: GetNewId(item.sessionInfo ?? []),
            },
          ],
        }
      : item
  );
};

export const AddBuildingServiceInfo = async (serviceInfo: BuildingServices) => {
  const resultModel: IServiceResult<BuildingServices | null> = {
    message: "",
    data: null,
  };
  const addServiceResult: ApiResponse<BuildingServices> = await AddService(
    serviceInfo
  );
  if (addServiceResult.responseStatus == ResponseCode.Success) {
    resultModel.data = addServiceResult.data;
  }

  return resultModel;
};
