import React from "react";
import { DashboardContainer } from "../../../../tools/container/customeContainer/DashboardContainer";
import DashboardBody from "../../dashboardBody/DashboardBody";
import ComplaintInfoContainer from "./complaintInfo/ComplaintInfoContainer";

const ComplaintInfo = () => {
  return (
    <DashboardContainer>
      <DashboardBody>
        <ComplaintInfoContainer />
      </DashboardBody>
    </DashboardContainer>
  );
};

export default ComplaintInfo;
