import React, { ReactNode } from "react";
import Style from "./MessagePopUp.module.scss";

interface Props {
  icon?: ReactNode;
  title?: string;
  description?: string | ReactNode;
  buttons: ReactNode;
  className?: string;
}
const MessagePopUp: React.FC<Props> = ({
  icon,
  title,
  description = "",
  buttons,
  className = "",
}) => {
  return (
    <div className={`${Style.popup} ${className}`}>
      <div className={Style.popup_box}>
        <h3 className={Style.popup_header}>
          {icon}
          {title}
        </h3>
        <p className={Style.popup_description}>{description}</p>
        <div className={Style.popup_box_button}>{buttons}</div>
      </div>
    </div>
  );
};

export default MessagePopUp;
