import { Grid } from "@mui/material";
import React from "react";
import TextField from "../../../../../../../tools/fields/textField/TextField";
import PhoneNumberValidation from "../../../../../../../tools/fields/phoneNumberField/PhoneNumberField";
import Style from "./UserInfo.module.scss";
import useWindowSize from "../../../../../../../hook/WindowSize";
import { MediumDeviceTrigger } from "../../../../../../../Setting";
import { useTranslation } from "react-i18next";
import { CountryType } from "../../../../../../../viewModels/dataTypes/country/Country";
import { FieldState } from "../../../../../../../viewModels/enums/PublicEnum";
import DashboardFormTitle from "../../../../../../../tools/dashboardTitle/dashboardFormTitle/DashboardFormTitle";
import { Profile } from "iconsax-react";
import FirstLogin from "../../../firstLogin/FirstLogin";

interface Props {
  firstName: string;
  lastName: string;
  phoneNumber: string;
  country: CountryType | null;
  email: string;
  userFirstLogin :boolean
}
const UserInfo: React.FC<Props> = ({
  firstName,
  lastName,
  phoneNumber,
  email,
  country,
  userFirstLogin
}) => {
  const windowSize = useWindowSize();
  const { t: translate } = useTranslation();
  const updateUserInfo = () => {};
  return (
    <div className={Style.user_info}>
      <DashboardFormTitle
        title={translate("TITLES.USER_INFO")}
        icon={<Profile className={Style.units_icon} />}
        className={Style.unit_title}
        children={
          <>
            <FirstLogin isFirstLogin={userFirstLogin} />
          </>
        }
      />
      <Grid
        className={Style.user_info_container}
        container
        columnSpacing={windowSize.width > MediumDeviceTrigger ? 8 : 6}
      >
        <Grid item md={6} sm={12} xs={12}>
          <TextField
            value={email}
            label={translate("LABELS.EMAIL")}
            requiredField
            readOnly={true}
          />
        </Grid>
        <Grid item md={6} sm={12} xs={12}>
          <PhoneNumberValidation
            value={phoneNumber}
            label={translate("LABELS.PHONENUMBER")}
            defaultCountry={country?.symbol}
            readOnly={true}
            requiredField
            onChange={updateUserInfo}
            fieldState={FieldState.Default}
          />
        </Grid>
        <Grid item md={6} sm={12} xs={12}>
          <TextField
            errorMessage={""}
            value={firstName}
            label={translate("LABELS.FIRSTNAME")}
            readOnly={true}
          />
        </Grid>

        <Grid item md={6} sm={12} xs={12}>
          <TextField
            value={lastName}
            label={translate("LABELS.LASTNAME")}
            readOnly={true}
          />
        </Grid>
      </Grid>
    </div>
  );
};

export default UserInfo;
