import { Grid } from "@mui/material";
import Style from "../AnnouncementPopup.module.scss";
import { Dispatch, SetStateAction, useRef, useState } from "react";
import { AttachmentsViewModel } from "../../../../../viewModels/dataTypes/AttachmentsViewModels";
import {
  FormatAcceptUpload,
} from "../../../../../Setting";
import SwiperComponent from "../../../../../tools/slider/swiperComponent/SwiperComponent";
import UploadImage from "../../../../../tools/uploadImage/UploadImage";

interface Props {
  setAnnouncementAttachment: Dispatch<SetStateAction<AttachmentsViewModel[]>>;
  announcementAttachment: AttachmentsViewModel[];
}
const SliderNewAnouncement: React.FC<Props> = ({
  announcementAttachment,
  setAnnouncementAttachment,
}) => {
  const [uploadDisabled, setUploadDisabled] = useState<boolean>(false);
  const [showErrorUpload, setShowErrorUpload] = useState<boolean>(false);
  const fileInputRef = useRef<HTMLInputElement>(null);

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files: FileList | null = event.target.files;
    if (files && files.length > 0) {
      const file: File = files[0];

      // Check file size
      if (file.size > 1 * 1024 * 1024) {
        setShowErrorUpload(true);
        return;
      }
      setShowErrorUpload(false);

      const fileName = file.name;
      const fileExtension = fileName.substring(fileName.lastIndexOf(".") + 1);

      const fileNameWithoutExtension =
        fileName.substring(0, fileName.lastIndexOf(".")) + ".";

      if (announcementAttachment.length < 5) {
        const reader = new FileReader();
        reader.onloadend = () => {
          const base64Data = reader.result as string;
          const newAttachment: AttachmentsViewModel = {
            filePath: base64Data,
            fileType: file.type,
            fileName: fileNameWithoutExtension,
            fileExtension: fileExtension,
          };

          setAnnouncementAttachment(
            (prevAttachments: AttachmentsViewModel[]) => [
              ...prevAttachments,
              newAttachment,
            ]
          );

          if (announcementAttachment.length + 1 >= 5) {
            setUploadDisabled(true);
          }

          if (fileInputRef.current) {
            fileInputRef.current.value = "";
          }
        };

        reader.readAsDataURL(file);
      }
    }
  };
  const triggerFileUpload = () => {
    if (fileInputRef.current && !uploadDisabled) {
      fileInputRef.current.click();
    }
  };
  return (
    <Grid container>
      <Grid item xs={12} className={Style.slider_container}>
        <UploadImage
          uploadDisabled={uploadDisabled}
          showErrorUpload={showErrorUpload}
          triggerFileUpload={triggerFileUpload}
        />
        <SwiperComponent
          isUpload={true}
          attachments={announcementAttachment}
          setAnnouncementAttachment={setAnnouncementAttachment}
          setUploadDisabled={setUploadDisabled}
        />
        <input
          type="file"
          ref={fileInputRef}
          accept={FormatAcceptUpload}
          onChange={handleFileChange}
        />
      </Grid>
    </Grid>
  );
};

export default SliderNewAnouncement;
