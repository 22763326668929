import React, { useEffect, useState } from "react";
import TicketsList from "./ticketsList/TicketsList";
import { useDispatch, useSelector } from "react-redux";
import { AppThunkDispatch } from "../../../../redux/Store";
import Loading from "../../../../tools/loading/Loading";
import { DashboardContainer } from "../../../../tools/container/customeContainer/DashboardContainer";
import DashboardBody from "../../dashboardBody/DashboardBody";
import { GetBuildingTickets } from "../../../../redux/slices/TicketsSlice";

const Tickets = () => {
  const dispatch = useDispatch<AppThunkDispatch>();
  const [preload, setPreload] = useState<boolean>(true);
  const tickets = useSelector((state: any) => state.ticketList.tickets);

  useEffect(() => {
    dispatch(GetBuildingTickets());
  }, [dispatch]);

  useEffect(() => {
    const getTickets = async () => {
      setPreload(true);
      await dispatch(GetBuildingTickets());
      setPreload(false);
    };
    getTickets();
  }, []);

  return (
    <>
      <DashboardContainer>
        <DashboardBody>
          <>
            {preload ? (
              <Loading />
            ) : (
              <TicketsList ticketsList={tickets || []} />
            )}
          </>
        </DashboardBody>
      </DashboardContainer>
    </>
  );
};

export default Tickets;
