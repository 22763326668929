import React, { useEffect, useState } from "react";
import Style from "./ImageBoxSlider.module.scss";
import { Swiper, SwiperClass, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper/modules";
import ArrowBackIosOutlinedIcon from "@mui/icons-material/ArrowBackIosOutlined";
import ArrowForwardIosOutlinedIcon from "@mui/icons-material/ArrowForwardIosOutlined";
import {
  GetFileName,
  GetShortFileName,
} from "../../../services/commonServices/filesCommonServices/FilesCommonServices";
import DownloadButton from "../../button/DownloadButton";
import { Attachments } from "../../../viewModels/api/Models";
import { SpecialZoomLevel, Viewer, Worker } from "@react-pdf-viewer/core";
import { WorkerUrl } from "../../../Setting";
import { AttachmentsViewModel } from "../../../viewModels/dataTypes/AttachmentsViewModels";
import { ChevronLeftOutlined } from "@mui/icons-material";

interface Props {
  currentImage: string;
  imagesList: Attachments[] | null | undefined;
  onClose: () => void;
}

const ImagePdfBoxSlider: React.FC<Props> = ({
  currentImage,
  imagesList,
  onClose,
}) => {
  const [swiper, setSwiper] = useState<SwiperClass | null>(null);
  const [, setCurrentSlideIndex] = useState<number>(0);
  const [isBeginning, setIsBeginning] = useState<boolean>(true);
  const [isEnd, setIsEnd] = useState<boolean>(false);

  const isPdfFile: (filePath: string) => boolean = (filePath: string) => {
    return filePath.startsWith("data:application/pdf;base64,");
  };

  useEffect(() => {
    const index: number | undefined = imagesList?.findIndex(
      (url: Attachments) => url.filePath === currentImage
    );

    if (index !== -1) {
      if (swiper) {
        swiper.slideTo(index!);
        setCurrentSlideIndex(index!);
      }
    }
  }, [currentImage, swiper, imagesList]);

  useEffect(() => {
    if (swiper) {
      swiper.on("slideChange", () => {
        setCurrentSlideIndex(swiper.activeIndex);
        setIsBeginning(swiper.isBeginning);
        setIsEnd(swiper.isEnd);
      });
    }
  }, [swiper]);

  const handlePrevClick = () => {
    if (swiper && !isBeginning) {
      swiper.slidePrev();
    }
  };

  const handleNextClick = () => {
    if (swiper && !isEnd) {
      swiper.slideNext();
    }
  };

  const hasMultipleImages = imagesList && imagesList.length > 1;

  return (
    <div className={Style.image_box}>
      <div>
        <div className={Style.image}>
          <>
            <Swiper
              effect="coverflow"
              grabCursor={true}
              centeredSlides={true}
              slidesPerView={1}
              coverflowEffect={{
                rotate: 0,
                stretch: 0,
                depth: 100,
                modifier: 1,
                slideShadows: false,
              }}
              navigation={true}
              modules={[Navigation]}
              className={Style.slider}
              onSwiper={setSwiper}
            >
              {imagesList?.map((url: AttachmentsViewModel, index: number) => {
                const isPdf = isPdfFile(url.filePath || "");
                return (
                  <SwiperSlide key={index}>
                    <div className={Style.slider_container}>
                      <div className={Style.slide_info}>
                        <div className={Style.image_info}>
                          <ChevronLeftOutlined onClick={onClose} />
                          <span>
                            {GetShortFileName(
                              GetFileName(url.filePath ?? ""),
                              20
                            )}
                          </span>
                        </div>
                        <div
                          className={`${Style.image_box_calltoaction} ${Style.image_box_calltoaction_pdf}`}
                        >
                          <DownloadButton
                            fileName={GetFileName(url.filePath ?? "")}
                            fileUrl={url.filePath ?? ""}
                          />
                        </div>
                      </div>
                      <div className={Style.slider_item}>
                        {isPdf ? (
                          <Worker workerUrl={WorkerUrl}>
                            <div className={Style.pdf_preview}>
                              <Viewer
                                defaultScale={SpecialZoomLevel.PageWidth}
                                fileUrl={url.filePath || ""}
                                initialPage={0}
                              />
                            </div>
                          </Worker>
                        ) : (
                          <img
                            src={url.filePath ?? ""}
                            alt={`slide-${index}`}
                          />
                        )}
                      </div>
                    </div>
                  </SwiperSlide>
                );
              })}
            </Swiper>
            {hasMultipleImages && (
              <>
                <div
                  className={`${Style.slider_icon} ${Style.prev} ${
                    isBeginning ? Style.disabled : ""
                  } swiper-button-prev`}
                  onClick={handlePrevClick}
                >
                  <ArrowBackIosOutlinedIcon />
                </div>
                <div
                  className={`${Style.slider_icon} ${Style.next} ${
                    isEnd ? Style.disabled : ""
                  } swiper-button-next`}
                  onClick={handleNextClick}
                >
                  <ArrowForwardIosOutlinedIcon />
                </div>
              </>
            )}
          </>
        </div>
      </div>
    </div>
  );
};

export default ImagePdfBoxSlider;
