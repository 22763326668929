import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import BeforeDashboardForm from "../BeforeDashboardForm";
import BuildingInfo from "./buildingInfo/BuildingInfo";
import PlansSection from "./items/PlansSection";
import Button from "../../../tools/button/Button";
import { ButtonState } from "../../../viewModels/enums/ButtonEnum";
import Style from "./Pricing.module.scss";
import {
  CheckCreateBuildingInfo,
  ChoosePlanService,
  GetPlans,
} from "./Pricing.Service";
import { PricingItem } from "../../../viewModels/dataTypes/plan/Pricing";
import { GetUserTokenInfo } from "../../../services/commonServices/CommonServices";
import { useNavigate } from "react-router-dom";
import { GetCities, GetProvince } from "./buildingInfo/BuildingInfo.Service";
import { ICity } from "../../../viewModels/dataTypes/country/Country";
import Loading from "../../../tools/loading/Loading";
import { ResponseCode } from "../../../viewModels/enums/ResponseCode";
import { UserTokenModel } from "../../../viewModels/dataTypes/registration/TokenViewModel";
import { DefaultPlan } from "../../../Setting";
import { ChoosePlanToCreateBuildingViewModel } from "../../../viewModels/api/Models";

const Pricing: React.FC = () => {
  const { t: translate } = useTranslation();
  const navigate = useNavigate();

  const [plans, setPlans] = useState<PricingItem[] | null>();
  const [provinces, setProvinces] = useState<ICity[]>();
  const [cities, setCities] = useState<ICity[]>();
  const [selectedProvince, setSelectedProvince] = useState<ICity | null>(null);
  const [selectedCity, setSelectedCity] = useState<ICity | null>(null);
  const [selectedPlan, setSelectedPlan] = useState<PricingItem>();
  const [preload, setPreload] = useState(true);
  const [buildingName, setBuildingName] = useState<string>("");
  const [buttonState, setButtonState] = useState(ButtonState.disable);
  const [userToken, setUserToken] = useState<UserTokenModel>();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const loadData = async () => {
      const plans = await GetPlans();
      var selectedPlan = plans?.find((a) => a.title == DefaultPlan);
      setSelectedPlan(selectedPlan);
      const userTokenInfo = GetUserTokenInfo();
      if (userTokenInfo != null) {
        setUserToken(userTokenInfo);
        const provinces = await GetProvince(userTokenInfo.userCountry ?? 0);
        setProvinces(provinces ?? []);
      } else {
        navigate("/");
      }
      setPlans(plans);
      setPreload(false);
    };
    loadData();
  }, []);

  const onChnageProvince = (province: ICity | null) => {
    setCities([]);
    setSelectedCity(null);
    setSelectedProvince(province);
    getCitiesOfProvince(province?.province ?? "");
  };

  const getCitiesOfProvince = async (province: string) => {
    const cities = await GetCities(province);
    setCities(cities ?? []);
    const checkInfo = CheckCreateBuildingInfo(
      selectedCity?.id ?? 0,
      province,
      buildingName,
      selectedPlan?.id ?? 0
    );
    changeButtonStateHandler(checkInfo);
  };

  const onChnageCity = (city: ICity | null) => {
    setSelectedCity(city);
    const checkInfo = CheckCreateBuildingInfo(
      city?.id ?? 0,
      selectedProvince?.province ?? "",
      buildingName,
      selectedPlan?.id ?? 0
    );
    changeButtonStateHandler(checkInfo);
  };

  const onChangeBuildingName = (buildingName: string) => {
    setBuildingName(buildingName);

    const checkInfo = CheckCreateBuildingInfo(
      selectedCity?.id ?? 0,
      selectedProvince?.province ?? "",
      buildingName,
      selectedPlan?.id ?? 0
    );
    changeButtonStateHandler(checkInfo);
  };

  const onSelectPlan = (plan: PricingItem) => {
    setSelectedPlan(plan);
    const checkInfo = CheckCreateBuildingInfo(
      selectedCity?.id ?? 0,
      selectedProvince?.province ?? "",
      buildingName,
      plan.id
    );
    changeButtonStateHandler(checkInfo);
  };

  const changeButtonStateHandler = (checkInfo: boolean) => {
    if (checkInfo) {
      setButtonState(ButtonState.default);
    } else {
      setButtonState(ButtonState.disable);
    }
  };

  const choosePlan = async () => {
    if (selectedPlan?.price === 0) {
      setLoading(true);
      const choosePlanInfo: ChoosePlanToCreateBuildingViewModel = {
        newBuilding: {
          buildingName: buildingName,
          cityId: selectedCity?.id ?? 0,
          userId: userToken?.userId ?? "",
        },
        planId: selectedPlan.id,
        planDuration: selectedPlan.planDuration,
      };
      const choosePlanResult = await ChoosePlanService(choosePlanInfo);
      if (choosePlanResult.data?.status == ResponseCode.Accepted) {
        navigate("/dashboard/dashboard");
      } else {
        //system error - should be update
      }
    } else {
      navigate("/factorDetail");
    }
  };

  return (
    <>
      {preload ? (
        <Loading />
      ) : (
        <BeforeDashboardForm
          title={translate("TITLES.CHOOSE_PLAN")}
          md={992}
          backButton={false}
          body={
            <div className="mt-3">
              <BuildingInfo
                buildingNameChange={onChangeBuildingName}
                provinces={provinces ?? []}
                cities={cities ?? []}
                provinceChange={onChnageProvince}
                cityChange={onChnageCity}
                cityValue={selectedCity}
                provinceValue={selectedProvince}
              />
              <PlansSection items={plans} onChange={onSelectPlan} />
              <div className={Style.button}>
                <Button
                  clickMethod={choosePlan}
                  text={translate("BUTTON.NEXT")}
                  state={buttonState}
                />
              </div>
              {loading && <Loading />}
            </div>
          }
        />
      )}
    </>
  );
};

export default Pricing;
