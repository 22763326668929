import React from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { MenuItemsViewModel } from "../../../../viewModels/dataTypes/tools/MenuItemsViewModel";
import Style from "./MenuItem.module.scss";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { AppThunkDispatch, RootState } from "../../../../redux/Store";
import {
  setShowPopup,
  setHasUnsavedChanges,
} from "../../../../redux/slices/UnsavedChangesSlice";
import Button from "../../../../tools/button/Button";
import { InfoCircle } from "iconsax-react";
import MessagePopUp from "../../../../tools/popup/MessagePopUp";
import { ButtonTheme } from "../../../../viewModels/enums/ButtonEnum";
import { SizeType } from "../../../../viewModels/dataTypes/tools/ListComponentType";

interface Props {
  item: MenuItemsViewModel;
  selectedItemPath: string;
  onSubMenuClick: (path: string) => void;
}

const SubMenu: React.FC<Props> = ({
  item,
  selectedItemPath,
  onSubMenuClick,
}) => {
  const { t: translate } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch<AppThunkDispatch>();
  const hasUnsavedChanges = useSelector(
    (state: RootState) => state.unsavedChanges.hasUnsavedChanges
  );
  const showPopup = useSelector((state: any) => state.unsavedChanges.showPopup);

  const handleSubMenuClick = (e: React.MouseEvent) => {
    if (hasUnsavedChanges) {
      e.preventDefault();
      dispatch(setShowPopup(true));
      onSubMenuClick(item.path);
    } else {
      navigate(item.path);
    }
  };

  const discardHandler = () => {
    dispatch(setShowPopup(false));
    dispatch(setHasUnsavedChanges(false));
    navigate(selectedItemPath);
  };

  return (
    <>
      <li className={`${Style.menu_item} ${Style.sub_menu}`} key={item.path}>
        <NavLink
          className={({ isActive }) => (isActive ? Style.item_menu_active : "")}
          to={item.path}
          onClick={handleSubMenuClick}
        >
          {item.icon}
          {translate(item.titr)}
        </NavLink>
      </li>
      {/* Handle Popup for SubMenu */}
      {showPopup && selectedItemPath === item.path ? (
        <MessagePopUp
          icon={<InfoCircle />}
          title={translate("SHARED.LEAVE_PAGE")}
          description={translate("ALERT.LEAVE_PAGE")}
          buttons={
            <>
              <Button
                className={Style.cancel_button}
                text={translate("BUTTON.CANCEL")}
                size={SizeType.small}
                clickMethod={() => dispatch(setShowPopup(false))}
              />
              <Button
                className={Style.cancel_button}
                text={translate("BUTTON.DISCARD")}
                theme={ButtonTheme.primaryReverse}
                size={SizeType.small}
                clickMethod={discardHandler}
              />
            </>
          }
        />
      ) : null}
    </>
  );
};

export default SubMenu;
