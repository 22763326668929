import React from "react";
import { DashboardContainer } from "../../../../tools/container/customeContainer/DashboardContainer";
import DashboardBody from "../../dashboardBody/DashboardBody";
import AddResident from "./addUser/AddResident";
import { ResidentType } from "../../../../viewModels/enums/ResidentType";

interface Props {
  residentType: ResidentType;
}
const NewResidentPage: React.FC<Props> = ({ residentType }) => {
  return (
    <DashboardContainer>
      <DashboardBody>
        <AddResident residentType={residentType} />
      </DashboardBody>
    </DashboardContainer>
  );
};

export default NewResidentPage;
