import React, { ReactNode } from "react";
import Style from "./Filter.module.scss";
import { FilterSearch, Maximize4 } from "iconsax-react";
import useWindowSize from "../../../hook/WindowSize";
import FilterSearchComponent from "./filterSearch/FilterSearch";
import FilterItems from "./filterItems/FilterItems";
import CloseIcon from "@mui/icons-material/Close";
import { SmallDeviceTrigger } from "../../../Setting";

interface Props {
  filterItems: ReactNode;
  filterItemsDate?: ReactNode;
  onSearch: (value: string) => void;
  onClose: () => void;
  valueSearch: string;
  isActiveCloseIcon: boolean;
}

const Filter: React.FC<Props> = ({
  filterItems,
  filterItemsDate,
  onSearch,
  valueSearch,
  onClose,
  isActiveCloseIcon,
}) => {
  const windowSize = useWindowSize();
  const closeFilterBox = () => {
    onClose();
  };
  return (
    <div className={Style.filter_box}>
      <div className={Style.filter_icons}>
        <div className={Style.filterItems}>
          <FilterSearch className={Style.filter_search_icon} />
          <div>
            <FilterSearchComponent
              valueSearch={valueSearch}
              onSearch={onSearch}
            />
            <div>
              <FilterItems
                filterItems={filterItems}
                filterItemsDate={filterItemsDate}
              />
              <CloseIcon
                onClick={closeFilterBox}
                className={`${Style.closeIcon} ${
                  isActiveCloseIcon ? Style.active : ""
                }`}
              />
            </div>
          </div>
        </div>
        {windowSize.width > SmallDeviceTrigger ? (
          <div className={Style.fullscreen_table}>
            <Maximize4 className={Style.fullscreen_icon} />
          </div>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
};

export default Filter;
