import {
  ActionReducerMapBuilder,
  PayloadAction,
  createAsyncThunk,
  createSlice,
} from "@reduxjs/toolkit";
import { IServiceResult } from "../../viewModels/api/Api";
import { IBuildingServicesReducer } from "../../viewModels/reduxInterfaces/ReduxInterfaces";
import { BuildingServices } from "../../viewModels/api/Models";
import { GetServicesItems } from "../../components/dashboard/dashboardContents/services/buildingServices/servicesComponent/buildingServicesList/BuildingServicesList.service";

const initialState = {
  services: [],
  loading: true,
  fill: false,
  err: "",
} as IBuildingServicesReducer;

const BuildingServicesSlice = createSlice({
  name: "buildingServices",
  initialState,
  reducers: {},
  extraReducers: (
    builder: ActionReducerMapBuilder<IBuildingServicesReducer>
  ) => {
    builder.addCase(GetBuildingServices.pending, (State) => {
      State.loading = true;
    });
    builder.addCase(
      GetBuildingServices.fulfilled,
      (State, action: PayloadAction<BuildingServices[]>) => {
        State.services = action.payload;
        State.loading = false;
        State.fill = true;
      }
    );
    builder.addCase(GetBuildingServices.rejected, (State) => {
      State.loading = false;
      State.err = "Loading Faild";
    });
  },
});

export const GetBuildingServices = createAsyncThunk(
  "GetBuildingServices",
  async ({
    buildingId,
    categoryId,
  }: {
    buildingId: number;
    categoryId: number;
  }) => {
    try {
      const BuildingServices: IServiceResult<BuildingServices[] | null> =
        await GetServicesItems(buildingId, categoryId);
      if (BuildingServices.data) {
        return BuildingServices.data;
      } else {
        return [];
      }
    } catch (err) {
      return [];
    }
  }
);

export default BuildingServicesSlice.reducer;
