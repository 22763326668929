import React, { useEffect, useState } from "react";
import { DashboardContainer } from "../../tools/container/customeContainer/DashboardContainer";
import DashboardBody from "./dashboardBody/DashboardBody";
import PricingSection from "./dashboardContents/pricingSection/PricingSection";
import { useDispatch, useSelector } from "react-redux";
import { IReducer } from "../../viewModels/reduxInterfaces/ReduxInterfaces";
import { AppThunkDispatch } from "../../redux/Store";
import { GetUserDataAction } from "../../redux/slices/UserSlice";
import { GetUserTokenInfo } from "../../services/commonServices/CommonServices";
import { PricingTypeEnum } from "../../viewModels/enums/PricingType";
import Loading from "../../tools/loading/Loading";
import { UserCredentialInfoViewModel } from "../../viewModels/api/Models";
import { useLocation, useNavigate } from "react-router-dom";

const Dashboard = () => {
  const dispatch = useDispatch<AppThunkDispatch>();
  const userCredential = useSelector((state: IReducer) => state.user);
  const location = useLocation();
  const navigate = useNavigate();

  const [userCredentialInfo, setUserCredentialInfo] =
    useState<UserCredentialInfoViewModel | null>(null);

  useEffect(() => {
    const handlePopState = () => {
      if (window.location.pathname === "/dashboard/dashboard") {
        navigate("/login", { replace: true });
      }
    };

    if (location.pathname === "/dashboard/dashboard") {
      window.history.pushState(null, "", window.location.pathname);
      window.addEventListener("popstate", handlePopState);
    }
    return () => {
      window.removeEventListener("popstate", handlePopState);
    };
  }, [location.pathname, navigate]);

  useEffect(() => {
    if (userCredential.userLogin) {
      setUserCredentialInfo(userCredential.user);
    } else {
      var userToken = GetUserTokenInfo();
      dispatch(GetUserDataAction(userToken?.userId ?? ""));
    }
  }, [userCredential]);

  return (
    <DashboardContainer>
      {userCredential.userLogin ? (
        <DashboardBody>
          <>
            {userCredentialInfo?.planTitle == PricingTypeEnum.Trial ? (
              <>
                <PricingSection
                  expireDate={userCredentialInfo.expireDate ?? new Date()}
                />
              </>
            ) : (
              <></>
            )}
          </>
        </DashboardBody>
      ) : (
        <Loading />
      )}
    </DashboardContainer>
  );
};

export default Dashboard;
