import { styled } from "@mui/material/styles";
import Menu, { MenuProps } from "@mui/material/Menu";
import Style from "./IconDropDown.module.scss";

export const StyledMenu = styled((props: MenuProps) => (
  <Menu
    MenuListProps={{
      classes: { root: Style.icons_list },
    }}
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 200,
    boxShadow: "0px 1px 1.5px 0px rgba(0, 0, 0, 0.2)",
  },
}));
