import { MaximumFileSize } from "../../Setting";
import { UploadFile } from "../../services/api/File";
import { IServiceResult } from "../../viewModels/api/Api";
import { IFile, IFileReader } from "../../viewModels/dataTypes/File";
import { FilesUploadType } from "../../viewModels/dataTypes/tools/FilesUploadType";
import { ResponseCode } from "../../viewModels/enums/ResponseCode";

export const FileUploadService = async (file: File | null) => {
  var resultModel: IServiceResult<IFile> = {
    message: "",
  };

  if (file != null) {
    var result = await UploadFile(file);
    if (result.responseStatus == ResponseCode.Success) {
      resultModel.data = result.data;
    } else {
      resultModel.data = null;
      resultModel.message = "ERRORS.OPERATION_FAILD";
    }
  } else {
    resultModel.data = null;
  }
  return resultModel;
};

export const CheckFileService = (
  file: File | null,
  fileType: FilesUploadType,
  dimensions?: number
): Promise<IServiceResult<IFileReader>> => {
  return new Promise((resolve) => {
    const resultModel: IServiceResult<IFileReader> = {
      message: "",
      data: null,
    };

    if (!file) {
      resultModel.message = "ERRORS.FILE_SIZE";
      resolve(resultModel);
      return;
    }

    if (file.size > MaximumFileSize) {
      resultModel.message = "ERRORS.FILE_SIZE";
      resolve(resultModel);
      return;
    }

    const reader = new FileReader();
    reader.onloadend = (e) => {
      if (e.target && e.target.result && fileType === FilesUploadType.image) {
        const imageDataUrl = reader.result as string;
        if (dimensions != null) {
          const img = new Image();
          img.onload = () => {
            if (img.width > dimensions || img.height > dimensions) {
              resultModel.message = "ERRORS.FILE_DIMENTIONS";
              resolve(resultModel);
            } else {
              resultModel.data = {
                file: file,
                filePath: imageDataUrl,
              };
              resolve(resultModel);
            }
          };
          img.src = e.target.result.toString();
        } else {
          resultModel.data = {
            file: file,
            filePath: imageDataUrl,
          };
          resolve(resultModel);
        }
      } else {
        resultModel.message = "ERRORS.FILE_SIZE";
        resolve(resultModel);
      }
    };
    reader.readAsDataURL(file);
  });
};