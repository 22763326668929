import styled from "styled-components";
import IconWrapper from "../../../icons/CustomIcon";

export const IconContainer = styled.div<{
  isHovered: boolean;
  serviceIconColor?: string;
  isOdd: boolean;
}>`
  background-color: ${({ isHovered, serviceIconColor, isOdd }) =>
    isHovered
      ? serviceIconColor
      : isOdd
      ? "var(--bg-secondary)"
      : "var(--bg-gray-low)"};
  transition: background-color 0.3s;
  color: ${({ isHovered, serviceIconColor }) =>
    isHovered ? "var(--bg-secondary)" : serviceIconColor};
  transition: color 0.3s;
`;

export const IconWrapperStyled = styled(IconWrapper)<{
  isHovered: boolean;
  serviceIconColor?: string;
}>`
  color: ${({ isHovered, serviceIconColor }) =>
    isHovered ? "inherit" : serviceIconColor};
`;
