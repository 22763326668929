import Style from "./DashboardFormTitle.module.scss";
import React, { ReactNode } from "react";

interface Props {
  icon: ReactNode;
  title: string;
  className?: string;
  children?: ReactNode;
}
const DashboardFormTitle: React.FC<Props> = ({ title, icon, className , children}) => {
  return (
    <div className={`${Style.title} ${className}`}>
      {icon}
      <h4>{title}</h4>
      {children}
    </div>
  );
};

export default DashboardFormTitle;
