import ComplaintsStateComponent from "../../../../../tools/table/complaintsComponent/ComplaintsStateComponent";
import { ComplaintsViewModel } from "../../../../../viewModels/dataTypes/complaints/ComplaintsViewModel";
import {
  AlignmentEnum,
  HeadCellsType,
} from "../../../../../viewModels/dataTypes/DataTableType";

export const headCells: HeadCellsType<ComplaintsViewModel>[] = [
  {
    id: "number",
    label: "COMPLAINTS.NUMBER",
    align: AlignmentEnum.Left,
    sortable: true,
    specialComponent: null,
    cellSize: {
      lg: 56,
      md: 56,
      sm: 56,
    },
    isLink: true,
  },
  {
    id: "complaintNumber",
    label: "COMPLAINTS.COMPLAINTS_NUMBER",
    align: AlignmentEnum.Left,
    sortable: true,
    specialComponent: null,
    cellSize: {
      lg: 56,
      md: 56,
      sm: 56,
    },
    isLink: true,
  },
  {
    id: "subject",
    label: "COMPLAINTS.SUBJECTS",
    align: AlignmentEnum.Left,
    sortable: true,
    specialComponent: null,
    cellSize: {
      lg: 195,
      md: 150,
      sm: 150,
    },
    isLink: true,
  },
  {
    id: "unitNumber",
    label: "COMPLAINTS.REPORTED_BY",
    align: AlignmentEnum.Left,
    sortable: true,
    specialComponent: null,
    cellSize: {
      lg: 195,
      md: 150,
      sm: 120,
    },
    isLink: true,
  },
  {
    id: "createdDateTime",
    label: "COMPLAINTS.REPORTS_DATE",
    align: AlignmentEnum.Left,
    sortable: true,
    specialComponent: null,
    cellSize: {
      lg: 195,
      md: 150,
      sm: 120,
    },
    isLink: true,
  },
  {
    id: "lastUpdateDateTime",
    label: "COMPLAINTS.LAST_UPDATE",
    align: AlignmentEnum.Left,
    sortable: true,
    specialComponent: null,
    cellSize: {
      lg: 195,
      md: 150,
      sm: 120,
    },
    isLink: true,
  },
  {
    id: "status",
    label: "COMPLAINTS.STATE",
    align: AlignmentEnum.Left,
    sortable: false,
    specialComponent: ComplaintsStateComponent,
    specialComponentProps: ["status", "id"],
    cellSize: {
      lg: 120,
      md: 120,
      sm: 120,
    },
    isLink: false,
  },
];
