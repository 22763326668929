export const GetFileFormat = (path: string) => {
  const slices = path.split(".");
  return slices[slices.length - 1];
};

export const GetFileName = (path: string) => {
  const slices = path.split("/");
  return slices[slices.length - 1];
};

export const GetShortFileName = (fileName: string , length : number) => {
  if (fileName.length < length) {
    return fileName;
  } else {
    const visiblePart =
      fileName.slice(0, 6) + "... ." + GetFileFormat(fileName);
    return visiblePart;
  }
};
