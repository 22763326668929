import React, { useEffect, useState } from "react";
import DashboardFormTitle from "../../../../../../../tools/dashboardTitle/dashboardFormTitle/DashboardFormTitle";
import CalendarViewMonthIcon from "@mui/icons-material/CalendarViewMonth";
import Style from "./UnitInfo.module.scss";
import useWindowSize from "../../../../../../../hook/WindowSize";
import { Grid } from "@mui/material";
import { MediumDeviceTrigger } from "../../../../../../../Setting";
import AutoComplete from "../../../../../../../tools/dropDown/autoComplete/AutoComplete";
import { useTranslation } from "react-i18next";
import { GetBuildingUnitsService } from "./UnitInfo.Service";
import { useNavigate, useParams } from "react-router-dom";
import { IUnitInfo } from "../../../../../../../viewModels/dataTypes/building/IUnitInfo";
import { IsNullList } from "../../../../../../../services/commonServices/CommonServices";
import { CreateToast } from "../../../../../../../tools/toast/ToastService";
import { ToastType } from "../../../../../../../viewModels/enums/ToastType";

interface Props {
  onChangeUnit: (value: IUnitInfo | null) => void;
  selectedUnit : IUnitInfo | null;
}
const UnitInfo: React.FC<Props> = ({ onChangeUnit , selectedUnit }) => {
  const windowSize = useWindowSize();
  const { t: translate } = useTranslation();
  const { buildingId } = useParams();
  const navigate = useNavigate();
  const [unitInfo, setunitInfo] = useState<IUnitInfo[]>([]);

  useEffect(() => {
    const loadInfo = async () => {
      const unitInfoResult = await GetBuildingUnitsService(buildingId ?? "");

      if (!IsNullList(unitInfoResult?.data ?? null)) {
        setunitInfo(unitInfoResult?.data ?? []);
      } else {
        CreateToast(ToastType.Error, translate("ERRORS.NOT_FOUND_UNITS"), {
          onClose: goLastPage,
        });
      }
    };
    loadInfo();
  }, []);
  const goLastPage = () => {
    navigate(-1);
  };

  return (
    <div className={Style.unit}>
      <DashboardFormTitle
        title={translate("UNIT.UNITS_INFO")}
        icon={<CalendarViewMonthIcon className={Style.units_icon} />}
        className={Style.unit_title}
      />
      <Grid
        container
        className={Style.find_units_container}
        columnSpacing={windowSize.width > MediumDeviceTrigger ? 8 : 6}
      >
        <Grid item lg={6} md={6} sm={12} xs={12}>
          <AutoComplete
            items={unitInfo}
            label={translate("LABELS.UNIT_NUMBERS")}
            placeHolder={translate("PLACEHOLDER.UNIT_NUMBERS")}
            getOptionLabel={(unit: IUnitInfo) => unit.unitNumber.toString()}
            getRenderOption={(unit: IUnitInfo) => unit.unitNumber.toString()}
            onChange={onChangeUnit}
            value={selectedUnit}
            requiredField
            noOptionsText="ERRORS.NO_UNIT_FOUND"
          />
        </Grid>
      </Grid>
    </div>
  );
};

export default UnitInfo;
