import * as Icons from "@mui/icons-material";
import { BuildingServices } from "../../../../../../../viewModels/api/Models";
import {
  DeleteServices,
  GetServices,
} from "../../../../../../../services/api/BuildingServices";
import {
  ApiResponse,
  IServiceResult,
} from "../../../../../../../viewModels/api/Api";

export interface Sample {
  title: string;
  icon: keyof typeof Icons;
  color: string;
}

export const GetServicesItems = async (
  buildingId: number,
  categoryId: number
): Promise<IServiceResult<BuildingServices[] | null>> => {
  const resultModel: IServiceResult<BuildingServices[] | null> = {
    message: "",
    data: null,
  };

  const getServiceCategoriesResult: ApiResponse<BuildingServices[]> =
    await GetServices(buildingId, categoryId);
  resultModel.data = getServiceCategoriesResult.data;

  return resultModel;
};

export const DeleteServicesItems = async (
  serviceId: number
): Promise<IServiceResult<boolean>> => {
  const resultModel: IServiceResult<boolean> = {
    message: "",
    data: null,
  };
  const getServicesResult: ApiResponse<boolean> = await DeleteServices(
    Number(serviceId)
  );
  resultModel.data = getServicesResult.data;
  return resultModel;
};
