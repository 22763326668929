import React, { useEffect, useState } from "react";
import { TimerState } from "../../viewModels/enums/TimerState";
import Style from "./Timer.module.scss";
import { useTranslation } from "react-i18next";

interface Props {
  action?: (value?: any) => any;
}
const Timer: React.FC<Props> = ({ action }) => {
  const [timerValue, setTimerValue] = useState<number>(
    parseInt(localStorage.getItem("timerValue") || "59")
  );
  const [timerState, setTimerState] = useState<TimerState>(TimerState.Timer);
  const [timerText, setTimerText] = useState<string>("00:" + timerValue);
  useEffect(() => {
    UpdateTimer();
  }, [timerValue]);
  useEffect(() => {
    localStorage.setItem("timerValue", timerValue.toString());
  }, [timerValue]);
  const UpdateTimer = () => {
    if (timerState === TimerState.Timer) {
      timerValue > 0 &&
        setTimeout(() => {
          setTimerValue(timerValue - 1);
          if (timerValue < 10) {
            setTimerText("00:0" + timerValue);
          } else {
            setTimerText("00:" + timerValue);
          }
        }, 1000);
      if (timerValue === 0) {
        setTimeout(() => {
          setTimerState(TimerState.Resend);
        }, 1000);
      }
    }
  };
  const { t: translate } = useTranslation();
  return (
    <div className={`flex ${Style.otp_timer}`}>
      {timerState === TimerState.Resend ? (
        <a
          onClick={() => {
            setTimerValue(59);
            setTimerText("00:59");
            setTimerState(TimerState.Timer);
          }}
          className={Style.resend}
        >
          {action != null ? (
            <span onClick={action}>
              {translate("AUTHENTICATION.RESEND_CODE")}
            </span>
          ) : null}
        </a>
      ) : (
        <a>{`${timerText}`}</a>
      )}
    </div>
  );
};

export default Timer;
