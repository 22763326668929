import {
  FormControl,
  MenuItem,
  Select,
  SelectChangeEvent,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import Style from "./DropDownLabel.module.scss";
import { IDropDown } from "../../../viewModels/dataTypes/tools/DropDownItems";
import { SizeType } from "../../../viewModels/dataTypes/tools/ListComponentType";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import useDropdownPosition from "../../../hook/DropdownPosition";
import { useTranslation } from "react-i18next";
import useCloseOnResize from "../../../hook/CloseOnResize";
import PushPinIcon from "@mui/icons-material/PushPin";
import { LabelEnum } from "../../../viewModels/enums/LabelEnum";

interface Props {
  value: IDropDown | null;
  data: IDropDown[];
  onChange: (value: IDropDown | null) => void;
  label: string;
  size?: SizeType;
  placeholder?: string;
}

const DropDownLabel: React.FC<Props> = ({
  data,
  onChange,
  label,
  size = SizeType.large,
  value,
  placeholder,
}) => {
  const [selectedValue, setSelectedValue] = useState<IDropDown | null>(null);

  const { selectRef, menuPosition, calculatePosition } = useDropdownPosition();
  const [isOpen, setIsOpen] = useState(false);
  const handleChange = (event: SelectChangeEvent<string>) => {
    const {
      target: { value },
    } = event;

    const selectedItems: IDropDown | undefined = data.find((item: IDropDown) =>
      value.includes(item.value.toString())
    );
    setSelectedValue(selectedItems ?? null);
    onChange(selectedItems ?? null);
  };

  useEffect(() => {
    setSelectedValue(value);
  }, [value, data]);

  const { t: translate } = useTranslation();

  const openSelect = () => {
    setIsOpen(true);
    calculatePosition();
  };

  const closeSelect = () => {
    setIsOpen(false);
  };

  useCloseOnResize(closeSelect);

  // Function to get icon color based on LabelEnum value
  const getIconColor = (label: string) => {
    switch (label) {
      case LabelEnum.Notice:
        return "var(--icon-primary)";
      case LabelEnum.Warning:
        return "var(--icon-red)";
      case LabelEnum.Advertisement:
        return "var(--icon-green-high)";
      default:
        return "var(--icon-gray-low)";
    }
  };

  return (
    <div className={Style.dropdown_box}>
      <label
        className={`${Style.label} ${selectedValue ? Style.fill_field : ""}`}
      >
        <span>{label}</span>
      </label>
      <div
        className={`${Style.dropdown_items} ${
          size === SizeType.small ? Style.small_field : ""
        }`}
        ref={selectRef}
      >
        <FormControl className={Style.dropdown}>
          <Select
            IconComponent={ExpandMoreIcon}
            onChange={handleChange}
            onOpen={openSelect}
            onClose={closeSelect}
            open={isOpen}
            value={selectedValue?.value ?? ""}
            MenuProps={{
              classes: { paper: Style.dropdown_menu },
              anchorOrigin: {
                vertical: menuPosition === "top" ? "top" : "bottom",
                horizontal: "left",
              },
              transformOrigin: {
                vertical: menuPosition === "top" ? "bottom" : "top",
                horizontal: "left",
              },
            }}
            // Render selected value with icon
            renderValue={(selected: string) => {
              const selectedItem = data.find((item) => item.value === selected);
              if (!selectedItem) return null;

              return (
                <div className={Style.selected_value_container}>
                  <PushPinIcon
                    style={{
                      color: getIconColor(selectedItem.text),
                    }}
                  />
                  {translate(selectedItem.text)}
                </div>
              );
            }}
            {...(placeholder &&
              !selectedValue && {
                displayEmpty: true,
                renderValue: () => (
                  <div className={Style.placeholder_container}>
                    <PushPinIcon className={Style.icon_placeholder} />
                    {placeholder}
                  </div>
                ),
              })}
          >
            {data.map((item: IDropDown) => (
              <MenuItem
                className={Style.dropdown_item}
                value={item.value}
                key={item.value}
              >
                <PushPinIcon
                  style={{ color: getIconColor(item.text) }}
                  className={Style.icon}
                />
                {translate(item.text)}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>
    </div>
  );
};

export default DropDownLabel;
