import React, { useEffect, useState } from "react";
import { DashboardBox } from "../../../../../tools/box/DashboardBox";
import CustomizedTables from "../../../../../tools/table/Table";
import DashboardTitle from "../../../../../tools/dashboardTitle/DashboardTitle";
import { User } from "iconsax-react";
import { IBuildingUsers } from "../../../../../viewModels/dataTypes/user/IBuildingUsers";
import { FilterBuildingUsers, GetUserType } from "./UserList.Service";
import { useParams } from "react-router-dom";
import Loading from "../../../../../tools/loading/Loading";
import AddUser from "./addUser/AddUser";
import Filter from "../../../../../tools/table/filter/Filter";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { AppThunkDispatch } from "../../../../../redux/Store";
import {
  IBuildingInfoReducer,
  IReducer,
} from "../../../../../viewModels/reduxInterfaces/ReduxInterfaces";
import { GetBuildingInfoDataAction } from "../../../../../redux/slices/BuildingInfoSlice";
import FilterDropDown from "../../../../../tools/dropDown/filterDropDown/FilterDropDown";
import { FilterConditionsEnum } from "../../../../../viewModels/enums/FilterConditionsEnum";
import { BuildingUserAccess, headCells } from "./UserListMockData";
import Style from "./UserList.module.scss";
import { IDropDown } from "../../../../../viewModels/dataTypes/tools/DropDownItems";

interface Props {
  users: IBuildingUsers[];
}
const UsersList: React.FC<Props> = ({ users }) => {
  const { buildingId } = useParams();
  const [userFilterList, setUserFilterList] = useState<IBuildingUsers[]>([]);
  const [preload, setPreload] = useState<boolean>(true);
  const [buildingName, setBuildingName] = useState<string>("");
  const [userType, setUserType] = useState<string | null>(null);
  const [userPermission, setUserPermission] = useState<string | null>(null);
  const [userTypeFilterLogic, setUserTypeFilterLogic] = useState<string>(
    FilterConditionsEnum.OR
  );
  const [checkedItemsUserRole, setCheckedItemsUserRole] = useState<string[]>(
    []
  );
  const [checkedItemsUserPermition, setCheckedItemsUserPermition] = useState<
    string[]
  >([]);
  const [userRoleList, setUserRoleList] = useState<IDropDown[]>([]);
  const [searchText, setSearchText] = useState<string>("");
  const [userPermissionFilterLogic, setUserPermissionFilterLogic] =
    useState<string>(FilterConditionsEnum.OR);
  const [isActiveCloseIcon, setIsActiveCloseIcon] = useState<boolean>(false);
  const userBuildingInfo: IBuildingInfoReducer = useSelector(
    (state: IReducer) => state.buildingInfo
  );

  const dispatch = useDispatch<AppThunkDispatch>();

  useEffect(() => {
    const getBuildingInfo = async () => {
      if (userBuildingInfo.buildingInfo != null) {
        setBuildingName(userBuildingInfo.buildingInfo.buildingName);
      } else {
        dispatch(GetBuildingInfoDataAction(buildingId ?? ""));
      }
    };
    getBuildingInfo();
  }, [userBuildingInfo, dispatch, buildingId]);

  useEffect(() => {
    setUserFilterList(users);
    setPreload(false);
  }, [users]);

  useEffect(() => {
    const loadData = async () => {
      const buildingRoles = await GetUserType();
      if (buildingRoles.data) {
        setUserRoleList(buildingRoles.data);
      }
    };
    loadData();
  }, []);

  useEffect(() => {
    const dataFilter = FilterBuildingUsers(
      users,
      userPermission ?? "",
      userType ?? "",
      userPermissionFilterLogic,
      userTypeFilterLogic,
      searchText
    );
    setUserFilterList(dataFilter);
  }, [
    userType,
    userPermission,
    userPermissionFilterLogic,
    userTypeFilterLogic,
    searchText,
    users,
  ]);
  useEffect(() => {
    setIsActiveCloseIcon(
      checkedItemsUserPermition.length > 0 ||
        checkedItemsUserRole.length > 0 ||
        searchText.length > 0
    );
  }, [
    checkedItemsUserPermition.length,
    searchText.length,
    checkedItemsUserRole.length,
  ]);
  const resetFilters = () => {
    setSearchText("");
    setUserPermission(null);
    setUserType(null);
    setCheckedItemsUserRole([]); // Reset checked items
    setCheckedItemsUserPermition([]); // Reset checked items
    if (
      setCheckedItemsUserRole.length > 0 ||
      setCheckedItemsUserPermition.length ||
      searchText.length > 0
    ) {
      setUserTypeFilterLogic(FilterConditionsEnum.OR); // Reset checked radioButtons
      setUserPermissionFilterLogic(FilterConditionsEnum.OR); // Reset checked radioButtons
    }
  };
  const { t: translate } = useTranslation();
  return (
    <DashboardBox>
      <DashboardTitle
        icon={<User className={Style.user_icon} />}
        label={`${buildingName} ${translate("USERS.USERS")}`}
        button={<AddUser buildingId={buildingId ?? ""} />}
      />
      <Filter
        onSearch={setSearchText}
        onClose={resetFilters}
        valueSearch={searchText}
        isActiveCloseIcon={isActiveCloseIcon}
        filterItems={
          <>
            <FilterDropDown
              onChange={setUserType}
              onChangeConditionsLogic={setUserTypeFilterLogic}
              valueConditionsLogic={userTypeFilterLogic}
              items={userRoleList}
              label={translate("USERS.USERS")}
              checkedItems={checkedItemsUserRole}
              setCheckedItems={setCheckedItemsUserRole}
              showRadioButton={true}
            />
            <FilterDropDown
              onChange={setUserPermission}
              onChangeConditionsLogic={setUserPermissionFilterLogic}
              valueConditionsLogic={userPermissionFilterLogic}
              items={BuildingUserAccess}
              label={translate("USERS.PERMISSION")}
              checkedItems={checkedItemsUserPermition}
              setCheckedItems={setCheckedItemsUserPermition}
              showRadioButton={true}
            />
          </>
        }
      />
      {preload || userBuildingInfo.loading ? (
        <Loading />
      ) : (
        <>
          <CustomizedTables
            path={`/dashboard/building/users/${buildingId}/userInfo/`}
            tableData={userFilterList}
            tableHeaderItems={headCells}
            notFoundMessage={translate("ALERT.NOT_FOUND_USER")}
          />
        </>
      )}
    </DashboardBox>
  );
};

export default UsersList;
