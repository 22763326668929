import { CheckOtpCode } from "../../../services/api/User";
import { ResponseCode } from "../../../viewModels/enums/ResponseCode";
import { IServiceResult, ApiResponse } from "../../../viewModels/api/Api";
import { ICheckOtpResponse } from "../../../viewModels/dataTypes/registration/Otp";
import { OtpMessageGenerator } from "../../../services/commonServices/CommonServices";
import { CheckOtpRequestViewModel } from "../../../viewModels/api/Models";

// detect delivery method to check otp
export const CheckOtpService = async (
  otpInfo: CheckOtpRequestViewModel,
  setOtpDisabled?: React.Dispatch<React.SetStateAction<boolean>>
): Promise<IServiceResult<ICheckOtpResponse>> => {
  var checkOtpInfo: IServiceResult<ICheckOtpResponse> = {
    message: "",
    data: null,
  };
  var checkOtpApiResponse: ApiResponse<ICheckOtpResponse> = await CheckOtpCode(
    otpInfo
  );
  if (checkOtpApiResponse.data != null) {
    const isBlocked: boolean =
      checkOtpApiResponse.data.status === ResponseCode.BlockedOtpCode;
    if (setOtpDisabled) {
      setOtpDisabled(isBlocked);
    }
    localStorage.setItem("isOtpBlocked", JSON.stringify(isBlocked));
    localStorage.setItem("otpDisabled", JSON.stringify(isBlocked));
    if (checkOtpApiResponse.data.status === ResponseCode.Accepted) {
      checkOtpInfo.data = checkOtpApiResponse.data;
    } else {
      checkOtpInfo.message = OtpMessageGenerator(
        checkOtpApiResponse.data.status
      );
    }
  } else {
    checkOtpInfo.message = "ERRORS.OPERATION_FAILD";
  }
  return checkOtpInfo;
};
