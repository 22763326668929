import React, { useEffect, useState } from "react";
import { Trash } from "iconsax-react";
import { useTranslation } from "react-i18next";
import Style from "./BuildingDeleteService.module.scss";
import MessagePopUp from "../../../../../../../tools/popup/MessagePopUp";
import Button from "../../../../../../../tools/button/Button";
import {
  ButtonState,
  ButtonTheme,
} from "../../../../../../../viewModels/enums/ButtonEnum";
import { SizeType } from "../../../../../../../viewModels/dataTypes/tools/ListComponentType";

interface Props {
  serviceName: string;
  cancelDeleteHandler: () => void;
  deleteHandler: () => void;
  loading: boolean;
}
const BuildingDeleteService: React.FC<Props> = ({
  serviceName,
  cancelDeleteHandler,
  deleteHandler,
  loading = false,
}) => {
  const { t: translate } = useTranslation();
  const [buttonState, setButtonState] = useState<ButtonState>(
    ButtonState.disable
  );
  useEffect(() => {
    const changeStatePolice: boolean = loading;
    setButtonState(
      changeStatePolice ? ButtonState.disable : ButtonState.default
    );
  }, [loading]);
  return (
    <MessagePopUp
      icon={<Trash className={Style.delete_icon} />}
      title={translate("TITLES.DELETE_SERVICES")}
      description={
        <p>
          {translate("ALERT.DELETE_SERVICE_ALERT")}
          <span className={`${Style.service_name}`}>{serviceName}?</span>
        </p>
      }
      className={Style.delete_user_popup}
      buttons={
        <>
          <Button
            className={Style.delete_button}
            text={translate("BUTTON.DISCARD")}
            theme={ButtonTheme.deleteReverse}
            size={SizeType.small}
            clickMethod={cancelDeleteHandler}
          />
          <Button
            className={Style.delete_button}
            text={translate("BUTTON.DELETE")}
            theme={ButtonTheme.delete}
            size={SizeType.small}
            clickMethod={deleteHandler}
            state={buttonState}
          />
        </>
      }
    />
  );
};

export default BuildingDeleteService;
