import { ApiResponse } from "../../viewModels/api/Api";

import { CallApi } from "../CallApi";

import {
  BaseUrl,
  GetAllCountriesRequest,
  VerifyReCaptchaToken,
} from "../../text/Api";
import { ApiMethod } from "../../viewModels/enums/ApiMethod";
import { DefaultApiHeader } from "./ApiHeaders";
import { CountryType } from "../../viewModels/dataTypes/country/Country";
import { ReCaptachaViewModel } from "../../viewModels/dataTypes/TokenViewModel";
import { BodyOfGetRequest } from "../../Setting";

export const GetAllCountriesAction = async () => {
  const Url: string = BaseUrl + GetAllCountriesRequest;
  var raw = JSON.stringify({});
  var Result: ApiResponse<CountryType[]> = await CallApi(
    Url,
    raw,
    DefaultApiHeader,
    ApiMethod.Get
  );

  return Result.data;
};
export const VerifyCaptcha = async (token: string) => {
 
  var Result: ApiResponse<ReCaptachaViewModel> = await CallApi(
    BaseUrl + VerifyReCaptchaToken + token,
    BodyOfGetRequest,
    DefaultApiHeader,
    ApiMethod.Post
  );

  return Result.data?.success ?? false;
};
