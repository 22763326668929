import { useEffect, RefObject } from "react";

const usePreventContextMenu = (ref: RefObject<HTMLElement>) => {
  useEffect(() => {
    const handleContextMenu = (event: MouseEvent) => {
      event.preventDefault();
    };

    const element = ref.current;
    if (element) {
      element.addEventListener("contextmenu", handleContextMenu);

      return () => {
        element.removeEventListener("contextmenu", handleContextMenu);
      };
    }
  }, [ref]);
};

export default usePreventContextMenu;
