import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { UnsavedChangesState } from "../../viewModels/reduxInterfaces/ReduxInterfaces";

const initialState: UnsavedChangesState = {
  hasUnsavedChanges: false,
  showPopup: false,
};

const unsavedChangesSlice = createSlice({
  name: "unsavedChanges",
  initialState,
  reducers: {
    setHasUnsavedChanges: (
      state: UnsavedChangesState,
      action: PayloadAction<boolean>
    ) => {
      state.hasUnsavedChanges = action.payload;
    },
    setShowPopup: (
      state: UnsavedChangesState,
      action: PayloadAction<boolean>
    ) => {
      state.showPopup = action.payload;
    },
  },
});

export const { setHasUnsavedChanges } = unsavedChangesSlice.actions;
export const { setShowPopup } = unsavedChangesSlice.actions;
export default unsavedChangesSlice.reducer;
