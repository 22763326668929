import React, { ReactNode } from "react";
import Style from "./ServicePopUp.module.scss";

interface Props {
  children: ReactNode;
  icon?: ReactNode;
  title: string;
}
const ServicesPopUp: React.FC<Props> = ({ children, icon, title }) => {
  return (
    <div className={Style.popup_container}>
      <div className={Style.popup_box}>
        <div className={Style.popup_box_titr}>
          {icon}
          <span>{title}</span>
        </div>
        {children}
      </div>
    </div>
  );
};

export default ServicesPopUp;
