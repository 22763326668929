import { Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import ContainerStyle from "../ServiceInfoComponent.module.scss";
import { useTranslation } from "react-i18next";
import useWindowSize from "../../../../../../../../hook/WindowSize";
import {
  DefaultMaxTime,
  DefaultMinTime,
  MediumDeviceTrigger,
} from "../../../../../../../../Setting";
import TimePickerDropDown from "../../../../../../../../tools/timePicker/TimePickerDropDown";
import { IDropDown } from "../../../../../../../../viewModels/dataTypes/tools/DropDownItems";
import DropDown from "../../../../../../../../tools/dropDown/DropDown";
import TextField from "../../../../../../../../tools/fields/textField/TextField";
import {
  SessionServiceViewModel,
  DayOfWeek,
} from "../../../../../../../../viewModels/api/Models";
import { GetActiveDurations } from "./ServiceInfoTime.Service";
import CounterAvatar from "../../../../../../../../tools/counterAvatar/CounterAvatar";
import DeleteInfo from "../deleteInfo/DeleteInfo";
import Style from "./ServiceAddSession.module.scss";
import { NumberRegex } from "../../../../../../../../services/Regex";

interface Props {
  selectedDay: DayOfWeek[];
  sessionInfo: SessionServiceViewModel;
  onChange: (day: DayOfWeek[], value: SessionServiceViewModel) => void;
  onDelete: (day: DayOfWeek[], value: number) => void;
  index: number;
}
const ServiceInfoTime: React.FC<Props> = ({
  selectedDay,
  sessionInfo,
  onChange,
  index,
  onDelete,
}) => {
  const windowSize = useWindowSize();
  const { t: translate } = useTranslation();
  const [startTime, setStartTime] = useState<string | null>(null);
  const [endTime, setEndTime] = useState<string | null>(null);
  const [capacity, setCapacity] = useState<string>("");
  const [duration, setDuration] = useState<IDropDown | null>(null);
  const [durations, setDurations] = useState<IDropDown[]>([]);

  const changeStartTimeHandler = (value: string) => {
    let updatedValue = { ...sessionInfo, startTime: value };
    setStartTime(value);
    onChange(selectedDay, updatedValue);
  };

  const changeEndTimeHandler = (value: string) => {
    let updatedValue = { ...sessionInfo, endTime: value };
    setEndTime(value);
    onChange(selectedDay, updatedValue);
  };

  const changeDurationTimeHandler = (value: IDropDown | null) => {
    let updatedValue = {
      ...sessionInfo,
      durationSession: Number(value?.value),
    };
    setDuration(value);
    onChange(selectedDay, updatedValue);
  };

  const changeCapavityHandler = (value: string) => {
    const newValue = NumberRegex(value);
    let updatedValue = { ...sessionInfo, capacity: Number(newValue) };
    setCapacity(newValue);
    onChange(selectedDay, updatedValue);
  };

  // use effect to load session info
  useEffect(() => {
    //set duration time
    let curentDutation = durations.find(
      (item: IDropDown) =>
        item.value === sessionInfo.durationSession?.toString()
    );

    setDuration(curentDutation ?? durations[durations.length - 1]);
    setStartTime(sessionInfo.startTime ?? "");
    setEndTime(sessionInfo.endTime ?? "");
    setCapacity(
      sessionInfo.capacity === 0 ? "" : sessionInfo.capacity?.toString() ?? ""
    );
  }, [sessionInfo]);

  useEffect(() => {
    const activeDurations = () => {
      if (startTime === DefaultMaxTime) {
        changeStartTimeHandler(DefaultMinTime);
      }
      const activeDurations = GetActiveDurations(
        startTime ?? DefaultMinTime,
        endTime ?? DefaultMaxTime
      );
      setDurations(activeDurations);
    };
    activeDurations();
  }, [startTime, endTime]);

  const deleteSessionHandler = () => {
    onDelete(selectedDay, sessionInfo.id);
  };

  return (
    <>
      <Grid
        className={ContainerStyle.service_accordion_box_container}
        container
        columnSpacing={windowSize.width > MediumDeviceTrigger ? 8 : 6}
      >
        <Grid item xs={12}>
          {index > 0 && (
            <CounterAvatar
              counterValue={index.toString()}
              counterText={translate("SERVICE.SESSION_TIME")}
              callToActionComponent={
                <>
                  <DeleteInfo onClick={deleteSessionHandler} />
                </>
              }
            />
          )}
        </Grid>
      </Grid>

      <Grid
        className={ContainerStyle.service_accordion_box_container}
        container
        columnSpacing={windowSize.width > MediumDeviceTrigger ? 8 : 6}
      >
        <div className={Style.timer_connector}>To</div>
        <Grid item md={6} sm={6} xs={6}>
          <TimePickerDropDown
            value={startTime}
            onChange={changeStartTimeHandler}
            label={translate("LABELS.START_TIME")}
          />
        </Grid>
        <Grid item md={6} sm={6} xs={6}>
          <TimePickerDropDown
            value={endTime}
            onChange={changeEndTimeHandler}
            label={translate("LABELS.END_TIME")}
            minTime={startTime ?? DefaultMinTime}
          />
        </Grid>
      </Grid>
      <Grid
        className={ContainerStyle.service_accordion_box_container}
        container
        columnSpacing={windowSize.width > MediumDeviceTrigger ? 8 : 6}
      >
        <Grid item md={6} sm={6} xs={6}>
          <DropDown
            data={durations}
            value={duration}
            onChange={changeDurationTimeHandler}
            label={translate("LABELS.SESSION_DURATION")}
          />
        </Grid>

        <Grid item md={6} sm={6} xs={6}>
          <TextField
            value={capacity}
            onChange={changeCapavityHandler}
            label={translate("LABELS.CAPACITY")}
            placeHolder={translate("PLACEHOLDER.CAPACITY")}
            maxLength={4}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default ServiceInfoTime;
