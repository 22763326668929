import React from "react";
import Style from "./FilterSearch.module.scss";
import { useTranslation } from "react-i18next";
import useSyncInputValue from "../../../../hook/SyncInputValue";
interface Props {
  valueSearch: string;
  onSearch: (value: string) => void;
}
const FilterSearchComponent: React.FC<Props> = ({ valueSearch, onSearch }) => {
  const { t: translate } = useTranslation();
  const [handleInputChange] = useSyncInputValue(valueSearch, onSearch);
  return (
    <div className={Style.search_field}>
      <input
        onChange={handleInputChange}
        placeholder={translate("SHARED.SEARCH")}
        value={valueSearch}
      />
    </div>
  );
};

export default FilterSearchComponent;
