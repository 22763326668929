import React from "react";
import Style from "./WeekDays.module.scss";
import { WeekDaysType } from "../../viewModels/dataTypes/tools/WeekDaysType";
import DayComponent from "./dayComponent/DayComponent";


interface Props {
  items: WeekDaysType[];
  onChange: (value: WeekDaysType[]) => void;
}
const WeekDays: React.FC<Props> = ({ items, onChange }) => {
  const updateDaysInfo = (selectedDay: WeekDaysType) => {
    let daysInfo: WeekDaysType[] = items.map((item: WeekDaysType) =>
      item.value === selectedDay.value
        ? { ...item, isActive: !selectedDay.isActive }
        : item
    );
    const activeDays = daysInfo.filter(
      (item: WeekDaysType) => item.isActive == true
    );
    if (activeDays.length > 0) {
      onChange(daysInfo);
    }
  };

  return (
    <div className={Style.container}>
      {items.map((item: WeekDaysType) => (
        <div onClick={() => updateDaysInfo(item)}>
          <DayComponent
            value={item.value}
            isActive={item.isActive}
            label={item.label}
          />
        </div>
      ))}
    </div>
  );
};

export default WeekDays;
