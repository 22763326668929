/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

export interface AddAdministratorViewModel {
  /** @format uuid */
  userId?: string | null;
  firstName?: string | null;
  lastName?: string | null;
  phoneNumber?: string | null;
  email?: string | null;
  /** @format int32 */
  countryId?: number | null;
  /** @format int32 */
  buildingId?: number;
  userRoleIds?: string | null;
}

export interface AddBuildingRequestViewModel {
  /** @format uuid */
  userId?: string;
  buildingName?: string | null;
  /** @format int32 */
  cityId?: number;
}

export interface AddResidentViewModel {
  /** @format uuid */
  userId?: string | null;
  firstName?: string | null;
  lastName?: string | null;
  phoneNumber?: string | null;
  email?: string | null;
  /** @format int32 */
  countryId?: number | null;
  /** @format int32 */
  id?: number | null;
  /** @format int32 */
  unitId?: number;
  userRole?: string | null;
  /** @format date-time */
  contractStartDate?: Date | null;
  /** @format date-time */
  contractEndDate?: Date | null;
  isResident?: boolean;
  isPrimary?: boolean;
}

export interface Announcement {
  /** @format int32 */
  id?: number;
  /** @format date-time */
  createdDateTime?: Date;
  /** @format int32 */
  buildingId?: number;
  /**
   * @minLength 0
   * @maxLength 50
   */
  title?: string | null;
  /**
   * @minLength 0
   * @maxLength 500
   */
  description?: string | null;
  label?: string | null;
  isDelete?: boolean;
  announcementAttachment?: Attachments[] | null;
}

export interface Attachments {
  /** @format int32 */
  id?: number;
  filePath: string | null;
}

export interface BookService {
  /** @format int32 */
  id?: number;
  /** @format date-time */
  createdDateTime?: Date;
  /** @format int32 */
  buildingServicesId?: number;
  /** @format int32 */
  residentId?: number;
  title?: string | null;
  description?: string | null;
  status?: RequestState;
  serviceTitle?: string | null;
  serviceIcon?: string | null;
  serviceIconColor?: string | null;
  unitNumber?: string | null;
  /** @format int32 */
  serviceFee?: number;
  isDelete?: boolean;
  /** @format date-time */
  lastUpdateDateTime?: Date;
  /** @format date-time */
  bookDate?: Date;
  bookStartTime?: string | null;
  bookEndTime?: string | null;
  requestNumber?: string | null;
  requestComments?: BuildingCommunicationViewModel[] | null;
  requestAttachment?: Attachments[] | null;
  cancellationReason?: string | null;
}

export interface BookServiceUpdateStateViewModel {
  /** @format int32 */
  id?: number;
  cancellationReason?: string | null;
  status?: RequestState;
}

export interface BuildingCommunicationViewModel {
  /** @format uuid */
  sederId?: string | null;
  sederAvatar?: string | null;
  senderName?: string | null;
  senderMessage?: string | null;
  /** @format date-time */
  sendDateTime?: Date;
  isAdmin?: boolean;
}

export interface BuildingInfoViewModel {
  /** @format int32 */
  id?: number;
  /** @format date-time */
  createdDateTime?: Date;
  buildingName?: string | null;
  /** @format int32 */
  cityId?: number;
  /** @format int32 */
  fileUploadId?: number | null;
  /** @format date-time */
  lastModified?: Date;
  /** @format date-time */
  lastView?: Date;
  address?: string | null;
  buildingUsages?: string | null;
  zipCode?: string | null;
  cityName?: string | null;
  province?: string | null;
  /** @format int32 */
  countryId?: number | null;
  fileName?: string | null;
}

export interface BuildingServices {
  /** @format int32 */
  id?: number;
  /** @format date-time */
  createdDateTime?: Date;
  /** @format int32 */
  buildingId?: number;
  /** @format int32 */
  serviceCategoryId?: number;
  title?: string | null;
  description?: string | null;
  /** @format date-time */
  startDate?: Date;
  /** @format date-time */
  endDate?: Date;
  /** @format double */
  fee?: number;
  isDelete?: boolean;
  /** @format int32 */
  repeatInterval?: number;
  repeatIntervalType?: TimeInterval;
  /** @format int32 */
  iconId?: number;
  iconTitle?: string | null;
  iconColor?: string | null;
  serviceSessions?: DaySchedule[] | null;
  serviceSessionsType?: SessionType;
}

export interface ChangePasswordRequestViewModel {
  /** @format uuid */
  userId?: string;
  password?: string | null;
}

export interface CheckOtpRequestViewModel {
  receiver?: string | null;
  code?: string | null;
  receiverType?: ReceiverType;
}

export interface ChoosePlanToCreateBuildingViewModel {
  newBuilding?: AddBuildingRequestViewModel;
  /** @format int32 */
  planId?: number;
  planDuration?: PlanDuration;
}

export interface Complaint {
  /** @format int32 */
  id?: number;
  /** @format date-time */
  createdDateTime?: Date;
  title?: string | null;
  /** @format int32 */
  residentId?: number;
  /** @format int32 */
  serviceCategoryId?: number;
  /**
   * @minLength 0
   * @maxLength 500
   */
  description?: string | null;
  status?: ComplaintState;
  /** @format date-time */
  lastUpdateDateTime?: Date;
  complaintComments?: BuildingCommunicationViewModel[] | null;
  complaintAttachment?: Attachments[] | null;
  isDelete?: boolean;
  unitNumber?: string | null;
  subject?: string | null;
  complaintNumber?: string | null;
}

/** @format int32 */
export enum ComplaintState {
  New = 0,
  Resolved = 1,
  InProgress = 2,
  UnResolved = 3,
}

export interface CreateUnitResidentsViewModel {
  primaryResident?: AddResidentViewModel;
  coResident?: AddResidentViewModel;
}

/** @format int32 */
export enum DayOfWeek {
  Sunday = 0,
  Monday = 1,
  Tuesday = 2,
  Wednesday = 3,
  Thursday = 4,
  Friday = 5,
  Saturday = 6,
}

export interface DaySchedule {
  days: DayOfWeek[];
  sessionInfo?: SessionServiceViewModel[] | null;
}

export interface DeleteAdministratorViewModel {
  /** @format int32 */
  buildingId?: number;
  /** @format uuid */
  userId?: string;
}

export interface DeleteBuildingUserViewModel {
  /** @format int32 */
  buildingId?: number;
  /** @format uuid */
  userId?: string;
}

export interface DeleteFileViewModel {
  /** @format int32 */
  id?: number;
  fileName?: string | null;
}

export interface FAQ {
  /** @format int32 */
  id?: number;
  /** @format date-time */
  createdDateTime?: Date;
  title?: string | null;
  description?: string | null;
  category?: string | null;
}

export interface Icon {
  /** @format int32 */
  id?: number;
  /** @format date-time */
  createdDateTime?: Date;
  title?: string | null;
  color?: string | null;
  iconType?: IconType;
  isDelete?: boolean;
}

/** @format int32 */
export enum IconType {
  All = 0,
  Categoty = 1,
  Service = 2,
}

/** @format int32 */
export enum PlanDuration {
  Trial = 0,
  PerMonth = 1,
  PerYear = 2,
}

/** @format int32 */
export enum ReceiverType {
  Email = 1,
  PhoneNumber = 2,
}

/** @format int32 */
export enum RequestState {
  New = 0,
  Canceled = 1,
  Completed = 2,
}

export interface Resident {
  /** @format int32 */
  id?: number;
  /** @format date-time */
  createdDateTime?: Date;
  /** @format int32 */
  unitId?: number;
  unitNumber?: string | null;
  /** @format uuid */
  userId?: string;
  isActive?: boolean;
  /** @format int32 */
  userRoleId?: number;
  userRoleName?: string | null;
  /** @format date-time */
  lastAvailableDateTime?: Date;
  /** @format date-time */
  contractStartDate?: Date | null;
  /** @format date-time */
  contractEndDate?: Date | null;
  isResident?: boolean;
  isPrimary?: boolean;
}

export interface ServiceCategory {
  /** @format int32 */
  id?: number;
  /** @format date-time */
  createdDateTime?: Date;
  title?: string | null;
  /** @format int32 */
  iconId?: number;
  /** @format int32 */
  buildingId?: number;
  isDelete?: boolean;
  iconTitle?: string | null;
  iconColor?: string | null;
}

export interface SessionServiceViewModel {
  /** @format int32 */
  id: number;
  startTime?: string | null;
  endTime?: string | null;
  /** @format int32 */
  durationSession?: number;
  /** @format int32 */
  capacity?: number;
}

/** @format int32 */
export enum SessionType {
  Default = 0,
  Customize = 1,
}

export interface Ticket {
  /** @format int32 */
  id?: number;
  /** @format date-time */
  createdDateTime?: Date;
  /**
   * @minLength 0
   * @maxLength 500
   */
  description?: string | null;
  status?: TicketState;
  /** @format date-time */
  lastUpdateDateTime?: Date;
  ticketComments?: BuildingCommunicationViewModel[] | null;
  ticketAttachment?: Attachments[] | null;
  isDelete?: boolean;
  ticketNumber?: string | null;
  ticketType?: string | null;
  ticketCategory?: string | null;
}

/** @format int32 */
export enum TicketState {
  New = 0,
  Resolved = 1,
  InProgress = 2,
  UnResolved = 3,
}

/** @format int32 */
export enum TimeInterval {
  Weekly = 0,
  Monthly = 1,
  Yearly = 2,
}

export interface TwoFactorAuthViewModel {
  /** @minLength 1 */
  userContact: string;
  /** @minLength 1 */
  password: string;
}

export interface UpdateComplaintStateViewModel {
  /** @format int32 */
  id?: number;
  status?: ComplaintState;
}

export interface UserCredentialInfoViewModel {
  /** @format uuid */
  userId?: string | null;
  firstName?: string | null;
  lastName?: string | null;
  companyName?: string | null;
  userAvatar?: string | null;
  /** @format int32 */
  planId?: number;
  planTitle?: string | null;
  planDuration?: PlanDuration;
  planImageUrl?: string | null;
  /** @format date-time */
  expireDate?: Date;
}

export interface UserInfoRegistrationViewModel {
  /** @format uuid */
  id?: string | null;
  phoneNumber?: string | null;
  firstName?: string | null;
  lastName?: string | null;
  password?: string | null;
  email?: string | null;
  /** @format int32 */
  country?: number | null;
  companyName?: string | null;
}
