import React from "react";
import Style from "./FilterDropDownDate.module.scss";
import { useTranslation } from "react-i18next";
import { DirectionNavigateEnum } from "../../../viewModels/enums/DirectionNavigateEnum";
import { ChevronLeft, ChevronRight } from "@mui/icons-material";

interface DateNavigationProps {
  onNavigate: (
    direction: DirectionNavigateEnum.LEFT | DirectionNavigateEnum.RIGHT
  ) => void;
  onTodayClick: () => void;
}

const DateNavigation: React.FC<DateNavigationProps> = ({
  onNavigate,
  onTodayClick,
}) => {
  const { t: translate } = useTranslation();

  return (
    <div className={Style.navigation}>
      <span className={Style.label_today} onClick={onTodayClick}>
        {translate("LABELS.TODAY")}
      </span>
      <ChevronLeft
        className={Style.chevron}
        onClick={() => onNavigate(DirectionNavigateEnum.LEFT)}
      />
      <ChevronRight
        className={Style.chevron}
        onClick={() => onNavigate(DirectionNavigateEnum.RIGHT)}
      />
    </div>
  );
};

export default DateNavigation;
