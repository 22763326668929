import { AnyAction, configureStore } from "@reduxjs/toolkit";
import LangSlice from "./slices/LangSlice";
import thunk, { ThunkDispatch } from "redux-thunk";
// ...
import { logger } from "redux-logger";
import UserSlice from "./slices/UserSlice";
import CountrySlice from "./slices/CountrySlice";
import BuildingSlice from "./slices/UserBuildingSlice";
import MenuSlice from "./slices/MenuSlice";
import BuildingInfoSlice from "./slices/BuildingInfoSlice";
import BuildingUsersSlice from "./slices/BuildingUsersSlice";
import AdminResponsibilitiesSlice from "./slices/AdminResponsibilitiesSlice";
import BuildingServiceCategoriesSlice from "./slices/BuildingServiceCategories";
import BuildingServicesSlice from "./slices/BuildingServices";
import ServicesIconsSlice from "./slices/ServicesIcons";
import BuildingServiceRequestsSlice from "./slices/BuildingServiceRequestsSlice";
import BuildingComplainsSlice from "./slices/ComplaintsSlice";
import UnsavedChangesSlice from "./slices/UnsavedChangesSlice";
import AnnouncementSlice from "./slices/AnnouncementSlice";
import TicketsSlice from "./slices/TicketsSlice";

const store = configureStore({
  reducer: {
    lang: LangSlice,
    menu: MenuSlice,
    unsavedChanges: UnsavedChangesSlice,
    user: UserSlice,
    country: CountrySlice,
    building: BuildingSlice,
    buildingInfo: BuildingInfoSlice,
    buildingUsers: BuildingUsersSlice,
    adminResponsibilities: AdminResponsibilitiesSlice,
    buildingServiceCategories: BuildingServiceCategoriesSlice,
    buildingServices: BuildingServicesSlice,
    servicesIcons: ServicesIconsSlice,
    buildingServiceRequests: BuildingServiceRequestsSlice,
    complaintsList: BuildingComplainsSlice,
    announcementList: AnnouncementSlice,
    ticketList: TicketsSlice,
  },
  middleware: [thunk, logger],
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;

export type AppThunkDispatch = ThunkDispatch<RootState, any, AnyAction>;

export default store;
