import * as React from "react";
import Style from "./Stepper.module.scss";
import { IStepperItem } from "../../viewModels/dataTypes/tools/Stepper";
import useWindowSize from "../../hook/WindowSize";
import MobileStepper from "./mobileStepper/MobileStepper";
import { SmallDeviceTrigger } from "../../Setting";
import { useTranslation } from "react-i18next";

interface CustomStepperProps {
  activeStep: number;
  steps: IStepperItem[];
}
const LinerStepper: React.FC<CustomStepperProps> = ({ steps, activeStep }) => {
  const size = useWindowSize();
  const { t: translate } = useTranslation();
  return (
    <div className={Style.stepper_wrapper}>
      {size.width > SmallDeviceTrigger ? (
        <div className={Style.custom_stepper}>
          {steps.map((step: IStepperItem, index: number) => (
            <>
              {index !== 0 && (
                <div
                  className={`${Style.step_line} ${
                    index <= activeStep ? Style.line_active : ""
                  }`}
                ></div>
              )}{" "}
              <div
                key={index}
                className={`${Style.step} ${
                  index <= activeStep ? Style.active : ""
                }`}
              >
                <div className={Style.step_circle}>{step.icon}</div>
                <div className={Style.step_title}>
                  {index + 1 + "." + translate(step.titr)}
                </div>
              </div>
            </>
          ))}
        </div>
      ) : (
        <MobileStepper activeStep={activeStep} steps={steps} />
      )}
    </div>
  );
};

export default LinerStepper;
