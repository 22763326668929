import React, { useEffect, useState } from "react";
import Style from "./ServiceCategoryPopUp.module.scss";
import ServicesPopUp from "../../../../../../tools/popup/servicesPopup/ServicesPopUp";
import ManageAccountsOutlinedIcon from "@mui/icons-material/ManageAccountsOutlined";
import TextField from "../../../../../../tools/fields/textField/TextField";
import ServiceIconSlider from "./servicesIconSlider/ServiceIconSlider";
import { useTranslation } from "react-i18next";
import Button from "../../../../../../tools/button/Button";
import {
  ButtonState,
  ButtonTheme,
} from "../../../../../../viewModels/enums/ButtonEnum";
import { SizeType } from "../../../../../../viewModels/dataTypes/tools/ListComponentType";
import { IsNullString } from "../../../../../../services/commonServices/CommonServices";
import { FieldState } from "../../../../../../viewModels/enums/PublicEnum";

interface Props {
  title: string;
  categoryServiceName: string;
  categoryServiceIconId?: number;
  onChangeHandler: (categoryName: string, categoryIcon: number) => void;
  onClickHandler: () => void;
  onCancelHandler: () => void;
  callToActionButtonText: string;
  existingCategoryNames?: string[];
  openEditPopUp?: boolean;
  loading?: boolean;
}
const ServiceCategoryPopUp: React.FC<Props> = ({
  title,
  categoryServiceName = "",
  categoryServiceIconId = 0,
  onChangeHandler,
  onClickHandler,
  onCancelHandler,
  callToActionButtonText,
  existingCategoryNames = [],
  openEditPopUp,
  loading = false, // Default value for loading
}) => {
  const { t: translate } = useTranslation();
  const [categoryName, setCategoryName] = useState<string>(categoryServiceName);
  const [iconId, setIconId] = useState<number>(categoryServiceIconId);
  const [buttonState, setButtonState] = useState<ButtonState>(
    ButtonState.disable
  );
  const [initialCategoryName] = useState<string>(categoryServiceName);
  const [initialCategoryIconId] = useState<number>(categoryServiceIconId);
  const [hasDublicate, setHasDublicate] = useState<boolean>(false);

  const checkDuplicate = (name: string): boolean => {
    if (
      openEditPopUp &&
      name.toLowerCase() === initialCategoryName.toLowerCase()
    ) {
      return false;
    }
    return existingCategoryNames.includes(name.toLowerCase());
  };

  useEffect(() => {
    onChangeHandler(categoryName, iconId);

    const changeStatePolice: boolean =
      IsNullString(categoryName) ||
      (categoryName === initialCategoryName &&
        iconId === initialCategoryIconId) ||
      loading;
    setButtonState(
      changeStatePolice ? ButtonState.disable : ButtonState.default
    );
  }, [
    categoryName,
    iconId,
    initialCategoryIconId,
    initialCategoryName,
    onChangeHandler,
    loading,
  ]);

  const closeBoxHandler = () => {
    onCancelHandler();
  };

  const handleAddClick = () => {
    if (checkDuplicate(categoryName)) {
      setHasDublicate(true);
    } else {
      setHasDublicate(false);
      onClickHandler();
    }
  };

  return (
    <>
      <ServicesPopUp
        icon={<ManageAccountsOutlinedIcon className={Style.service_icon} />}
        title={title}
      >
        <div className={Style.service_contents}>
          <ServiceIconSlider iconId={iconId} changeIconHandler={setIconId} />
          <TextField
            label={
              hasDublicate
                ? translate("ALERT.DUPLICATE_NAME", {
                    value: translate("SHARED.CATEGORY").toLowerCase(),
                  })
                : translate("LABELS.SERVICE_CATEGORY_NAME")
            }
            fieldState={hasDublicate ? FieldState.Error : FieldState.Default}
            placeHolder={translate("PLACEHOLDER.SERVICE_CATEGORY_NAME")}
            value={categoryName} 
            onChange={(value: string) => {
              setCategoryName(value);
              setHasDublicate(false);
            }}
          />
          <div className={Style.add_service_button}>
            <Button
              theme={ButtonTheme.primaryReverse}
              text={translate("BUTTON.CANCEL")}
              size={SizeType.small}
              clickMethod={closeBoxHandler}
            />
            <Button
              text={callToActionButtonText}
              clickMethod={handleAddClick}
              theme={ButtonTheme.primary}
              size={SizeType.small}
              state={buttonState}
            />
          </div>
        </div>
      </ServicesPopUp>
    </>
  );
};

export default ServiceCategoryPopUp;
