import { Grid } from "@mui/material";
import React from "react";
import Item from "./serviceItem/Item";
import { Container } from "../../../tools/container/customeContainer/Container";
import { Building } from "iconsax-react";
import { useTranslation } from "react-i18next";

const ServicesSection: React.FC = () => {
  const { t : translate } = useTranslation();
  return (
    <Container top="3rem">
      <Grid container spacing={4}>
        <Grid item md={3} sm={6} xs={100}>
          <Item
            icon={<Building size="32" color="var(--icon-primary)" />}
            title={translate("DASHBOARD.BUILDING")}
            desc="Lorem ipsum dolor sit ametLorem ipsum dolor sit amet"
          />
        </Grid>
        <Grid item md={3} sm={6} xs={100}>
          <Item
            icon={<Building size="32" color="var(--icon-primary)" />}
            title={translate("DASHBOARD.BUILDING")}
            desc="Lorem ipsum dolor sit ametLorem ipsum dolor sit amet"
          />
        </Grid>
        <Grid item md={3} sm={6} xs={100}>
          <Item
            icon={<Building size="32" color="var(--icon-primary)" />}
            title={translate("DASHBOARD.BUILDING")}
            desc="Lorem ipsum dolor sit ametLorem ipsum dolor sit amet"
          />
        </Grid>
        <Grid item md={3} sm={6} xs={100}>
          <Item
            icon={<Building size="32" color="var(--icon-primary)" />}
            title={translate("DASHBOARD.BUILDING")}
            desc="Lorem ipsum dolor sit ametLorem ipsum dolor sit amet"
          />
        </Grid>
      </Grid>
    </Container>
  );
};

export default ServicesSection;
