import React, { ReactNode } from "react";
import Style from "./ImageItem.module.scss";
import { GetShortFileName } from "../../../../services/commonServices/filesCommonServices/FilesCommonServices";

interface Props {
  src: string;
  alt?: string;
  fileName: string;
  callToAction: ReactNode;
  format: string;
  onClick: () => void;
}
const ImageItem: React.FC<Props> = ({
  src,
  alt,
  fileName,
  callToAction,
  format,
  onClick,
}) => {
  return (
    <div className={Style.image_box}>
      {format === "pdf" ? (
        <iframe src={src} title="PDF Preview" />
      ) : (
        <img src={src} alt={alt} />
      )}
      <div className={Style.image_info} onClick={onClick}>
        <div className={Style.file_name}>{GetShortFileName(fileName, 12)}</div>
        <div className={Style.icon_call_to_action}>{callToAction}</div>
      </div>
    </div>
  );
};

export default ImageItem;
