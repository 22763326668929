import React, { ReactElement } from "react";
import Style from "./BeforeDashboardForm.module.scss";
import { BeforeLoginContainer } from "../../tools/container/beforeLoginContainer.tsx/Container";
import { AlignCenterContainer } from "../../tools/container/customeContainer/AlignCenterContainer";
import { FloorinsBox } from "../../tools/box/LoginBox";
import Header from "./formHeader/Header";
import PrimaryFooter from "../footer/primaryFooter/PrimaryFooter";
import { UltraSmallDeviceTrigger } from "../../Setting";

interface Props {
  title?: string;
  description?: string;
  body: ReactElement;
  backButton?: boolean;
  md?: number;
  backHandler?: () => void;
}

const BeforeDashboardForm: React.FC<Props> = ({
  title = "",
  description = "",
  body,
  backButton = true,
  backHandler,
  md = UltraSmallDeviceTrigger,
}) => {
  return (
    <>
      <BeforeLoginContainer>
        <AlignCenterContainer>
          <FloorinsBox md={md}>
            <Header backButton={backButton} backHandler={backHandler} />
            {title.length > 0 ? (
              <h1 className={Style.page_title}>{title}</h1>
            ) : (
              <></>
            )}
            {description.length > 0 ? (
              <p className={Style.page_description}>{description}</p>
            ) : (
              <></>
            )}
            <div
              className={` ${
                md === UltraSmallDeviceTrigger && Style.mainpage_box
              } mb-3`}
            >
              {body}
            </div>
          </FloorinsBox>
        </AlignCenterContainer>
      </BeforeLoginContainer>
      <PrimaryFooter />
    </>
  );
};

export default BeforeDashboardForm;
