import React from "react";
import StateComponent from "./StateComponent";
import { UpdateStateComplaints } from "../../../components/dashboard/dashboardContents/complaints/complaintsList/ComplaintsList.service";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { GetBuildingComplaintsAction } from "../../../redux/slices/ComplaintsSlice";
import { ComplaintState } from "../../../viewModels/api/Models";
import { AppThunkDispatch } from "../../../redux/Store";

interface Props {
  id: number;
  isHovered?: boolean | undefined;
  status: number;
}

const ComplaintsStateComponent: React.FC<Props> = ({
  status,
  isHovered,
  id,
}) => {
  const { buildingId } = useParams();
  const dispatch = useDispatch<AppThunkDispatch>();

  const getComplaintsStateList = (): string[] => {
    return Object.keys(ComplaintState)
      .filter((key: string) => isNaN(Number(key)))
      .map((key: string) => key);
  };

  const handleSelectState = async (index: number) => {
    await updateState(id, index);
    dispatch(GetBuildingComplaintsAction(buildingId!));
  };

  const updateState = async (complaintId: number, newStatus: number) => {
    const newStateInfo = {
      id: complaintId,
      status: newStatus,
    };

    await UpdateStateComplaints(newStateInfo);
  };

  const statusLabels = getComplaintsStateList();
  const stateColors = [
    "var(--color-new)",
    "var(--color-resolved)",
    "var(--color-inprogress)",
    "var(--color-unresolved)",
  ];

  return (
    <div>
      <StateComponent
        status={status}
        stateItems={statusLabels}
        stateColors={stateColors}
        hovered={isHovered}
        onSelect={handleSelectState}
      />
    </div>
  );
};

export default ComplaintsStateComponent;
