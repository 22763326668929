import React, { useEffect, useRef, useState } from "react";
import Style from "./DashboardMenu.module.scss";
import Logo from "../../../assets/images/Floorins.svg";
import { SuperAdminMenuItem, appSupportItem } from "./MenuItemsValue";
import Button from "../../../tools/button/Button";
import { useTranslation } from "react-i18next";
import { ButtonTheme } from "../../../viewModels/enums/ButtonEnum";
import MenuList from "./menuItems/MenuList";
import { InfoCircle, LogoutCurve } from "iconsax-react";
import { useDispatch, useSelector } from "react-redux";
import { IReducer } from "../../../viewModels/reduxInterfaces/ReduxInterfaces";
import CloseIcon from "@mui/icons-material/Close";
import { AppThunkDispatch } from "../../../redux/Store";
import { menuStateHandler } from "../../../redux/slices/MenuSlice";
import ProfileInfo from "../header/profile/ProfileInfo";
import useWindowSize from "../../../hook/WindowSize";
import { Localstorage_UserToken } from "../../../text/LocalStorageValues";
import { SizeType } from "../../../viewModels/dataTypes/tools/ListComponentType";
import { SmallDeviceTrigger } from "../../../Setting";
import { useNavigate, useParams } from "react-router-dom";
import { UserCredentialInfoViewModel } from "../../../viewModels/api/Models";
import { ClassNameViewModel } from "../../../viewModels/dataTypes/CommonViewModels";
import {
  setHasUnsavedChanges,
  setShowPopup,
} from "../../../redux/slices/UnsavedChangesSlice";
import MessagePopUp from "../../../tools/popup/MessagePopUp";

interface Props extends ClassNameViewModel {
  userInfo: UserCredentialInfoViewModel | null;
}
const DashboardMenu: React.FC<Props> = ({ userInfo, className }) => {
  const { t: translate } = useTranslation();

  const { buildingId } = useParams();
  const navigate = useNavigate();

  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [state, setState] = useState<boolean>(false);

  const { width: screenSize } = useWindowSize();

  const menuState = useSelector((state: IReducer) => state.menu);
  const hasUnsavedChanges = useSelector(
    (state: any) => state.unsavedChanges.hasUnsavedChanges
  );
  const discardHandler = () => {
    dispatch(setShowPopup(false));
    dispatch(setHasUnsavedChanges(false));
    navigate("/login");
  };

  const cancelHandler = () => {
    dispatch(setShowPopup(false));
    setState(false);
  };

  const dispatch = useDispatch<AppThunkDispatch>();
  useEffect(() => {
    setIsOpen(menuState.isOpen);
  }, [menuState]);

  const closeMenu = () => {
    dispatch(menuStateHandler(false));
  };

  const logout = () => {
    if (hasUnsavedChanges) {
      dispatch(setShowPopup(true));
      setState(true);
    } else {
      // Remove the user token from local storage
      localStorage.removeItem(Localstorage_UserToken);
      // Replace the current history entry with the login page
      window.history.replaceState(null, "", "/login");
      window.history.pushState(null, "", "/login");
      // Redirect to the login page without adding a new entry to the history
      window.location.replace("/login");
      setState(false);
      dispatch(setShowPopup(false));
    }
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (divRef.current && !divRef.current.contains(event.target as Node)) {
        // Logic to handle click outside of the div
        closeMenu();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const divRef = useRef<HTMLDivElement>(null);

  return (
    <div
      ref={divRef}
      className={`${Style.menu} ${isOpen ? Style.open_menu : ""} ${className}`}
    >
      <div className={Style.menu_header}>
        <div className={Style.menu_logo}>
          <img src={Logo} alt="Logo" />
          {screenSize < SmallDeviceTrigger ? (
            <ProfileInfo userInfo={userInfo} />
          ) : (
            <></>
          )}
        </div>
        <div className={Style.close_menu} onClick={closeMenu}>
          <CloseIcon />
        </div>
      </div>

      <div className={Style.menu_list_buttons}>
        <nav onClick={closeMenu}>
          <MenuList items={SuperAdminMenuItem(buildingId ?? "")} />
          <MenuList items={appSupportItem} />
        </nav>
        <div className={Style.menu_button}>
          <Button
            size={SizeType.medium}
            text={translate("SHARED.UPGRADE")}
            className={Style.button}
          />
          <Button
            className={Style.button}
            size={SizeType.medium}
            clickMethod={logout}
            theme={ButtonTheme.accent}
            text={translate("AUTHENTICATION.LOGOUT")}
            icon={<LogoutCurve size="16" />}
          />
        </div>
        {state ? (
          <MessagePopUp
            icon={<InfoCircle />}
            title={translate("SHARED.LEAVE_PAGE")}
            description={translate("ALERT.LEAVE_PAGE")}
            buttons={
              <>
                <Button
                  className={Style.cancel_button}
                  text={translate("BUTTON.CANCEL")}
                  size={SizeType.small}
                  theme={ButtonTheme.primaryReverse}
                  clickMethod={cancelHandler}
                />
                <Button
                  className={Style.cancel_button}
                  text={translate("BUTTON.DISCARD")}
                  size={SizeType.small}
                  theme={ButtonTheme.primary}
                  clickMethod={discardHandler}
                />
              </>
            }
          />
        ) : null}
      </div>
    </div>
  );
};

export default DashboardMenu;
