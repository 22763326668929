import { GetBuildingUsers } from "../../../../../services/api/User";
import { GetPrimaryRoles } from "../../../../../services/api/UserRole";
import {
  CheckNullText,
  GenerateRandomColor,
  IsNullString,
} from "../../../../../services/commonServices/CommonServices";
import { ContainsSearchTerm } from "../../../../../tools/table/filter/filterSearch/FIlterSearch.Service";
import { ApiResponse, IServiceResult } from "../../../../../viewModels/api/Api";
import { IDropDown } from "../../../../../viewModels/dataTypes/tools/DropDownItems";
import {
  BuildingUsersResponseViewModel,
  IBuildingUsers,
} from "../../../../../viewModels/dataTypes/user/IBuildingUsers";
import { IUserRole } from "../../../../../viewModels/dataTypes/user/User";
import { FilterConditionsEnum } from "../../../../../viewModels/enums/FilterConditionsEnum";
import { ResponseCode } from "../../../../../viewModels/enums/ResponseCode";

export const GetBuildingUsersService = async (buildingId: string) => {
  let resultModel: IServiceResult<IBuildingUsers[]> = {
    message: "",
  };
  const combinedResponse: IBuildingUsers[] = [];
  const result: ApiResponse<BuildingUsersResponseViewModel[]> =
    await GetBuildingUsers(buildingId);
  if (result.responseStatus === ResponseCode.Success) {
    result.data?.forEach((item: BuildingUsersResponseViewModel) => {
      const existingEntry = combinedResponse.find(
        (entry: IBuildingUsers) => entry.id === item.userId
      );
      if (existingEntry) {
        if (item.unitNumber) {
          if (existingEntry.unitNumber === "-") {
            existingEntry.unitNumber = item.unitNumber;
          } else {
            existingEntry.unitNumber += `,${item.unitNumber}`;
          }
        }
        if (!existingEntry.userRole.includes(item.roleName)) {
          existingEntry.userRole += `,${item.roleName}`;
        }
      } else {
        const bgColor = GenerateRandomColor();
        combinedResponse.push({
          id: item.userId,
          contact: item.email != null ? item.email : "+" + item.phoneNumber,
          fullName:
            CheckNullText(item.firstName) && CheckNullText(item.lastName)
              ? `${CheckNullText(item.firstName)} ${CheckNullText(
                  item.lastName
                )}`
              : "-",
          userRole: item.roleName != null ? item.roleName : "-",
          unitNumber: item.unitNumber != null ? item.unitNumber : "-",
          confirm: item.isConfirmed,
          permissions: "All",
          avatar: item.userAvatar,
          bgAvatar: bgColor,
        });
      }
    });
    resultModel.data = combinedResponse;
  } else {
    resultModel.data = null;
    resultModel.message = "ERRORS.OPERATION_FAILD";
  }
  return resultModel;
};

export const FilterBuildingUsers = (
  data: IBuildingUsers[],
  permissions: string,
  userTypes: string,
  permissionsLogic: string,
  userTypesLogic: string,
  searchText: string
) => {
  const searchedData = SearchBuildingUsers(data, searchText);
  if (!IsNullString(permissions) || !IsNullString(userTypes)) {
    const permissionsList: string[] = permissions
      .split(",")
      .map((p) => p.trim());
    const userTypesList: string[] = userTypes.split(",").map((u) => u.trim());
    // Filter the data array
    const filteredData = searchedData.filter((item: IBuildingUsers) => {
      // Check permissions based on specified logic
      const permissionMatch =
        permissionsLogic === FilterConditionsEnum.OR
          ? permissionsList.some((permission: string) =>
              item.permissions.split(",").includes(permission)
            )
          : permissionsList.every((permission: string) => {
              return item.permissions.split(",").includes(permission);
            });
      // Check user types based on specified logic
      const userTypeMatch =
        userTypesLogic === FilterConditionsEnum.OR
          ? userTypesList.some((userType: string) =>
              item.userRole.split(",").includes(userType)
            )
          : userTypesList.every((userType: string) => {
              return item.userRole.split(",").includes(userType);
            });
      // Return true if both permission and user type match according to their logic
      return permissionMatch || userTypeMatch;
    });
    return filteredData;
  } else {
    return searchedData;
  }
};

export const GetUserType = async () => {
  let resultModel: IServiceResult<IDropDown[]> = {
    message: "",
  };
  const combinedResponse: IDropDown[] = [];
  var result: ApiResponse<IUserRole[]> = await GetPrimaryRoles();
  if (result.responseStatus === ResponseCode.Success) {
    result.data?.forEach((item: IUserRole) => {
      combinedResponse.push({
        text: item.roleName,
        value: item.roleName,
      });
    });
    resultModel.data = combinedResponse;
  }
  return resultModel;
};

const SearchBuildingUsers = (data: IBuildingUsers[], searchTerm: string) => {
  if (IsNullString(searchTerm)) {
    return data;
  }

  return data.filter((item: IBuildingUsers) =>
    ContainsSearchTerm(item, searchTerm)
  );
};
