import {
  ActionReducerMapBuilder,
  createAsyncThunk,
  createSlice,
  PayloadAction,
} from "@reduxjs/toolkit";
import { IServiceResult } from "../../viewModels/api/Api";
import { IBuildingTicketsReducer } from "../../viewModels/reduxInterfaces/ReduxInterfaces";
import { TicketViewModel } from "../../viewModels/dataTypes/building/TicketViewModel";
import { GetTicketItems } from "../../components/dashboard/dashboardContents/tickets/Tickets.Service";

const initialState: IBuildingTicketsReducer = {
  tickets: [],
  loading: true,
  fill: false,
  err: "",
} as IBuildingTicketsReducer;

const BuildingTicketsSlice = createSlice({
  name: "buildingTickets",
  initialState,
  reducers: {},
  extraReducers: (
    builder: ActionReducerMapBuilder<IBuildingTicketsReducer>
  ) => {
    builder.addCase(
      GetBuildingTickets.fulfilled,
      (
        state: IBuildingTicketsReducer,
        action: PayloadAction<TicketViewModel[]>
      ) => {
        if (action.payload.length > 0) {
          state.tickets = action.payload;
          state.fill = true;
        }
        state.loading = false;
      }
    );
    builder.addCase(
      GetBuildingTickets.pending,
      (state: IBuildingTicketsReducer) => {
        state.loading = true;
      }
    );
    builder.addCase(
      GetBuildingTickets.rejected,
      (state: IBuildingTicketsReducer) => {
        state.loading = false;
      }
    );
  },
});

export const GetBuildingTickets = createAsyncThunk(
  "GetBuildingTickets",
  async (): Promise<TicketViewModel[]> => {
    const response: IServiceResult<TicketViewModel[]> = await GetTicketItems();
    return response.data || [];
  }
);

export default BuildingTicketsSlice.reducer;
