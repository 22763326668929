import { useEffect, RefObject } from "react";

interface UseDynamicMaxHeightArgs {
  tableContainerRef: RefObject<HTMLElement>;
  paginationRef: RefObject<HTMLElement>;
  spacing: number;
  setMaxHeight: (value: string) => void;
}

const useDynamicMaxHeight = ({
  tableContainerRef,
  paginationRef,
  spacing,
  setMaxHeight,
}: UseDynamicMaxHeightArgs) => {
  useEffect(() => {
    const updateMaxHeight = () => {
      if (tableContainerRef.current && paginationRef.current) {
        const distanceFromTop: number =
          tableContainerRef.current.getBoundingClientRect().top;
        const paginationHeight: number = paginationRef.current.clientHeight;
        const newMaxHeight: string = `calc(100vh - ${
          distanceFromTop + paginationHeight + spacing
        }px)`;
        setMaxHeight(newMaxHeight);
      }
    };

    // Initial calculation
    updateMaxHeight();

    // Recalculate on window resize
    window.addEventListener("resize", updateMaxHeight);

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener("resize", updateMaxHeight);
    };
  }, [tableContainerRef, paginationRef, spacing, setMaxHeight]); // Dependencies include setMaxHeight now
};

export default useDynamicMaxHeight;
