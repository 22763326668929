import { IDropDown } from "../../viewModels/dataTypes/tools/DropDownItems";

export const GenerateDigitalTimes = (): IDropDown[] => {
    const times: IDropDown[] = [];
    const pad = (num: number): string => num.toString().padStart(2, '0');

    for (let hour = 0; hour < 24; hour++) {
        for (let minute = 0; minute < 60; minute += 30) {
            const hour12 = hour % 12 === 0 ? 12 : hour % 12;
            const period = hour < 12 ? 'AM' : 'PM';
            const formattedHour = pad(hour12 === 12 && period === 'AM' ? 0 : hour12);
            const formattedMinute = pad(minute);
            const value = `${pad(hour)}:${formattedMinute}`;
            const text = `${formattedHour}:${formattedMinute} ${period}`;

            times.push({ value, text });
        }
    }

    return times;
};
