import React from "react";
import { DashboardContainer } from "../../../../tools/container/customeContainer/DashboardContainer";
import DashboardBody from "../../dashboardBody/DashboardBody";
import AddAdmin from "./addUser/AddAdmin";

const NewAdminPage = () => {
  return (
    <DashboardContainer>
      <DashboardBody>
        <AddAdmin />
      </DashboardBody>
    </DashboardContainer>
  );
};

export default NewAdminPage;
