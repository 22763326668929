import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import Style from "./UploadImage.module.scss";
import { FormatAcceptUploadLabel, MaxSizeUpload } from "../../Setting";
import { useTranslation } from "react-i18next";

interface Props {
  uploadDisabled?: boolean;
  showErrorUpload?: boolean;
  triggerFileUpload?: () => void;
}
const UploadImage: React.FC<Props> = ({
  uploadDisabled,
  showErrorUpload,
  triggerFileUpload,
}) => {
  const { t: translate } = useTranslation();
  return (
    <div
      className={`${Style.swiper_slide_custom_upload} ${
        uploadDisabled ? Style.disable_upload : ""
      } ${showErrorUpload ? Style.error_upload : ""}`}
      onClick={triggerFileUpload}
    >
      <FileUploadOutlinedIcon
        className={`${Style.icon_upload} ${
          showErrorUpload ? Style.error_icon : ""
        }`}
      />
      {!showErrorUpload ? (
        <>
          <p className={Style.label_upload}>
            {translate("LABELS.UPLOAD_FILE")}
          </p>
          <div className={Style.file_info}>
            <p>{FormatAcceptUploadLabel}</p>
            <p>
              ({translate("LABELS.MAX")} {MaxSizeUpload})
            </p>
          </div>
        </>
      ) : (
        <>
          <div className={Style.file_info_error}>
            <p>{translate("ANNOUNCEMENTS.ALERT.FILE_SIZE_EXCEEDS")}</p>
          </div>
        </>
      )}
    </div>
  );
};

export default UploadImage;
