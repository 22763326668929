import styled from "styled-components";

export const BeforeLoginContainer = styled.div`
  /* max-width: 540px; */
  width: 100%;
  padding: 3rem 1rem;
  position: relative;
  margin: auto;
  min-height:calc(100vh - 100px);
  height: auto;
  display: flex;
`;
