import React from "react";
import { PickersDay, PickersDayProps } from "@mui/x-date-pickers/PickersDay";
import dayjs, { Dayjs } from "dayjs";
import { CalenderEnum } from "../../../viewModels/enums/CalenderEnum";
import useWindowSize from "../../../hook/WindowSize";
import { UltraSmallDeviceTrigger } from "../../../Setting";

interface WeekPickerDayProps extends PickersDayProps<Dayjs> {
  date: Dayjs;
  isWithinWeek: boolean | null;
  isHoveredWeek: boolean | null;
  handleDayHover: (date: Dayjs | null) => void;
}

const WeekPickerDay: React.FC<WeekPickerDayProps> = ({
  date,
  isWithinWeek,
  isHoveredWeek,
  handleDayHover,
  ...pickersDayProps
}) => {
  const isToday = date.isSame(dayjs(), CalenderEnum.DAY);
  const windowSize = useWindowSize();
  const isMobile: boolean = windowSize.width < UltraSmallDeviceTrigger;

  return (
    <PickersDay
      {...pickersDayProps}
      disableMargin
      selected={false}
      sx={{
        backgroundColor: isHoveredWeek
          ? isMobile
            ? "var(--bg-blue-low)"
            : "var(--bg-gray-low)"
          : isWithinWeek
          ? "var(--bg-blue-low)"
          : undefined,
        "&:hover": {
          backgroundColor: isMobile
            ? "var(--bg-blue-low)"
            : isHoveredWeek
            ? "var(--bg-gray-low)"
            : "var(--bg-blue-low)",
        },
        "&:focus": {
          backgroundColor: isMobile
            ? "var(--bg-blue-low)"
            : isHoveredWeek
            ? "var(--bg-gray-low)"
            : "var(--bg-blue-low)",
        },
        "&.Mui-selected": {
          backgroundColor: isMobile
            ? "var(--bg-blue-low)"
            : isHoveredWeek
            ? "var(--bg-blue-low)"
            : "var(--bg-blue-low)",
          color: "unset",
          outline: "unset",
        },
        border: isToday ? "0 !important" : "",
        borderRadius: isToday ? "50%" : 0,
      }}
      onMouseEnter={() => handleDayHover(date)}
      onMouseLeave={() => handleDayHover(null)}
    />
  );
};

export default WeekPickerDay;
