import React from "react";
import { LineCircle } from "../../../../../tools/chart/LineCircle";
import { useTranslation } from "react-i18next";
import Style from "./CurrentPlanItem.module.scss";
import { TrialPlanDuration } from "../../../../../Setting";
import { CalculatePercentage } from "../../../../../services/commonServices/CommonServices";

interface Props {
  remainingTime: number;
}

const CurrentPlanItem: React.FC<Props> = ({ remainingTime }) => {
  const { t: translate } = useTranslation();
  return (
    <div className={Style.current_plan_info}>
      <LineCircle
        width={120}
        fillPercent={CalculatePercentage(remainingTime, TrialPlanDuration)}
      >
        <div className={Style.plan_chart}>
          <span>
            <span> {remainingTime} </span>
            {translate("PLANS.DAYS_REMAINING")}
          </span>
        </div>
      </LineCircle>
      <div>
        <h3>{translate("PLANS.TRIAL")}</h3>
        <p>
          {translate("PLANS.MANAGE_SINGLE_BUILDING")}
          <span>
            {" " + TrialPlanDuration}-
            {translate("SHARED.DAY").toLocaleLowerCase()}
          </span>{" "}
          {translate("PLANS.TRIAL_PERIOD")}
          {translate("PLANS.UPGRADE_TRIAL")}
        </p>
      </div>
    </div>
  );
};

export default CurrentPlanItem;
