import { DeleteUserFromList } from "../../../../../../services/api/Building";
import {
  ApiResponse,
  IServiceResult,
} from "../../../../../../viewModels/api/Api";
import { DeleteBuildingUserViewModel } from "../../../../../../viewModels/api/Models";
import { ResponseCode } from "../../../../../../viewModels/enums/ResponseCode";

export const DeleteUserFromListService = async (
  userInfo: DeleteBuildingUserViewModel
) => {
  var resultModel: IServiceResult<boolean> = {
    message: "",
  };

  const deleteUserResult: ApiResponse<boolean> = await DeleteUserFromList(
    userInfo
  );
  if (deleteUserResult.responseStatus === ResponseCode.Success) {
    resultModel.message = "ALERT.DELETE_RESIDENT_SUCCESSFULLY";
    resultModel.data = true;
  } else {
    resultModel.message = "ERRORS.DELETED_FAILD";
    resultModel.data = false;
  }
  return resultModel;
};
