import React from "react";
import ProfileComponent from "./ProfileComponent";
import { RandomColorEnum } from "../../../viewModels/enums/AvatarBackGround";

interface Props {
  buildingName: string;
  fileName: string;
  bgAvatar: RandomColorEnum;
}

const BuildingProfileComponent: React.FC<Props> = ({
  buildingName,
  fileName,
  bgAvatar,
}) => (
  <ProfileComponent value={buildingName} avatar={fileName} bgColor={bgAvatar} />
);

export default BuildingProfileComponent;
