import { Grid } from "@mui/material";
import React, { useState } from "react";
import ContainerStyle from "../ComplaintInfoForm.module.scss";
import useWindowSize from "../../../../../../../hook/WindowSize";
import { MediumDeviceTrigger } from "../../../../../../../Setting";
import TextField from "../../../../../../../tools/fields/textField/TextField";
import TextArea from "../../../../../../../tools/textArea/TextArea";
import { useTranslation } from "react-i18next";
import ImageSlider from "../../../../../../../tools/slider/imageSlider/ImageSlider";
import Style from "./ComplaintInfo.module.scss";
import { ComplaintInfoState } from "./ComplaintInfo.State";
import { Attachments } from "../../../../../../../viewModels/api/Models";

interface Props {
  complaintInfo: ComplaintInfoState;
  dispatch?: () => void;
  sliderOnClick: (value: string) => void;
}
const ComplaintInfo: React.FC<Props> = ({
  complaintInfo,
  dispatch,
  sliderOnClick,
}) => {
  //configuration
  const { t: translate } = useTranslation();
  const windowSize = useWindowSize();
  //use states
  const [subject, setSubject] = useState<string>(complaintInfo.subject);
  const [reportedBy, setReportedBy] = useState<string>(
    complaintInfo.reportedBy
  );
  const [description, setDescription] = useState<string>(
    complaintInfo.description
  );
  const [images] = useState<Attachments[]>(complaintInfo.attachments);
  const [isReadOnley] = useState<boolean>(dispatch ? false : true);

  return (
    <Grid
      className={ContainerStyle.complaint_accordion_box_container}
      container
      columnSpacing={windowSize.width > MediumDeviceTrigger ? 8 : 6}
    >
      <Grid item md={6} sm={12} xs={12}>
        <TextField
          value={subject}
          onChange={setSubject}
          label={translate("LABELS.COMPLAINT_SUBJECT")}
          readOnly={isReadOnley}
        />
      </Grid>
      <Grid item md={6} sm={12} xs={12}>
        <TextField
          value={reportedBy}
          onChange={setReportedBy}
          label={translate("LABELS.REPORTED_BY")}
          readOnly={isReadOnley}
        />
      </Grid>
      <Grid item xs={12}>
        <TextArea
          value={description}
          label={translate("LABELS.DESCRIPTION")}
          placeholder=""
          onChange={setDescription}
          readonly={isReadOnley}
        />
      </Grid>
      {images.length > 0 && (
        <Grid className={Style.slider} item xs={12}>
          <ImageSlider onClick={sliderOnClick} urls={images} />
        </Grid>
      )}
    </Grid>
  );
};

export default ComplaintInfo;
