import { AnnouncementInfo } from "../../../../../services/api/Announcement";
import { ApiResponse, IServiceResult } from "../../../../../viewModels/api/Api";
import { Announcement } from "../../../../../viewModels/api/Models";
import { ResponseCode } from "../../../../../viewModels/enums/ResponseCode";

export const AnnouncementInfoService = async (id: number) => {
  const resultModel: IServiceResult<Announcement | null> = {
    message: "",
    data: null,
  };
  const Result: ApiResponse<Announcement> = await AnnouncementInfo(
    id
  );
  if (Result.responseStatus === ResponseCode.Success) {
    resultModel.data = Result.data;
  }
   
  return resultModel;
};