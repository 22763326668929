import { BodyOfGetRequest } from "../../Setting";
import {
  BaseUrl,
  GetBookServiceRequests,
  UpdateRequestState,
} from "../../text/Api";
import { ApiResponse } from "../../viewModels/api/Api";
import {
  BookService,
  BookServiceUpdateStateViewModel,
} from "../../viewModels/api/Models";
import { ApiMethod } from "../../viewModels/enums/ApiMethod";
import { CallApi } from "../CallApi";
import { DefaultApiHeader } from "./ApiHeaders";

export const GetServicesRequests = async (
  buildingId: number,
  categoryId: number
) => {
  const Url: string = `${BaseUrl}${GetBookServiceRequests}${buildingId}/${categoryId}`;

  let Result: ApiResponse<BookService[]> = await CallApi(
    Url,
    BodyOfGetRequest,
    DefaultApiHeader,
    ApiMethod.Get
  );

  return Result;
};

export const UpdateServiceRequestsState = async (
  stateInfo: BookServiceUpdateStateViewModel
) => {
  const Url: string = `${BaseUrl}${UpdateRequestState}`;
  const raw = JSON.stringify(stateInfo);

  let Result: ApiResponse<BookServiceUpdateStateViewModel> = await CallApi(
    Url,
    raw,
    DefaultApiHeader,
    ApiMethod.Put
  );

  return Result;
};
