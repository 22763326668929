import { Grid } from "@mui/material";
import React from "react";
import TextField from "../../../../../../../../tools/fields/textField/TextField";
import useWindowSize from "../../../../../../../../hook/WindowSize";
import { MediumDeviceTrigger } from "../../../../../../../../Setting";
import { useTranslation } from "react-i18next";
import DashboardFormTitle from "../../../../../../../../tools/dashboardTitle/dashboardFormTitle/DashboardFormTitle";
import ContainerStyle from "../ServiceInfoComponent.module.scss";
import IconDropDown from "../../../../../../../../tools/dropDown/iconDropDown/IconDropDown";
import Style from "./ServiceInfoTitle.module.scss";

import { FieldState } from "../../../../../../../../viewModels/enums/PublicEnum";
import { Setting } from "iconsax-react";

interface Props {
  serviceName: string;
  serviceIconId: number;
  onChange: (serviceIconId: number, serviceName: string) => void;
  errorMessage: string;
}

const ServiceInfoTitle: React.FC<Props> = ({
  serviceName,
  serviceIconId,
  onChange,
  errorMessage = "",
}) => {
  //config
  const windowSize = useWindowSize();
  const { t: translate } = useTranslation();
  //states
  //use effect

  const changeServiceNameHandler = (value: string) => {
    onChange(serviceIconId, value);
  };
  const changeServiceIconHandler = (value: number) => {
    onChange(value, serviceName);
  };
  return (
    <div>
      <DashboardFormTitle
        title={translate("TITLES.SERVICE_TITLE")}
        icon={<Setting className={Style.service_box_icon} />}
        className={Style.service_box_title}
      />
      <Grid
        className={ContainerStyle.service_box_container}
        container
        columnSpacing={windowSize.width > MediumDeviceTrigger ? 8 : 6}
      >
        <Grid item md={6} sm={12} xs={12}>
          <div className={Style.service_title_container}>
            <TextField
              value={serviceName}
              label={translate("LABELS.SERVICE_NAME")}
              placeHolder={translate("PLACEHOLDER.SERVICE_NAME")}
              onChange={changeServiceNameHandler}
              requiredField
              errorMessage={errorMessage}
              fieldState={
                errorMessage.length === 0
                  ? FieldState.Default
                  : FieldState.Error
              }
            />
            <IconDropDown
              iconId={serviceIconId}
              onChange={changeServiceIconHandler}
            />
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default ServiceInfoTitle;
