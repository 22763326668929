import { ReceiverType } from "../../../../viewModels/api/Models";
import { CountryType } from "../../../../viewModels/dataTypes/country/Country";
import {
  SuperAdminLoginServiceViaEmail,
  SuperAdminLoginServiceViaPhoneNumber,
} from "./UserContactInfo.Service";

export const SuperAdminLoginService = async (
  receiver: string,
  country?: CountryType
) => {
  let receiverType = DeliveryMethodDetectionService(country);
  switch (receiverType) {
    case ReceiverType.PhoneNumber:
      return await SuperAdminLoginServiceViaPhoneNumber(receiver, country);
    case ReceiverType.Email:
      return await SuperAdminLoginServiceViaEmail(receiver);
  }
};
//The function detect delivery method to send Otp code
export const DeliveryMethodDetectionService = (
  country?: CountryType | null
) => {
  if (country != null) {
    return ReceiverType.PhoneNumber;
  } else {
    return ReceiverType.Email;
  }
};
