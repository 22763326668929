import React, { useEffect, useState } from "react";
import { DashboardContainer } from "../../../../tools/container/customeContainer/DashboardContainer";
import DashboardBody from "../../dashboardBody/DashboardBody";
import {
  IBuildingAnnouncementReducer,
  IReducer,
} from "../../../../viewModels/reduxInterfaces/ReduxInterfaces";
import AnouncementList from "./announcementList/AnnouncementList";
import Loading from "../../../../tools/loading/Loading";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { AppThunkDispatch } from "../../../../redux/Store";
import { GetAnnouncementAction } from "../../../../redux/slices/AnnouncementSlice";

const Announcements = () => {
  const { buildingId } = useParams();
  const [preload, setPreload] = useState<boolean>(true);
  const announcementInfo: IBuildingAnnouncementReducer = useSelector(
    (state: IReducer) => state.announcementList
  );
  const dispatch = useDispatch<AppThunkDispatch>();

  useEffect(() => {
    const getBuildingAnnouncement = async () => {
      if (announcementInfo.fill) {
        setPreload(false);
      } else {
        dispatch(GetAnnouncementAction(buildingId ?? ""));
      }
    };
    getBuildingAnnouncement();
  }, [announcementInfo]);
  return (
    <DashboardContainer>
      <DashboardBody>
        {!preload ? (
          <AnouncementList annoncements={announcementInfo.annoncements || []} />
        ) : (
          <Loading />
        )}
      </DashboardBody>
    </DashboardContainer>
  );
};

export default Announcements;
