export const BaseUrl: string = "https://floorinsapi.rymon.info/";
//export const BaseUrl: string = "http://192.168.100.7:7088/";
//export const BaseUrl: string = "https://localhost:7088/";
export const CheckEmailToSignUpRequest: string = "api/User/Email/Signup/";
export const CheckEmailToLoginRequest: string = "api/User/Email/Login/";
export const GetUserInfoRequest: string = "api/User/UserInfo/";
export const GetUserRolesRequest: string = "api/User/AvtiveRoles/";
export const CheckPhoneNumberToSignUpRequest: string = "api/User/Phone/Signup/";
export const CheckPhoneNumberToLoginRequest: string = "api/User/Phone/Login/";
export const SendEmailOtpRequest = "api/User/Email/Otp/";
export const SendSMSOtpRequest = "api/User/Phone/Otp/";
export const CheckOtpRequest = "api/User/CheckingOtp";
export const GetUserBuildingRequest = "api/Building/UserBuilding/";
export const UserInfoRegistrationRequest: string = "api/User/InfoRegistration";
export const UserInfoConfirmationRequest: string =
  "api/User/ConfirmationUserInfo";
export const GetAllCountriesRequest = "api/Country/Countries";
export const CheckPasswordRequest = "api/User/CheckingPassword";
export const ForgetPasswordViaEmailRequest = "api/User/Email/PasswordRecovery/";
export const ForgetPasswordViaPhoneRequest = "api/User/Phone/PasswordRecovery/";
export const ChangePasswordRequest = "api/Auth/ChangingPassword";
export const CheckRefreshTokenRequest =
  "api/Auth/CheckRefreshToken?RefreshToeknText=";
export const VerifyReCaptchaToken = "api/Auth/Captcha?token=";
export const GetProvincesByCountryRequest = "api/Country/Provinces/";
export const GetCitiesByProvinceRequest = "api/Country/Cities/";
export const GetApplicationPlansRequest = "api/Plan/ApplicationPlans";
export const GetMonetaryPlansRequest = "api/Plan/MonetaryPlans";
export const CreateFirstBuildingRequest = "api/Building/FirstBuilding";
export const GetUserCredentialRequest = "api/User/CredentialInfo/";
export const GetBuildingInfoRequest = "api/Building/BuildingInfo/";
export const UpdateBuildingInfoRequest = "api/Building/BuildingInfo";
export const FileUploadRequest = "api/FileUpload/FileUpload";
export const BuildingUsersRequest = "api/User/BuildingUsers/";
export const PrimaryRolesRequest = "api/UserRole/PrimaryRoles";
export const AdminRolesRequest = "api/UserRole/AdministratorRoles";
export const AdministratorRequest = "api/Administrator/Administrator";
export const GetAppFeaturesRequest = "api/Feature/Features";
export const CreateNewAdministratorRequest =
  "api/Administrator/NewAdministrator";
export const GetUserPermissionsRequest = "api/Feature/UserRoleFeatures/";
export const GetBuldingUnitsRequest = "api/Building/BuilingUnits/";
export const GetUnitResidentsRequest = "api/Resident/UnitResidents";
export const CreateUnitResidentsRequest = "api/Resident/NewUnitResidents";
export const GetBuildingUserInfoRequest = "api/Building/BuildingUserInfo/";
export const DeleteResidentRequest = "api/Resident/Resident/";
export const DeleteUserFromUserListRequest = "api/Building/BuildingUser";
export const UpdateRangeOfResidentsRequest = "api/Resident/RangeOfResidents";
export const GetServiceCategoriesRequest =
  "api/ServiceCategory/ServiceCategories/";
export const GetServiceRequest = "api/BuildingServices/Services/";
export const AddServiceRequest = "api/BuildingServices/Service";
export const DeleteServiceRequest = "api/BuildingServices/Service/";
export const ServiceCategoryRequest = "api/ServiceCategory/ServiceCategory";
export const GetIconsBasedOnFilterRequest = "api/Icon/Icons/";
export const GetBookServiceRequests = "api/BookService/BookServices/";
export const UpdateRequestState = "api/BookService/BookServiceState";
export const UpdateComplaintsState = "api/Complaint/ComplaintState";
export const UpdateComplaintsRequest = "api/Complaint/Complaint";
export const GetComplaints = "api/Complaint/BuildingComplaints";
export const GetComplaintInfoRequest = "api/Complaint/Complaint/";
export const GetAnnouncements = "api/Announcement/BuildingAnnouncements";
export const GetBuildingTickets = "api/Ticket/Tickets";
export const UpdateTicketState = "api/Complaint/Complaint";

export const AddAnnouncementRequest = "api/Announcement/Announcement/";
export const AnnouncementInfoRequest = "api/Announcement/Announcement/";
export const BaseFilesPath = "https://files.rymon.info/UploadFiles/";
export const GoogleMapRequest = `https://maps.googleapis.com/maps/api/js?key=${"AIzaSyDuKczGEjwQxRFDrEMJxfeNm622kiVYX2Q"}&libraries=places`;
