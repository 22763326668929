import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import type { ActionReducerMapBuilder, PayloadAction } from "@reduxjs/toolkit";
import { IUserReducer } from "../../viewModels/reduxInterfaces/ReduxInterfaces";
import { ApiResponse } from "../../viewModels/api/Api";
import { GetUserCredentialInfo } from "../../services/api/User";
import { ResponseCode } from "../../viewModels/enums/ResponseCode";
import { UserCredentialInfoViewModel } from "../../viewModels/api/Models";

const initialState = {
  user: {},
  userLogin: false,
  loading: true,
  err: "",
} as IUserReducer;

const UserSlice = createSlice({
  name: "user",
  initialState,
  reducers: {},
  extraReducers: (builder: ActionReducerMapBuilder<IUserReducer>) => {
    builder.addCase(GetUserDataAction.pending, (State, action) => {
      State.loading = true;
      State.userLogin = false;
    });
    builder.addCase(
      GetUserDataAction.fulfilled,
      (State : IUserReducer, action: PayloadAction<ApiResponse<UserCredentialInfoViewModel> | null>) => {
        if (action.payload?.responseStatus === ResponseCode.Success) {
          State.user = action.payload?.data;
          State.loading = false;
          State.userLogin = true;
        }
      }
    );
    builder.addCase(GetUserDataAction.rejected, (State) => {
      State.loading = false;
      State.userLogin = false;
      State.err = "Login Faild";
    });
  },
});

export const GetUserDataAction = createAsyncThunk(
  "GetUserDataAction",
  async (userId: string) => {
    try {
      const userInfo: ApiResponse<UserCredentialInfoViewModel> | undefined =
        await GetUserCredentialInfo(userId);
      if (userInfo) {
        return userInfo;
      } else {
        return null;
      }
    } catch (err) {
      return null;
    }
  }
);

export default UserSlice.reducer;
