import React, { useEffect, useRef, useState } from "react";
import { Swiper, SwiperClass, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/navigation";
import "./ServiceIconSlider.scss";
import { Navigation } from "swiper/modules";
import { GetServiceCategoryIcons } from "./ServiceIconSlider.Service";
import { Icon } from "../../../../../../../viewModels/api/Models";
import { IServiceResult } from "../../../../../../../viewModels/api/Api";
import EastIcon from "@mui/icons-material/East";
import WestIcon from "@mui/icons-material/West";
import IconSliderItem from "./IconSliderItem";
interface Props {
  iconId: number;
  changeIconHandler: (value: number) => void;
}
const ServiceIconSlider: React.FC<Props> = ({ iconId, changeIconHandler }) => {
  const [activeIconId, setActiveIconId] = useState<number>(iconId); // State to store active slide index
  const [swiper, setSwiper] = useState<SwiperClass | null>(null);
  const [activeIndex, setActiveIndex] = useState<number>(0);
  const [icons, setIcons] = useState<Icon[] | null>(null);
  const latestIconId = useRef<number>(iconId);
  const isFirstRender = useRef<boolean>(true);

  useEffect(() => {
    const getIcons = async () => {
      const getIconsResult: IServiceResult<Icon[] | null> =
        await GetServiceCategoryIcons();
      setIcons(getIconsResult.data ?? null);

      const index = getIconsResult.data?.findIndex(
        (slide: Icon) => slide.id === latestIconId.current
      );
      setActiveIndex(index ?? 0);
      setActiveIconId(latestIconId.current);
    };

    if (isFirstRender.current || latestIconId.current !== iconId) {
      latestIconId.current = iconId;
      getIcons();
      isFirstRender.current = false;
    }
  }, [iconId]);

  useEffect(() => {
    changeIconHandler(activeIconId);
  }, [activeIconId, changeIconHandler]);

  const handlePrevClick = () => {
    if (swiper) {
      swiper.slidePrev();
    }
  };

  const handleNextClick = () => {
    if (swiper) {
      swiper.slideNext();
    }
  };
  
  return (
    <div className="slider-container">
      {icons && (
        <>
          <Swiper
            effect="coverflow"
            grabCursor={true}
            centeredSlides={true}
            slidesPerView={3}
            loop={true}
            initialSlide={activeIndex}
            coverflowEffect={{
              rotate: 0,
              stretch: 0,
              depth: 100,
              modifier: 1,
              slideShadows: false,
            }}
            navigation={true}
            modules={[Navigation]}
            className="slider"
           
            onSwiper={setSwiper}
            onSlideChange={(swiper: SwiperClass) =>
              setActiveIconId(Number(icons[swiper.realIndex].id))
            } // Update activeIndex on slide change
          >
            {icons.map((slide: Icon) => (
              <SwiperSlide key={slide.id}>
                <div className="slide-content">
                  <IconSliderItem
                    color={slide.color ?? ""}
                    icon={slide.title ?? ""}
                  />
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
          <div className="swiper-button-prev" onClick={handlePrevClick}>
            <WestIcon />
          </div>
          <div className="swiper-button-next" onClick={handleNextClick}>
            <EastIcon />
          </div>
        </>
      )}
    </div>
  );
};

export default ServiceIconSlider;
