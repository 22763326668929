import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Button from "../../../../tools/button/Button";
import Loading from "../../../../tools/loading/Loading";
import Captcha from "../../../../tools/capcha/Captcha";
import { ButtonState } from "../../../../viewModels/enums/ButtonEnum";
import { useTranslation } from "react-i18next";
import { SuperAdminLoginService } from "./SuperAdminLogin.Service";
import SmartField from "../../../../tools/fields/smartField/SmartField";
import BeforeDashboardForm from "../../BeforeDashboardForm";
import Style from "./SuperAdminLogin.module.scss";
import { ResponseCode } from "../../../../viewModels/enums/ResponseCode";
import { FieldState } from "../../../../viewModels/enums/PublicEnum";
import { CountryType } from "../../../../viewModels/dataTypes/country/Country";

const SuperAdminLogin: React.FC = () => {
  const [userContactInfo, setUserContactInfo] = useState("");
  const [country, setCountry] = useState<CountryType>();
  const [captchaState, setCaptchaState] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [messageContent, setMessageContent] = useState<string>("");
  const [enableLogin, setEnableLogin] = useState(false);
  const [fieldState, setFieldState] = useState<FieldState>(FieldState.Default);

  const navigate = useNavigate();

  const setUserMobileOrEmail = (phoneNumber: string, country?: CountryType) => {
    setMessageContent("");
    setEnableLogin(false);
    setFieldState(FieldState.Default);
    setCountry(country);
    setUserContactInfo(phoneNumber);
  };

  const submitPhoneNumberOrEmail = async () => {
    setMessageContent("");
    setEnableLogin(false);
    setLoading(true);
    const superAdminLoginResult = await SuperAdminLoginService(
      userContactInfo,
      country
    );
    if (superAdminLoginResult.data?.status === ResponseCode.Accepted) {
      navigate(superAdminLoginResult.route);
    } else {
      if (superAdminLoginResult.data?.status === ResponseCode.Created) {
        setEnableLogin(true);
      }
      setFieldState(FieldState.Error);
      setMessageContent(superAdminLoginResult.message);
      setLoading(false);
    }
  };

  const { t: translate } = useTranslation();

  return (
    <>
      <BeforeDashboardForm
        title={translate("AUTHENTICATION.SIGNUP")}
        description={translate("AUTHENTICATION.SIGNUP_DESCRIPTION")}
        body={
          <>
            <SmartField
              onChange={setUserMobileOrEmail}
              errorMessage={
                !enableLogin ? (
                  translate(messageContent)
                ) : (
                  <>
                    {translate(messageContent)}
                    <Link className={Style.login_link} to="/login">
                      {" "}
                      {translate("AUTHENTICATION.LOGIN")}
                    </Link>
                  </>
                )
              }
              fieldState={fieldState}
            />
            <div className="mt-2">
              <Captcha setMethod={setCaptchaState} />
            </div>
            <div className="mt-3">
              {captchaState === true && userContactInfo.length > 0 ? (
                <>
                  <Button
                    text={translate("BUTTON.NEXT")}
                    clickMethod={submitPhoneNumberOrEmail}
                  />
                </>
              ) : (
                <>
                  <Button
                    state={ButtonState.disable}
                    text={translate("BUTTON.NEXT")}
                  />
                </>
              )}
            </div>
            {loading && <Loading />}
          </>
        }
      />
    </>
  );
};
export default SuperAdminLogin;
