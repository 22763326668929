import React from "react";
import { DashboardContainer } from "../../../../tools/container/customeContainer/DashboardContainer";
import DashboardBody from "../../dashboardBody/DashboardBody";
import BuildingServiceTab from "./buildingServices/BuildingServiceTab";

const BuildingServices: React.FC = () => {
  return (
    <DashboardContainer>
      <DashboardBody>
        <BuildingServiceTab />
      </DashboardBody>
    </DashboardContainer>
  );
};

export default BuildingServices;
