import { Box, CircularProgress } from "@mui/material";
import React from "react";
import Style from "./LoaderScreen.module.scss";

interface Props{
  customStyle?: string 
}
const LoaderScreen: React.FC<Props> = ({ customStyle }) => {
  return (
    <Box className={`${Style.loader_screen} ${customStyle}`}>
      <CircularProgress />
    </Box>
  );
};

export default LoaderScreen;
