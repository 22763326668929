import React, { ReactNode } from "react";
import Style from "./AddNewPopups.module.scss";
import { FloorinsDateTimeFormat } from "../../../services/commonServices/CommonServices";
import { useTranslation } from "react-i18next";

interface Props {
  children: ReactNode;
  icon?: ReactNode;
  title: string;
  buttons?: ReactNode;
  cancelIcon?: ReactNode;
  createDateTime?: Date | undefined;
  id?: number | undefined
}
const AddNewPopups: React.FC<Props> = ({
  children,
  icon,
  title,
  buttons,
  cancelIcon,
  createDateTime,
  id
}) => {
    const { t: translate } = useTranslation();
  return (
    <div className={Style.popup_container}>
      <div className={Style.popup_box}>
        <div className={Style.popup_box_titr}>
          <div>
            <div>
              {icon}
              <span className={Style.title_popup}>
                {title} {id && `${translate("SHARED.NUMBER")}${id}`}
              </span>
            </div>
            {createDateTime && <p>{FloorinsDateTimeFormat((createDateTime).toString())}</p>}
          </div>
          {cancelIcon && (
            <span className={Style.cancel_icon}>{cancelIcon}</span>
          )}
        </div>
        {children}
        {buttons}
      </div>
    </div>
  );
};

export default AddNewPopups;
