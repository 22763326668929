import React, { useEffect, useState } from "react";
import { DashboardBox } from "../../../../../tools/box/DashboardBox";
import DashboardTitle from "../../../../../tools/dashboardTitle/DashboardTitle";
import Loading from "../../../../../tools/loading/Loading";
import {
  IBuildingInfoReducer,
  IReducer,
} from "../../../../../viewModels/reduxInterfaces/ReduxInterfaces";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { AppThunkDispatch } from "../../../../../redux/Store";
import { GetBuildingInfoDataAction } from "../../../../../redux/slices/BuildingInfoSlice";
import { MenuBoard } from "iconsax-react";
import { Complaint } from "../../../../../viewModels/api/Models";
import ComplaintInfoForm from "./complaintInfoForm/ComplaintInfoForm";
import { GetComplaintInfoService } from "./ComplaintInfoContainer.Service";

const ComplaintInfoContainer = () => {
  const { t: translate } = useTranslation();
  const { buildingId, complaintId } = useParams();
  const userBuildingInfo: IBuildingInfoReducer = useSelector(
    (state: IReducer) => state.buildingInfo
  );
  const [complaintInfo, setComplaintInfo] = useState<Complaint | null>(null);
  const dispatch = useDispatch<AppThunkDispatch>();
  const [buildingName, setBuildingName] = useState<string>("");

  useEffect(() => {
    const getBuildingInfo = async () => {
      if (userBuildingInfo.buildingInfo != null) {
        setBuildingName(userBuildingInfo.buildingInfo.buildingName);
      } else {
        dispatch(GetBuildingInfoDataAction(buildingId ?? ""));
      }
    };
    getBuildingInfo();
  }, [userBuildingInfo]);

  useEffect(() => {
    const getInfo = async () => {
      const getComplaintInfoResult = await GetComplaintInfoService(
        Number(complaintId)
      );

      if (getComplaintInfoResult.data) {
        setComplaintInfo(getComplaintInfoResult.data);
      }
    };
    getInfo();
  }, []);
  return (
    <DashboardBox>
      {complaintInfo ? (
        <>
          <DashboardTitle
            icon={<MenuBoard />}
            label={`${buildingName} ${translate("COMPLAINT.COMPLAINTS")}`}
          />
          <ComplaintInfoForm complaintInfo={complaintInfo} />
        </>
      ) : (
        <Loading />
      )}
    </DashboardBox>
  );
};

export default ComplaintInfoContainer;
