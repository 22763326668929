// CallToActionComponent.tsx
import { IconButton } from "@mui/material";
import React from "react";
import IconWrapper from "../../../../../../../tools/icons/CustomIcon";
import Style from "./ServicesModifieItems.module.scss";

interface CallToActionComponentProps {
  onDelete: () => void;
}

const ServisesModifieItems: React.FC<CallToActionComponentProps> = ({
  onDelete,
}) => (
  <IconButton onClick={onDelete} className={Style.iconRemove}>
    <IconWrapper name="RemoveRounded" />
  </IconButton>
);

export default ServisesModifieItems;
