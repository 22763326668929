import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { IMenuReducer } from "../../viewModels/reduxInterfaces/ReduxInterfaces";

const initialState = {
  isOpen: false,
} as IMenuReducer;
const MenuSlice = createSlice({
  name: "menu",
  initialState,
  reducers: {
    menuStateHandler(State, action: PayloadAction<boolean>) {
      State.isOpen = action.payload;
    },
  },
});

export const { menuStateHandler } = MenuSlice.actions;
export default MenuSlice.reducer;
