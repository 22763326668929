import {
  LocalStorage_BlockTimer,
  Localstorage_UserToken,
  Localstorage_UserValidation,
} from "../../text/LocalStorageValues";
import { UserContactValidationModel } from "../../viewModels/dataTypes/Cookie";
import { UserTokenModel } from "../../viewModels/dataTypes/registration/TokenViewModel";
import { IServiceResult } from "../../viewModels/api/Api";
import { ResponseCode } from "../../viewModels/enums/ResponseCode";
import { RandomColorEnum } from "../../viewModels/enums/AvatarBackGround";
import { IDropDown } from "../../viewModels/dataTypes/tools/DropDownItems";
import { DayOfWeek } from "../../viewModels/api/Models";
import { WeekDaysType } from "../../viewModels/dataTypes/tools/WeekDaysType";
import { RefObject } from "react";
import { MenuItemsViewModel } from "../../viewModels/dataTypes/tools/MenuItemsViewModel";

export const GetUserTokenInfo = () => {
  const ExistToken: boolean = CheckTokenExpiretion();
  if (ExistToken === true) {
    const TokenJson = localStorage.getItem(Localstorage_UserToken);
    if (TokenJson != null) {
      const Token: UserTokenModel = JSON.parse(TokenJson);
      return Token;
    } else {
      window.location.replace("/login");
    }
  } else {
    window.location.replace("/login");
  }
};

export const GetUserId = () => {
  const ExistToken: boolean = CheckTokenExpiretion();

  if (ExistToken === true) {
    const TokenJson = localStorage.getItem(Localstorage_UserToken);
    if (TokenJson != null) {
      const Token: UserTokenModel = JSON.parse(TokenJson);
      return Token.userId;
    } else {
      window.location.replace("/login");
    }
  } else {
    window.location.replace("/login");
  }
};

export const CheckTokenExpiretion = (): boolean => {
  if (localStorage) {
    const TokenJson = localStorage.getItem(Localstorage_UserToken);
    const DateNow = ConvertUTCToTimestamp(new Date(Date.now()));

    if (TokenJson != null) {
      const Token: UserTokenModel = JSON.parse(TokenJson);

      const _unixTokenExpire = ConvertUTCToTimestamp(Token.tokenExpiration);

      if (_unixTokenExpire < DateNow) {
        localStorage.removeItem(Localstorage_UserToken);
        return false;
      } else {
        return true;
      }
    } else {
      localStorage.removeItem(Localstorage_UserToken);
      return false;
    }
  } else {
    return false;
  }
};

export const ConvertUTCToTimestamp = (datetime: Date) => {
  const dateObject = new Date(datetime.toString());
  const unixTimestamp = Math.floor(dateObject.getTime() / 1000);

  return unixTimestamp;
};

export const FloorinsScrollTop = (loaction: number = 0) => {
  window.scrollTo({
    top: loaction,
    behavior: "smooth",
  });
};
export const ComponentScrollTop = (divRef: RefObject<HTMLDivElement>) => {
  if (divRef.current) {
    divRef.current.scrollTo({ top: 0, behavior: "smooth" });
  }
};

export const CompressImage = (
  src: string,
  quality: number
): Promise<string> => {
  return new Promise((resolve) => {
    const img = new Image();
    img.src = src;
    img.onload = () => {
      const canvas = document.createElement("canvas");
      const ctx = canvas.getContext("2d");
      canvas.width = img.width;
      canvas.height = img.height;
      ctx?.drawImage(img, 0, 0);
      const compressedImageSrc = canvas.toDataURL("image/jpeg", quality);
      resolve(compressedImageSrc);
    };
  });
};

export const DataURLtoFile = (dataURL: string, filename: string): File => {
  const urlArray = dataURL.split(",");
  const mime = urlArray[0].match(/:(.*?);/)?.[1] || "";
  const bstr = atob(urlArray[1]);
  let n = bstr.length;
  const _uint8Array = new Uint8Array(n);
  while (n--) {
    _uint8Array[n] = bstr.charCodeAt(n);
  }
  return new File([_uint8Array], filename, { type: mime });
};

export const MakeId = (length: number = 10) => {
  let result = "";
  const characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  const charactersLength = characters.length;
  let counter = 0;
  while (counter < length) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
    counter += 1;
  }
  return result;
};

export const BlockTime = () => {
  const _now = new Date(Date.now());
  const timer = _now.setMinutes(_now.getMinutes() + 1);

  localStorage.setItem(LocalStorage_BlockTimer, timer.toString());
};

export const CheckBlockTime = () => {
  const timeString = localStorage.getItem(LocalStorage_BlockTimer);
  if (timeString != null) {
    const _now = Date.now().toString();

    return _now < timeString;
  } else {
    return false;
  }
};

export const CheckUserValidation = () => {
  const resultModel: IServiceResult<UserContactValidationModel> = {
    message: "",
    data: null,
  };
  if (!localStorage.getItem(Localstorage_UserValidation)) {
    resultModel.data = null;
  } else {
    const ueserDataJson = localStorage.getItem(Localstorage_UserValidation);
    if (ueserDataJson != null) {
      const userValidationData: UserContactValidationModel =
        JSON.parse(ueserDataJson);
      resultModel.data = userValidationData;
    } else {
      resultModel.data = null;
    }
  }
  return resultModel;
};

export const OtpMessageGenerator = (status: ResponseCode) => {
  switch (status) {
    case ResponseCode.ExpiredOtpCode:
      return "ERRORS.EXPIRED_OTP";
    case ResponseCode.BlockedOtpCode:
      return "ERRORS.BLOCKED_OTP";
    case ResponseCode.IncorrectOtpCode:
      return "ERRORS.INVALID_OTP";
    case ResponseCode.TryAfterSomeMinutes:
      return "ERRORS.TRY_OTP_LATER";
    default:
      return "ERRORS.INVALID_OTP";
  }
};

export const MaskString = (input: string): string => {
  const length = input.length;
  if (length <= 6) {
    // If the string length is less than or equal to 6,
    // return the original string as it is.
    return input;
  } else {
    const visiblePart = input.slice(0, 3) + "****" + input.slice(-3);
    return visiblePart;
  }
};

export const PriceConvertor = (price: number) => {
  if (price > 0) {
    return "$" + price;
  } else {
    return "Free";
  }
};

export const DaysLeftUntilToday = (inputDate: string) => {
  // Parse the input date string to a JavaScript Date object
  const targetDate = new Date(inputDate);

  // Get the current date
  const currentDate = new Date();

  // Calculate the difference in milliseconds between the target date and the current date
  const timeDifference = targetDate.getTime() - currentDate.getTime();

  // Convert the time difference to days
  const daysDifference = timeDifference / (1000 * 60 * 60 * 24);

  // Round to the nearest whole number
  return Math.round(daysDifference);
};

export const MakeAvatarWithName = (name: string) => {
  const firstTwoChars: string = name.substring(0, 2);
  return firstTwoChars;
};

export const CheckNullText = (value: string | null): string => {
  return value == null || IsNullString(value) ? "" : value;
};

export const CalculatePercentage = (value: number, percentage: number) => {
  return (value * 100) / percentage;
};

export const FloorinsDateTimeFormat = (dateString: string) => {
  const date = new Date(dateString);
  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, "0");
  const day = date.getDate().toString().padStart(2, "0");

  let hours = date.getHours();
  const minutes = date.getMinutes().toString().padStart(2, "0");

  const ampm = hours >= 12 ? "PM" : "AM";
  hours = hours % 12;
  hours = hours ? hours : 12; // The hour '0' should be '12'
  const formattedHours = hours.toString().padStart(2, "0");

  return `${month}/${day}/${year} ${formattedHours}:${minutes} ${ampm}`;
};

export const RoundSizeToDecimal = (num: number, round: number) => {
  return parseFloat((num / 1000000).toFixed(round));
};

export const GenerateRandomColor = () => {
  const classes = [
    RandomColorEnum.Blue,
    RandomColorEnum.LightBlue,
    RandomColorEnum.Red,
    RandomColorEnum.Green,
  ];
  const randomIndex = Math.floor(Math.random() * classes.length);
  return classes[randomIndex];
};

export const IsNullString = (value: string) => {
  if (value.trim().length > 0) {
    return false;
  } else {
    return true;
  }
};

export const IsNullList = <T>(value: T[] | null) => {
  if (value?.length !== 0 && value != null) {
    return false;
  } else {
    return true;
  }
};

export const ConvertIDropDownToString = (items: IDropDown[] | null): string => {
  return items ? items.map((item: IDropDown) => item.value).join(", ") : "";
};

export const DeepEqual = <T>(obj1: T, obj2: T): boolean => {
  if (obj1 === obj2) {
    return true;
  }
  if (obj1 && obj2 && typeof obj1 === "object" && typeof obj2 === "object") {
    if (Object.keys(obj1).length !== Object.keys(obj2).length) {
      return false;
    }
    for (const key in obj1) {
      if (Object.prototype.hasOwnProperty.call(obj1, key)) {
        const val1 = obj1[key];
        const val2 = obj2[key];

        if (!DeepEqual(val1, val2)) {
          return false;
        }
      }
    }
    return true;
  }
  return false;
};

export const CompareObjectLists = <T>(list1: T[], list2: T[]): boolean => {
  if (list1.length !== list2.length) {
    return false;
  }
  for (let i = 0; i < list1.length; i++) {
    if (!DeepEqual(list1[i], list2[i])) {
      return false;
    }
  }
  return true;
};

export const GetWeekDays = (): WeekDaysType[] => {
  // Get the enum keys and filter out the numeric values (only keep string keys)
  const weekDayKeys = Object.keys(DayOfWeek).filter((key: string) =>
    isNaN(Number(key))
  );
  const today = GetTodayWeekDay();
  // Create the list of WeekDaysType objects
  return weekDayKeys.map((key: string) => ({
    value: DayOfWeek[key as keyof typeof DayOfWeek], // numeric value from enum
    label: key, // string label from enum key
    isActive: today == DayOfWeek[key as keyof typeof DayOfWeek], // You can set this based on your requirements
  }));
};

export const GetWeekDayName = (day: DayOfWeek): string => {
  return DayOfWeek[day];
};

export const GetTodayWeekDay = (): DayOfWeek => {
  const today = new Date().getDay();
  return today as DayOfWeek;
};

export const GetNewId = <T extends { id?: number }>(
  existingItems: T[]
): number => {
  if (existingItems.length === 0) {
    return 1;
  }
  const maxId = Math.max(...existingItems.map((item: T) => item.id ?? 0));
  return maxId + 1;
};

export const FormatCurrency = (value: number): string => {
  return new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  }).format(value);
};

export const EnumToList = <T>(enumObj: object): IDropDown[] => {
  const enumValues = Object.keys(enumObj).filter((key) => isNaN(Number(key)));

  // Return the mapped array
  return enumValues.map((key: string) => ({
    text: key, // Enum key as the value
    value: `${enumObj[key as keyof typeof enumObj]}`, // Enum value as the text
  }));
};

export const extractTitrItems = (menuItems: MenuItemsViewModel[]) => {
  const items: { value: string; text: string }[] = [];

  menuItems.forEach((item) => {
    items.push({
      value: item.path,
      text: item.titr,
    });

    if (item.subMenu) {
      item.subMenu.forEach((subItem) => {
        items.push({
          value: subItem.path,
          text: subItem.titr,
        });
      });
    }
  });

  return items;
};
