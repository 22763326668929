import {
  ActionReducerMapBuilder,
  PayloadAction,
  createAsyncThunk,
  createSlice,
} from "@reduxjs/toolkit";
import { IBuildingUsersReducer } from "../../viewModels/reduxInterfaces/ReduxInterfaces";

import { IBuildingUsers } from "../../viewModels/dataTypes/user/IBuildingUsers";
import { GetBuildingUsersService } from "../../components/dashboard/dashboardContents/users/usersList/UserList.Service";
import { IServiceResult } from "../../viewModels/api/Api";

const initialState = {
  users: [],
  loading: true,
  fill: false,
  error: "",
} as IBuildingUsersReducer;

const BuildingUsersSlice = createSlice({
  name: "buildingUsers",
  initialState,
  reducers: {},
  extraReducers: (builder: ActionReducerMapBuilder<IBuildingUsersReducer>) => {
    builder.addCase(GetBuildingUsersAction.pending, (State) => {
      State.loading = true;
    });
    builder.addCase(
        GetBuildingUsersAction.fulfilled,
      (State, action: PayloadAction<IBuildingUsers[]>) => {
        State.users = action.payload;
        State.loading = false;
        State.fill = true;
      }
    );
    builder.addCase(GetBuildingUsersAction.rejected, (State) => {
      State.loading = false;
      State.err = "Loading Faild";
    });
  },
});
export const GetBuildingUsersAction = createAsyncThunk(
  "GetBuildingUsersAction",
  async (buildingId: string) => {
    try {
      const buildingUsers: IServiceResult<IBuildingUsers[]> =
        await GetBuildingUsersService(buildingId);        
      if (buildingUsers.data) {
        return buildingUsers.data;
      } else {
        return [];
      }
    } catch (err) {
      return [];
    }
  }
);

export default BuildingUsersSlice.reducer;
