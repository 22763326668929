import { Grid } from "@mui/material";
import React, { useState } from "react";
import PlanItem from "./planItem/PlanItem";
import { PricingItem } from "../../../../viewModels/dataTypes/plan/Pricing";
import { PriceConvertor } from "../../../../services/commonServices/CommonServices";
import { DefaultPlan } from "../../../../Setting";
import { PricingTypeEnum } from "../../../../viewModels/enums/PricingType";

interface Props {
  items?: PricingItem[] | null;
  onChange: (value: PricingItem) => void;
}

const PlansSection: React.FC<Props> = ({ items, onChange }) => {
  const [planType, setPlanType] = useState<PricingTypeEnum>(DefaultPlan);

  const planOnClick = (value: PricingItem) => {
    onChange(value);
    setPlanType(value.title);
  };

  return (
    <div className="mt-3">
      <Grid container spacing={2}>
        {items != null ? (
          items?.map((item : PricingItem) => (
            <Grid item xs={12} sm={6} md={3}>
              <PlanItem
                onClick={() => planOnClick(item)}
                planTitle={item.title}
                planImage={item.planImageUrl}
                planPrice={PriceConvertor(item.price)}
                buildingLimitNumber={item.buildingCount}
                planColor={`var(--${item.title.toLowerCase()})`}
                isSelect={planType == item.title}
                description={item.description}
              />
            </Grid>
          ))
        ) : (
          <></>
        )}
      </Grid>
    </div>
  );
};

export default PlansSection;
