import { BaseUrl, GetIconsBasedOnFilterRequest } from "../../text/Api";
import { ApiResponse } from "../../viewModels/api/Api";
import { Icon, IconType } from "../../viewModels/api/Models";
import { ApiMethod } from "../../viewModels/enums/ApiMethod";
import { CallApi } from "../CallApi";
import { DefaultApiHeader } from "./ApiHeaders";

export const GetIconsBasedOnType = async (iconType: IconType) => {
  const Url: string = BaseUrl + GetIconsBasedOnFilterRequest + iconType;
  var Result: ApiResponse<Icon[]> = await CallApi(
    Url,
    "{}",
    DefaultApiHeader,
    ApiMethod.Get
  );
  return Result;
};
