import React, { useEffect, useState } from "react";
import Style from "./RequestServicesComponent.module.scss";
import {
  IconContainer,
  IconWrapperStyled,
} from "./RequestServiceComponentStyled";

interface Props {
  indexOfRow: number;
  serviceTitle: string;
  serviceIcon?: string | null | undefined;
  serviceIconColor?: string;
  isHovered: boolean;
}

const RequestServicesComponent: React.FC<Props> = ({
  indexOfRow,
  serviceTitle,
  serviceIcon,
  serviceIconColor,
  isHovered,
}) => {
  const [isOdd, setIsOdd] = useState<boolean>(false);

  useEffect(() => {
    setIsOdd(indexOfRow % 2 !== 0);
  }, [indexOfRow]);

  return (
    <div className={Style.request_service_wrapper}>
      {serviceIcon ? (
        <div className={Style.icon_background}>
          <IconContainer
            isHovered={isHovered}
            serviceIconColor={serviceIconColor}
            isOdd={isOdd}
            className={Style.icon_container}
          >
            <IconWrapperStyled
              isHovered={isHovered}
              serviceIconColor={serviceIconColor}
              className={Style.icon}
              name={serviceIcon}
            />
          </IconContainer>
        </div>
      ) : null}
      <span>{serviceTitle}</span>
    </div>
  );
};

export default RequestServicesComponent;
