import { Grid } from "@mui/material";
import React from "react";
import useWindowSize from "../../../../../../../../hook/WindowSize";
import { MediumDeviceTrigger } from "../../../../../../../../Setting";
import { useTranslation } from "react-i18next";
import DashboardFormTitle from "../../../../../../../../tools/dashboardTitle/dashboardFormTitle/DashboardFormTitle";
import ContainerStyle from "../ServiceInfoComponent.module.scss";
import TextArea from "../../../../../../../../tools/textArea/TextArea";
import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";

interface Props {
  value: string;
  onChange: (value: string) => void;
}
const ServiceInfoDescription: React.FC<Props> = ({ value, onChange }) => {
  const windowSize = useWindowSize();
  const { t: translate } = useTranslation();
  return (
    <div>
      <DashboardFormTitle
        title={translate("TITLES.SERVICE_DESCRIPTION")}
        icon={
          <DescriptionOutlinedIcon
            className={ContainerStyle.service_box_icon}
          />
        }
        className={ContainerStyle.service_box_title}
      />
      <Grid
        className={ContainerStyle.service_box_container}
        container
        columnSpacing={windowSize.width > MediumDeviceTrigger ? 8 : 6}
      >
        <Grid item xs={12}>
          <TextArea
            value={value}
            label={translate("LABELS.DESCRIPTION")}
            placeholder={translate("PLACEHOLDER.SERVICE_DESCRIPTION")}
            onChange={onChange}
            maxLength={500}
          />
        </Grid>
      </Grid>
    </div>
  );
};

export default ServiceInfoDescription;
