import React, { useEffect, useState } from "react";
import TenantContract from "../../../../addUser/addResidentForm/tenantContract/TenantContract";
import { Grid } from "@mui/material";
import FloorinsDatePicker from "../../../../../../../../tools/datePicker/DatePicker";
import { useTranslation } from "react-i18next";
import Style from "./ContractDate.module.scss";
import { UpdateResidenViewModel } from "../../../../../../../../viewModels/dataTypes/user/IResident";

interface Props {
  userInfo: UpdateResidenViewModel;
  changeHandler: (userInfo: UpdateResidenViewModel) => void;
}
const ContractDate: React.FC<Props> = ({ userInfo, changeHandler }) => {
  const [contractStartDate, setcontractStartDate] = useState<Date | null>(
    userInfo.startDate
  );
  const [contractEndDate, setcontractEndDate] = useState<Date | null>(
    userInfo.endDate
  );
  const { t: translate } = useTranslation();
  useEffect(() => {
    changeHandler({
      endDate: contractEndDate ?? userInfo.endDate,
      startDate: contractStartDate ?? userInfo.startDate,
      isResident: userInfo.isResident,
      residentId: userInfo.residentId,
    });
  }, [contractStartDate, contractEndDate]);
  return (
    <TenantContract className={Style.user_info_contract}>
      <Grid item md={6} sm={12} xs={12}>
        <FloorinsDatePicker
          value={contractStartDate}
          onChange={setcontractStartDate}
          label={translate("LABELS.START_DATE")}
        />
      </Grid>
      <Grid item md={6} sm={12} xs={12}>
        <FloorinsDatePicker
          value={contractEndDate}
          onChange={setcontractEndDate}
          label={translate("LABELS.END_DATE")}
        />
      </Grid>
    </TenantContract>
  );
};

export default ContractDate;
