import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Filter from "../../../../../../tools/table/filter/Filter";
import { FilterConditionsEnum } from "../../../../../../viewModels/enums/FilterConditionsEnum";
import FilterDropDown from "../../../../../../tools/dropDown/filterDropDown/FilterDropDown";
import { BookServiceViewModel } from "../../../../../../viewModels/dataTypes/building/BookServiceViewModel";
import Loading from "../../../../../../tools/loading/Loading";
import CustomizedTables from "../../../../../../tools/table/Table";
import { useTranslation } from "react-i18next";
import { headCells } from "../ServiceRequestsMockData";
import { IDropDown } from "../../../../../../viewModels/dataTypes/tools/DropDownItems";
import { useDispatch, useSelector } from "react-redux";
import { DataTableItemType } from "../../../../../../viewModels/dataTypes/DataTableType";
import { AppThunkDispatch } from "../../../../../../redux/Store";
import Style from "./RequestsList.module.scss";
import { FilterBuildingServiceRequests } from "../ServiceRequest.Service";
import { GetBuildingServices } from "../../../../../../redux/slices/BuildingServices";
import {
  BuildingServices,
  RequestState,
} from "../../../../../../viewModels/api/Models";
import { IReducer } from "../../../../../../viewModels/reduxInterfaces/ReduxInterfaces";
import FilterDropDownSelect from "../../../../../../tools/dropDown/filterDropDownSelect/FilterDropDownSelect";
import FilterDropDownDate from "../../../../../../tools/dropDown/filterDropDownDate/FilterDropDownDate";
import { Dayjs } from "dayjs";
import {
  FormatDateDefault,
  MediumDeviceTrigger,
  UltraSmallDeviceTrigger,
} from "../../../../../../Setting";
import useWindowSize from "../../../../../../hook/WindowSize";
import { DateItems } from "../../../../../../tools/dropDown/filterDropDownDate/DateItems";
import { CalenderEnum } from "../../../../../../viewModels/enums/CalenderEnum";
import { DateRangeType } from "../../../../../../viewModels/dataTypes/DateRangeType";

interface Props {
  serviceRequests: BookServiceViewModel[];
}
const ServiceRequests: React.FC<Props> = ({ serviceRequests }) => {
  const { buildingId, categoryId } = useParams<{
    buildingId: string;
    categoryId: string;
  }>();
  const [requestFilterList, setRequestFilterList] = useState<
    (BookServiceViewModel & DataTableItemType)[]
  >([]);
  const [preload, setPreload] = useState<boolean>(true);
  const [isActiveCloseIcon, setIsActiveCloseIcon] = useState<boolean>(false);
  const [checkedItemsServices, setCheckedItemsServices] = useState<string[]>(
    []
  );
  const [checkedItemsStates, setCheckedItemsStates] = useState<string[]>([]);

  const [searchText, setSearchText] = useState<string>("");
  const [requestServices, setRequestServices] = useState<string | null>(null);
  const [states, setStates] = useState<string | null>(null);
  const [servicesDropDown, setServicesDropDown] = useState<IDropDown[]>([]);
  const [stateDropDown, setStateDropDown] = useState<IDropDown[]>([]);
  const [stateFilterLogic, setStateFilterLogic] = useState<string>(
    FilterConditionsEnum.OR
  );
  const [requestServicesFilterLogic, setRequestServicesFilterLogic] =
    useState<string>(FilterConditionsEnum.OR);
  const [, setRequestTimeFilterLogic] = useState<string>(
    FilterConditionsEnum.OR
  );
  const [selectedMode, setSelectedMode] = useState<string>("");
  const windowsSize = useWindowSize();
  const isMediumToUltraSmallDevice: boolean =
    windowsSize.width <= MediumDeviceTrigger &&
    windowsSize.width > UltraSmallDeviceTrigger;
  const [selectedDateRange, setSelectedDateRange] =
    useState<DateRangeType | null>(null);
  const services = useSelector(
    (state: IReducer) => state.buildingServices.services
  );

  const dispatch = useDispatch<AppThunkDispatch>();

  const getRequestStateList = (): string[] => {
    return Object.keys(RequestState)
      .filter((key) => isNaN(Number(key)))
      .map((key: string) => key);
  };

  useEffect(() => {
    setRequestFilterList(serviceRequests);
    const stateDropDownList: IDropDown[] = getRequestStateList().map(
      (state: string, index: number) => ({
        value: index.toString() ?? "",
        text: state ?? "",
      })
    );
    setStateDropDown(stateDropDownList);
    setPreload(false);
  }, [serviceRequests]);

  const resetFilters = () => {
    setSearchText("");
    setRequestServices(null);
    setStates(null);
    setCheckedItemsServices([]);
    setCheckedItemsStates([]);
    setSelectedMode("");
    if (checkedItemsServices.length > 0 || searchText.length > 0) {
      setRequestServicesFilterLogic(FilterConditionsEnum.OR);
      setRequestTimeFilterLogic(FilterConditionsEnum.OR);
      setStateFilterLogic(FilterConditionsEnum.OR);
    }
  };

  useEffect(() => {
    setIsActiveCloseIcon(
      checkedItemsServices.length > 0 ||
        checkedItemsStates.length > 0 ||
        searchText.length > 0 ||
        selectedMode !== ""
    );
  }, [
    checkedItemsServices.length,
    checkedItemsStates.length,
    searchText.length,
    selectedMode,
  ]);

  useEffect(() => {
    const dataFilter = FilterBuildingServiceRequests(
      serviceRequests,
      searchText,
      requestServices ?? "",
      requestServicesFilterLogic,
      states ?? "",
      selectedDateRange,
      selectedMode
    );
    setRequestFilterList(dataFilter);
  }, [
    serviceRequests,
    searchText,
    requestServices,
    requestServicesFilterLogic,
    states,
    selectedDateRange,
    selectedMode,
  ]);
  useEffect(() => {
    const getServicesInfo = async () => {
      await dispatch(
        GetBuildingServices({
          buildingId: Number(buildingId),
          categoryId: Number(categoryId),
        })
      );
    };
    getServicesInfo();
  }, [buildingId, categoryId]);

  useEffect(() => {
    let _services: IDropDown[] = [];
    services?.map((item: BuildingServices) =>
      _services.push({
        text: item.title ?? "",
        value: item.id?.toString() ?? "",
      })
    );
    setServicesDropDown(_services);
  }, [services]);

  const { t } = useTranslation();
  // Handle date change
  const handleDateChange = (startDate: Dayjs, endDate: Dayjs) => {
    setSelectedDateRange({
      start: startDate.format(FormatDateDefault),
      end: endDate.format(FormatDateDefault),
    });
  };

  return (
    <div className={Style.requests_list_wrapper}>
      <Filter
        onSearch={setSearchText}
        onClose={resetFilters}
        valueSearch={searchText}
        isActiveCloseIcon={isActiveCloseIcon}
        filterItemsDate={
          <>
            {windowsSize.width >= UltraSmallDeviceTrigger && (
              <div className={Style.container_date_desktop_filter}>
                {isMediumToUltraSmallDevice ? (
                  selectedMode && (
                    <FilterDropDownSelect
                      items={DateItems}
                      label={t("REQUESTS.REQUEST_DATE")}
                      onModeChange={setSelectedMode}
                      value={selectedMode}
                    />
                  )
                ) : (
                  <FilterDropDownSelect
                    items={DateItems}
                    label={t("REQUESTS.REQUEST_DATE")}
                    onModeChange={setSelectedMode}
                    value={selectedMode}
                  />
                )}
                {selectedMode && (
                  <FilterDropDownDate
                    className={Style.requests_list_dropdown}
                    mode={selectedMode}
                    onDateChange={(start: Dayjs, end: Dayjs) =>
                      handleDateChange(start, end)
                    }
                  />
                )}
              </div>
            )}
          </>
        }
        filterItems={
          <>
            {windowsSize.width < MediumDeviceTrigger && (
              <>
                <p
                  className={`${Style.request_mobile_report} ${
                    selectedMode !== "" ? Style.active : ""
                  }`}
                  onClick={() =>
                    setSelectedMode(selectedMode !== "" ? "" : CalenderEnum.DAY)
                  }
                >
                  {t("REQUESTS.REQUEST_DATE")}
                </p>
              </>
            )}
            <FilterDropDown
              className={Style.requests_list_dropdown}
              onChange={setRequestServices}
              onChangeConditionsLogic={setRequestServicesFilterLogic}
              valueConditionsLogic={requestServicesFilterLogic}
              items={servicesDropDown}
              label={t("REQUESTS.SERVICES")}
              checkedItems={checkedItemsServices}
              setCheckedItems={setCheckedItemsServices}
              showRadioButton={true}
            />

            <FilterDropDown
              className={Style.requests_list_dropdown}
              onChange={setStates}
              onChangeConditionsLogic={setStateFilterLogic}
              valueConditionsLogic={stateFilterLogic}
              items={stateDropDown}
              label={t("REQUESTS.STATE")}
              checkedItems={checkedItemsStates}
              setCheckedItems={setCheckedItemsStates}
              showRadioButton={false}
            />
          </>
        }
      />
      {selectedMode && windowsSize.width < UltraSmallDeviceTrigger && (
        <div className={`${Style.container_filter_Date}`}>
          <FilterDropDownSelect
            items={DateItems}
            label={t("REQUESTS.REQUEST_DATE")}
            onModeChange={setSelectedMode}
            value={selectedMode}
          />
          {selectedMode && (
            <FilterDropDownDate
              className={Style.complaints_list_dropdown}
              mode={selectedMode}
              onDateChange={(start: Dayjs, end: Dayjs) =>
                handleDateChange(start, end)
              }
            />
          )}
        </div>
      )}

      {preload ? (
        <Loading />
      ) : (
        <CustomizedTables
          className={Style.requests_list}
          path={`/dashboard/building/services/${buildingId}/${categoryId}/`}
          tableData={requestFilterList}
          tableHeaderItems={headCells}
          notFoundMessage={t("ALERT.NOT_FOUND_REQUESTS")}
        />
      )}
    </div>
  );
};

export default ServiceRequests;
