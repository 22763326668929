import React, { useEffect, useState } from "react";
import DashboardFormTitle from "../../../../../../../tools/dashboardTitle/dashboardFormTitle/DashboardFormTitle";
import ContainerStyle from "../ComplaintInfoForm.module.scss";
import { useTranslation } from "react-i18next";
import ArticleOutlinedIcon from "@mui/icons-material/ArticleOutlined";
import { Grid } from "@mui/material";
import useWindowSize from "../../../../../../../hook/WindowSize";
import { MediumDeviceTrigger } from "../../../../../../../Setting";
import TextField from "../../../../../../../tools/fields/textField/TextField";
import { ComplaintDetailsState } from "./ComplaintDetails.State";

interface Props {
  detailsStates: ComplaintDetailsState;
  dispatch?: () => void;
}

const ComplaintDetails: React.FC<Props> = ({ detailsStates, dispatch }) => {
  //configuration
  const { t: translate } = useTranslation();
  const windowSize = useWindowSize();

  //states
  const [isReadOnley] = useState<boolean>(dispatch ? false : true);
  const [complaintNumber, setComplaintNumber] = useState<string>(
    detailsStates.complaintNumber
  );
  const [complaintDate, setComplaintDate] = useState<string>(
    detailsStates.complaintDate
  );
  const [complaintLastUpdate, setcomplaintLastUpdate] = useState<string>(
    detailsStates.lastUpdate
  );
  useEffect(() => {
    setComplaintNumber(detailsStates.complaintNumber);
    setcomplaintLastUpdate(detailsStates.lastUpdate);
    setComplaintDate(detailsStates.complaintDate);
  }, [detailsStates, dispatch]);
  return (
    <div>
      <DashboardFormTitle
        title={translate("TITLES.SERVICE_DESCRIPTION")}
        icon={
          <ArticleOutlinedIcon className={ContainerStyle.complaint_box_icon} />
        }
        className={ContainerStyle.complaint_box_title}
      />
      <Grid
        className={ContainerStyle.complaint_box_container}
        container
        columnSpacing={windowSize.width > MediumDeviceTrigger ? 4 : 2}
      >
        <Grid item md={4} xs={12}>
          <TextField
            value={complaintNumber}
            label={translate("LABELS.COMPLAINT_NUMBER")}
            onChange={setComplaintNumber}
            readOnly={isReadOnley}
          />
        </Grid>
        <Grid item md={4} xs={12}>
          <TextField
            value={complaintDate}
            label={translate("LABELS.DATE_OF_REPORT")}
            onChange={setComplaintDate}
            readOnly={isReadOnley}
          />
        </Grid>
        <Grid item md={4} xs={12}>
          <TextField
            value={complaintLastUpdate}
            label={translate("LABELS.LAST_UPDATE")}
            onChange={setcomplaintLastUpdate}
            readOnly={isReadOnley}
          />
        </Grid>
      </Grid>
    </div>
  );
};

export default ComplaintDetails;
