import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import Style from "./Icons.module.scss";
export const CustomeArrowUpIocn: React.FC = () => {
  return <ArrowUpwardIcon className={Style.arrow_icon} />;
};

export const CustomeArrowDownIcon: React.FC = () => {
  return <ArrowDownwardIcon className={Style.arrow_icon} />;
};
