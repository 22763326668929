import { Checkbox, FormControlLabel, MenuItem } from "@mui/material";
import React, { useEffect, useRef } from "react";
import { IDropDown } from "../../../viewModels/dataTypes/tools/DropDownItems";
import RadioButtonGroup from "../../radioButtonGroup/RadioButtonGroup";
import { RadioButtonItems } from "./FilterDropDownSetup";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CustomExpandMoreIcon from "../../icons/CustomeExpandMoreIcon";
import useWindowSize from "../../../hook/WindowSize";
import Style from "./FilterDropDown.module.scss";
import { MediumDeviceTrigger } from "../../../Setting";
import ClearCheckedDropDown from "./clearcheckedDropDown/ClearCheckedDropDown";
import { useTranslation } from "react-i18next";

interface Props {
  label: string;
  items: IDropDown[];
  onChange: (value: string | null) => void;
  onChangeConditionsLogic: (value: string) => void;
  valueConditionsLogic: string;
  checkedItems: string[];
  setCheckedItems: (items: string[]) => void;
  showRadioButton: boolean;
  className?: string;
}

const FilterDropDown: React.FC<Props> = ({
  label,
  items,
  onChange,
  onChangeConditionsLogic,
  valueConditionsLogic,
  checkedItems,
  setCheckedItems,
  showRadioButton,
  className,
}) => {
  const [open, setOpen] = React.useState(false);
  const [localCheckedItems, setLocalCheckedItems] =
    React.useState<string[]>(checkedItems); // Local state
  const windowsSize = useWindowSize();
  const { t: translate } = useTranslation();
  const divRef = useRef<HTMLDivElement>(null);

  const handleToggle = (value: string) => {
    const currentIndex = localCheckedItems.indexOf(value);
    const newChecked = [...localCheckedItems];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setLocalCheckedItems(newChecked);
    onChange(newChecked.toString());
    setCheckedItems(newChecked);
  };

  const handleClearCheckedItems = () => {
    const newChecked: string[] = [];
    setLocalCheckedItems(newChecked);
    onChange("");
    setCheckedItems(newChecked);
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (divRef.current && !divRef.current.contains(event.target as Node)) {
        setOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  useEffect(() => {
    if (!checkedItems.length) {
      setLocalCheckedItems([]);
    }
  }, [checkedItems.length]);
  return (
    <div ref={divRef} className={`${Style.mobile_dropdown} ${className}`}>
      <div
        className={`${Style.mobile_dropdown_label} ${
          open && windowsSize.width < MediumDeviceTrigger
            ? Style.active_label
            : ""
        }`}
        onClick={() => setOpen(!open)}
      >
        <span> {label}</span>
        <CustomExpandMoreIcon open={open} />
      </div>
      <ul
        className={`${Style.custom_dropdown_options} ${
          open ? Style.open_dropdown : ""
        }`}
      >
        {showRadioButton && (
          <div className={Style.radio_button}>
            <RadioButtonGroup
              onChangeConditionsLogic={onChangeConditionsLogic}
              value={valueConditionsLogic}
              items={RadioButtonItems}
            />
          </div>
        )}
        <div>
          {items.map((item: IDropDown, index: number) => (
            <MenuItem key={index} value={item.value}>
              <FormControlLabel
                key={index}
                control={
                  <Checkbox
                    disableRipple
                    icon={
                      <CheckBoxOutlineBlankIcon
                        className={Style.checkbox_outline}
                      />
                    }
                    checkedIcon={
                      <CheckBoxIcon className={Style.checkbox_icon} />
                    }
                    checked={
                      localCheckedItems.indexOf(item.value as string) > -1
                    }
                    onChange={() => {
                      handleToggle(item.value.toString());
                    }}
                  />
                }
                label={item.text}
                classes={{
                  label:
                    localCheckedItems.indexOf(item.value) > -1
                      ? Style.checkedLabel
                      : "",
                }}
              />
            </MenuItem>
          ))}
        </div>

        <ClearCheckedDropDown
          active={localCheckedItems.length > 0}
          text={translate("LABELS.CLEAR")}
          onClear={handleClearCheckedItems}
        />
      </ul>
    </div>
  );
};

export default FilterDropDown;
