import { ToggleTabItem } from "../../../../../viewModels/dataTypes/tools/ToggleTabItem";

export enum ServiceTabItemEnum {
  Service = "Service",
  Request = "Request",
}



export const TabItems: ToggleTabItem[] = [
  { label: "SERVICE.SERVICES", value: ServiceTabItemEnum.Service },
  { label: "SERVICE.REQUESTS", value: ServiceTabItemEnum.Request },
];
