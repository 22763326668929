import React from "react";
import { DashboardContainer } from "../../../../../../../tools/container/customeContainer/DashboardContainer";
import DashboardBody from "../../../../../dashboardBody/DashboardBody";
import AddBuildingServiceContainer from "./addBuildingServiceContainer/AddBuildingServiceContainer";

const AddBuildingService = () => {
  return (
    <DashboardContainer>
      <DashboardBody>
        <AddBuildingServiceContainer />
      </DashboardBody>
    </DashboardContainer>
  );
};

export default AddBuildingService;
