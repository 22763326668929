import { Trash } from "iconsax-react";
import React from "react";
import Style from "./RemoveResident.module.scss";
import { ClassNameViewModel } from "../../../../../../../viewModels/dataTypes/CommonViewModels";

interface Props extends ClassNameViewModel {
  onClock: () => void;
}
const RemoveResident: React.FC<Props> = ({ onClock, className = "" }) => {
  return (
    <div onClick={onClock} className={`${Style.remove} ${className}`}>
      <Trash />
    </div>
  );
};

export default RemoveResident;
