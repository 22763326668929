import { IRadioButton } from "../../../viewModels/dataTypes/tools/DropDownItems";
import { FilterConditionsEnum } from "../../../viewModels/enums/FilterConditionsEnum";

export const RadioButtonItems: IRadioButton[] = [
    {
      text: FilterConditionsEnum.OR,
      value: FilterConditionsEnum.OR,
    },
    {
      text: FilterConditionsEnum.AND,
      value: FilterConditionsEnum.AND,
    },
  ];