import { BodyOfGetRequest } from "../../Setting";
import {
  AddAnnouncementRequest,
  AnnouncementInfoRequest,
  BaseUrl,
  GetAnnouncements,
} from "../../text/Api";
import { ApiResponse } from "../../viewModels/api/Api";
import { Announcement } from "../../viewModels/api/Models";
import { ApiMethod } from "../../viewModels/enums/ApiMethod";
import { CallApi } from "../CallApi";
import { DefaultApiHeader } from "./ApiHeaders";

export const GetAnouncementsList = async (buildingId: string) => {
  const Url: string = `${BaseUrl}${GetAnnouncements}/${buildingId}`;

  let Result: ApiResponse<Announcement[]> = await CallApi(
    Url,
    BodyOfGetRequest,
    DefaultApiHeader,
    ApiMethod.Get
  );

  return Result;
};
export const AddAnnouncement = async (announcementInfo: Announcement) => {
  const Url: string = `${BaseUrl + AddAnnouncementRequest}`;
  const raw = JSON.stringify(announcementInfo);
  let Result: ApiResponse<Announcement> = await CallApi(
    Url,
    raw,
    DefaultApiHeader,
    ApiMethod.Post
  );

  return Result;
};
export const AnnouncementInfo = async (announcementId: number) => {
  const Url: string = `${BaseUrl}${AnnouncementInfoRequest}announcementId?announcementId=${announcementId}`;
  let Result: ApiResponse<Announcement> = await CallApi(
    Url,
    BodyOfGetRequest,
    DefaultApiHeader,
    ApiMethod.Get
  );

  return Result;
};