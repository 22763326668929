import { toast } from "react-toastify";
import Style from "./Toast.module.scss";

import CloseIcon from "@mui/icons-material/Close";
import { ToastType } from "../../viewModels/enums/ToastType";

interface IToast {
  (toastType: ToastType, message: string, options?: object): void;
}

export const CreateToast: IToast = (
  toastType: ToastType,
  message: string,
  options?: object
) => {
  toast(message, {
    className: Style.toast_items,
    type: toastType,
    ...options,
    closeButton: <CloseIcon className={Style.close_icon} />,
  });
};
