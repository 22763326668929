import { PricingTypeEnum } from "./viewModels/enums/PricingType";

export const PhoneNumberLength = 11;
export const EmailAddressLength = 50;
export const MaximumFileSize = 531575;
export const OtpTimerValue = 59;
export const EditUserContactLimit = 1;
export const DefaultPlan = PricingTypeEnum.Gold;
export const TrialPlanDuration = 12;
export const MenuIconSize = 18;
export const StandardDropDown = "standard";
export const DropDownValueType = "string";
export const UltraSmallDeviceTrigger = 600;
export const SmallDeviceTrigger = 900;
export const TabletDeviceTrigger = 1025;
export const MediumDeviceTrigger = 1200;
export const DefaultCountryRegen = "US";
export const FieldStopTypingTime = 800;
export const ApiViewModelDirectory = "../src/viewModels/api";
export const ApiViewModelFilePath = "./src/viewModels/api/Models.ts";
export const BodyOfGetRequest = "{}";
export const GeneralOtpCount = 4;
export const DefaultMinTime = "00:00";
export const DefaultMaxTime = "23:30";
export const DefaultDuration = 0;
export const FormatDateDefault = "YYYY-MM-DD";
export const FormatDateMonthWithDay = "MMM DD, YYYY";
export const FormatDateMonth = "MMM YYYY";
export const FormatDateYear = "YYYY";
export const FormatDateMonthAndDay = "MMM DD";
export const FormatAcceptUpload = ".png,.jpg,.jpeg,.pdf,.gif,.svg";
export const FormatAcceptUploadLabel = "PNG, JPG, PDF, GIF, SVG";
export const MaxSizeUpload = "1MB";
export const WorkerUrl = "https://unpkg.com/pdfjs-dist@3.11.174/build/pdf.worker.js";
