import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper/modules";
import ChevronRightOutlinedIcon from "@mui/icons-material/ChevronRightOutlined";
import ChevronLeftOutlinedIcon from "@mui/icons-material/ChevronLeftOutlined";
import { AttachmentsViewModel } from "../../../viewModels/dataTypes/AttachmentsViewModels";
import { SpecialZoomLevel, Viewer, Worker } from "@react-pdf-viewer/core";
import { UltraSmallDeviceTrigger, WorkerUrl } from "../../../Setting";
import useWindowSize from "../../../hook/WindowSize";
import DownloadButton from "../../button/DownloadButton";
import { GetFileName } from "../../../services/commonServices/filesCommonServices/FilesCommonServices";
import Style from "./SwiperComponent.module.scss";
import { Trash } from "iconsax-react";
import { Swiper as SwiperType } from "swiper";

interface Props {
  attachments: AttachmentsViewModel[];
  isUpload?: boolean;
  setAnnouncementAttachment?: Dispatch<SetStateAction<AttachmentsViewModel[]>>;
  setUploadDisabled?: Dispatch<SetStateAction<boolean>>;
  openAttachmentFile?: (value: string) => void;
}

const SwiperComponent: React.FC<Props> = ({
  attachments,
  isUpload = false,
  setAnnouncementAttachment,
  setUploadDisabled,
  openAttachmentFile,
}) => {
  const [swiperRef, setSwiperRef] = useState<SwiperType | null>(null);
  const [currentSlide, setCurrentSlide] = useState<number>(0);
  const windowsSize = useWindowSize();
  const [totalSlides, setTotalSlides] = useState<number>(attachments.length);

  useEffect(() => {
    if (swiperRef) {
      setTotalSlides(attachments.length);
    }
  }, [attachments, swiperRef]);

  const handleDeleteAttachment = (index: number) => {
    if (setAnnouncementAttachment) {
      const updatedAttachments = attachments.filter(
        (_, i: number) => i !== index
      );
      setAnnouncementAttachment(updatedAttachments);
      if (updatedAttachments.length < 5) {
        setUploadDisabled?.(false);
      }
    }
  };

  const isPdfFile = (filePath: string) => {
    return filePath.startsWith("data:application/pdf;base64,");
  };

  return (
    <>
      {totalSlides >
        (isUpload
          ? 3
          : windowsSize.width <= UltraSmallDeviceTrigger
          ? 2
          : 4) && (
        <div
          className={`${Style.custom_nav_button} ${
            isUpload ? Style.left_nav : Style.left_nav_info
          } ${currentSlide === 0 ? Style.nav_disabled : Style.nav_enabled}`}
          onClick={() => {
            if (currentSlide !== 0) {
              swiperRef?.slidePrev();
            }
          }}
        >
          <ChevronLeftOutlinedIcon />
        </div>
      )}
      <Swiper
        effect="coverflow"
        slidesPerView={isUpload ? 1 : 2}
        spaceBetween={12}
        navigation={false}
        draggable={true}
        touchRatio={1}
        loop={false}
        onSwiper={(swiper: SwiperType) => setSwiperRef(swiper)}
        onSlideChange={(swiper: SwiperType) =>
          setCurrentSlide(swiper.activeIndex)
        }
        className={`${Style.slider} ${isUpload ? "" : Style.slider_info}`}
        modules={[Navigation]}
        breakpoints={{
          600: {
            slidesPerView: isUpload ? 3 : 4,
            spaceBetween: 8,
          },
        }}
      >
        {attachments.map((attachment: AttachmentsViewModel, index: number) => {
          const isPdf = isPdfFile(attachment.filePath || "");
          return (
            <SwiperSlide
              key={index}
              className={Style.swiper_slide_custom}
              onClick={() => {
                if (!isUpload) openAttachmentFile?.(attachment.filePath || "");
              }}
            >
              {isPdf ? (
                <Worker workerUrl={WorkerUrl}>
                  <div className={Style.pdf_preview}>
                    <Viewer
                      defaultScale={SpecialZoomLevel.PageWidth}
                      fileUrl={attachment.filePath || ""}
                      initialPage={0}
                    />
                  </div>
                </Worker>
              ) : (
                <img
                  src={attachment.filePath || ""}
                  alt={attachment?.filePath || ""}
                  className={Style.attachment_image}
                />
              )}

              <div className={Style.delete_container}>
                <div className={Style.file_name_container}>
                  <p> {attachment.filePath || ""}</p>
                  <span>{attachment.fileExtension!}</span>
                </div>
                <div className={Style.delete_icon_container}>
                  {isUpload ? (
                    <Trash
                      className={Style.delete_icon}
                      size="18"
                      onClick={() => handleDeleteAttachment(index)}
                    />
                  ) : (
                    <DownloadButton
                      fileName={GetFileName(attachment.filePath ?? "")}
                      fileUrl={attachment.filePath ?? ""}
                    />
                  )}
                </div>
              </div>
            </SwiperSlide>
          );
        })}
      </Swiper>
      {totalSlides >
        (isUpload
          ? 3
          : windowsSize.width <= UltraSmallDeviceTrigger
          ? 2
          : 4) && (
        <div
          className={`${Style.custom_nav_button} ${
            isUpload ? Style.right_nav : Style.right_nav_info
          } ${
            currentSlide ===
              totalSlides -
                (isUpload
                  ? windowsSize.width <= UltraSmallDeviceTrigger
                    ? 1
                    : 3
                  : windowsSize.width <= UltraSmallDeviceTrigger
                  ? 2
                  : 4) || totalSlides <= 1
              ? Style.nav_disabled
              : Style.nav_enabled
          }`}
          onClick={() => {
            if (
              currentSlide !==
                totalSlides -
                  (isUpload
                    ? windowsSize.width <= UltraSmallDeviceTrigger
                      ? 1
                      : 3
                    : windowsSize.width <= UltraSmallDeviceTrigger
                    ? 2
                    : 4) &&
              totalSlides > 1
            ) {
              swiperRef?.slideNext();
            }
          }}
        >
          <ChevronRightOutlinedIcon />
        </div>
      )}
    </>
  );
};

export default SwiperComponent;
