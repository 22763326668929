import { TimeInterval } from "../../../../../../../../viewModels/api/Models";
import { IDurationRepeat } from "../../../../../../../../viewModels/dataTypes/tools/IDurationRepeat";

export interface ServiceDateInfo {
  startDate: Date | null;
  endDate: Date | null;
  durationRepeat: IDurationRepeat;
}

export enum ServiceDateActionType {
  StartDate = "SET_START_DATE",
  EndDate = "SET_END_DATE",
  DurationRepeat = "SET_DURATION_REPEAT",
}

export type ServiceDateAction =
  | { type: ServiceDateActionType.StartDate; payload: Date | null }
  | { type: ServiceDateActionType.EndDate; payload: Date | null }
  | { type: ServiceDateActionType.DurationRepeat; payload: IDurationRepeat };

export const InitialServiceDateState: ServiceDateInfo = {
  startDate: new Date(),
  endDate: new Date(),
  durationRepeat: {
    durationType: TimeInterval.Weekly,
    value: 1,
  },
};

export const ServiceDateReducer = (
  state: ServiceDateInfo,
  action: ServiceDateAction
): ServiceDateInfo => {
  switch (action.type) {
    case ServiceDateActionType.StartDate:
      return { ...state, startDate: action.payload };
    case ServiceDateActionType.EndDate:
      return { ...state, endDate: action.payload };
    case ServiceDateActionType.DurationRepeat:
      return { ...state, durationRepeat: action.payload };
    default:
      return state;
  }
};
