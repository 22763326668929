import dayjs from "dayjs";
import { IDropDown } from "../../../../../../../../viewModels/dataTypes/tools/DropDownItems";
import { DefaultDuration } from "../../../../../../../../Setting";

 const DefaultDurationsValue: IDropDown[] = [
  { text: "30 min", value: "30" },
  { text: "1h", value: "60" },
  { text: "1h 30m", value: "90" },
  { text: "2h", value: "120" },
  { text: "2h 30m", value: "150" },
  { text: "3h", value: "180" },
  { text: "3h 30m", value: "210" },
  { text: "4h", value: "240" },
  { text: "4h 30m", value: "270" },
  { text: "5h", value: "300" },
];

export const GetActiveDurations = (startTime: string, endTime: string) => {
  // Parse times with a reference date
  const start = dayjs(`2025-01-01 ${startTime}`, "YYYY-MM-DD HH:mm");
  const end = dayjs(`2025-01-01 ${endTime}`, "YYYY-MM-DD HH:mm");

  // Calculate the difference in minutes
  const diffInMinutes = end.diff(start, "minute");

  let dynamicDurationValues: IDropDown[] = [];

  DefaultDurationsValue.map((time: IDropDown) => {
    if (diffInMinutes > 0 && diffInMinutes % Number(time.value) === 0) {
      dynamicDurationValues.push(time);
    }
  });

  if (diffInMinutes > 300) {
    dynamicDurationValues.push({
      text: "SHARED.FULL_DURATION",
      value: DefaultDuration.toString(),
    });
  }

  return dynamicDurationValues;
};
