import { AddAnnouncement } from "../../../../../services/api/Announcement";
import { ApiResponse, IServiceResult } from "../../../../../viewModels/api/Api";
import {
  Announcement,
  Attachments,
} from "../../../../../viewModels/api/Models";

export const AddAnnouncementService = async (
  createdDateTime?: Date,
  buildingId?: number,
  title?: string | null,
  description?: string | null,
  announcementAttachment?: Attachments[] | null,
  label?: string | null
) => {
  const resultModel: IServiceResult<Announcement | null> = {
    message: "",
    data: null,
  };

  const newAnnouncementInfo: Announcement = {
    buildingId,
    title,
    description,
    createdDateTime,
    announcementAttachment,
    label,
  };
  const getAnnouncementInfoResult: ApiResponse<Announcement> =
    await AddAnnouncement(newAnnouncementInfo);

  resultModel.data = getAnnouncementInfoResult.data;
  return resultModel;
};
