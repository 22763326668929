import styled from "styled-components";
interface Props {
  width: number;
  fillPercent : number
}
export const LineCircle = styled.div<Props>`
  margin: auto;
  position: relative;
  width: ${(a : Props) => a.width + "px"};
  height: ${(a : Props) => a.width + "px"};
  border-radius: 50%;
  overflow: hidden;
  background: ${(a : Props)=> `conic-gradient(var(--trial) 0% ${a.fillPercent}%, var(--bg-gray-high) ${a.fillPercent}% 100%)`};
  display: flex;
  justify-content: center;
  align-items: center;
  > div {
    width: 92%;
    height: 92%;
    border-radius: 50%;
    background-color: var(--bg-secondary);
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0.5rem;
  }
`;
