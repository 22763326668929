import { InfoRegistration } from "../../../../services/api/User";
import {
  Localstorage_UserToken,
  Localstorage_UserValidation,
} from "../../../../text/LocalStorageValues";
import { ResponseCode } from "../../../../viewModels/enums/ResponseCode";
import { IServiceResult, ApiResponse } from "../../../../viewModels/api/Api";
import { UserTokenModel } from "../../../../viewModels/dataTypes/registration/TokenViewModel";
import { UserInfoRegistrationViewModel } from "../../../../viewModels/api/Models";

export const RegisterUser = async (userData: UserInfoRegistrationViewModel) => {
  var _result: IServiceResult<any> = {
    message: "",
  };
  var personalRegistrationResult: ApiResponse<UserTokenModel> =
    await InfoRegistration(userData);
  if (personalRegistrationResult.responseStatus == ResponseCode.Success) {
    localStorage.setItem(
      Localstorage_UserToken,
      JSON.stringify(personalRegistrationResult.data)
    );
    _result.data = personalRegistrationResult.data;
    localStorage.removeItem(Localstorage_UserValidation);
  } else {
    _result.message = "ERRORS.OPERATION_FAILD";
  }

  return _result;
};
