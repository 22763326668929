import React from "react";
import Style from "./SearchBox.module.scss";
import { SearchNormal1 } from "iconsax-react";

interface Props {
  placeholder: string;
  onSearch: (value: string) => void;
}
const SearchBox: React.FC<Props> = ({ placeholder, onSearch }) => {
  const searchChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    onSearch(e.target.value);
  };
  return (
    <div className={Style.search_field}>
      <SearchNormal1 size="16" color="var(--icon-gray-high)" />
      <input onChange={searchChangeHandler} placeholder={placeholder} />
    </div>
  );
};

export default SearchBox;
